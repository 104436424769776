.register-box
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08)
	border-radius: r(20px)
	padding: r(40px) r(80px)
	+mq-max(lg)
		padding: r(32px)
	.form-radio
		ul
			display: flex
			gap: r(20px)
			li
				display: flex
				align-items: center
				gap: r(8px)
				input
					margin: 0
				label
					@extend .body-3
					+text(gray-300)
				input:checked
					+text(primary-500)
					& ~ label
						+text(primary-500)
	.form-box
		& + .form-box
			margin-top: r(32px)
	.form-row.row
		+gap-override(20px)
	.form-submit
		input
			border: 0

.register-nav
	ul
		+layout-row(20px)
		justify-content: center
		li
			a
				@extend .body-2
				+text(gray-300)
				display: inline-flex
				align-items: center
				gap: r(8px)
				&:before
					+icon("\f111")
					font-size: r(14px)
			&.active
				a
					+text(primary-500)
					&:before
						content: '\f192'