.history-slider
	.swiper-btn
		top: r(65px) !important
	
.history-item
	&-year
		+circle(130px)
		display: flex
		+flex-center
		+bg-gra(primary)
		+text(white)
		margin: 0 auto r(32px)
		border: r(8px) solid color(white)
		box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25)
		@extend .heading-4
		font-weight: 700
		font-family: $font-2
	&-content
		+layout-col(20px)
	&-img
		figure
			+res-ratio(304,171)
			border-radius: r(8px)
			overflow: hidden
	&-brief
		@extend .body-1
		+text(gray-600)
		+line(3)
		margin-bottom: r(12px)
	&-link
		margin-top: auto