.home-2
	background-position: bottom right
	+mq-min(lg)
		padding-bottom: r(212px) !important
	+mq-max(md)
		background-image: none !important
		+bg(primary-50)
		.heading-3
			text-align: center
	.img
		figure
			filter: drop-shadow(6px 6px 15px rgba(0, 0, 0, 0.1))
			+res-ratio(572,496)
			img
				width: 100%
