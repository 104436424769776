.cart-wrapper
	position: relative
.cart
	text-align: center
	position: relative
	top: 2px
	height: r(24px)
	.cart-toggle
		cursor: pointer
		display: flex
		align-items: center
		.icon
			+fz(20px)
		.cart-amount
			+circle(20px)
			+bg(white)
			font-size: r(12px)
			+text(primary-500)
			margin-left: r(4px)
		.cart-text
			margin-left: r(8px)
			+fz(14px)
	.cart-dropdown
		position: absolute
		top: 100%
		right: 0
		margin-top: r(12px)
		width: r(360px)
		background: #fff
		+text(gray-700)
		border-radius: r(8px)
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15)
		z-index: 20
		display: none
		+mq-max(lg)
			right: auto
			left: 0
		&.show
			display: block

	.cart-container
		padding: r(24px)

	.cart-heading
		display: flex
		align-items: center
		justify-content: space-between
		padding-bottom: r(12px)
		border-bottom: 2px solid color(gray-50)
		margin-bottom: r(16px)

	.cart-close
		font-size: r(20px)
		cursor: pointer
		position: absolute
		top: r(12px)
		right: r(24px)

	.cart-body
		overflow-y: auto
		height: r(264px)
		padding-right: r(16px)
		&::-webkit-scrollbar
			width: 4px
			background-color: #ccc
		&::-webkit-scrollbar-thumb
			background-color: color(main)
		ul
			li
				& + li
					margin-top: r(16px)
					padding-top: r(16px)
					border-top: 1px solid color(gray-50)

	.cart-product
		.img
			display: block
			width: r(60px)
		.caption
			flex: 1
			text-align: left
			padding-left: r(20px)
		.name
			display: block
			margin-bottom: 10px
			&:hover
				+text(primary-500)
		.quantity
			color: #999

	.cart-button
		margin-top: r(20px)
		a
			width: 100%
	@media screen and ( max-width: 576px )
		position: static
