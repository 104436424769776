.sub-banner
	.banner-item
		position: relative
		.banner-icon
			position: absolute
			+center(both)
			+fz(80px)
			+text(white)
		&-caption
			position: absolute
			bottom: r(70px)
			+text(white)
			width: 100%
			text-align: center
			+mq-max(md)
				bottom: r(32px)
		// &-img
		// 	figure
		// 		+mq-max(md)
		// 			+res-ratio(16,9)
	.swiper-pagination
		bottom: r(40px)
		z-index: 2
		+center(X)
		+mq-max(lg)
			bottom: r(16px)
		&-bullet
			height: r(16px)
			width: r(16px)
			border-radius: 50%
			+bg(white)
			opacity: 1
			margin: 0 r(10px) !important
			&-active
				+bg(primary-500)

.news-detail-page,.recruitment-detail-page
	.sub-banner
		display: none
