.manufacture-image-slider
	.swiper-container
		overflow: hidden !important

.manufacture-detail-2
	.item
		&-title
			font-weight: 700
		&-img
			figure
				+res-ratio(640,360)
		+even
			.row
				flex-direction: row-reverse

.manufacture-standard-slider
	.item
		position: relative
		padding: r(72px) r(60px)
		+bg(white)
		+radius(r(20px))
		@extend .box-shadow-1
		@extend .body-1
		+layout-col(20px)
		+mq-max(lg)
			padding: r(40px) r(32px)
		+mq-max(sm)
			padding: r(60px) r(20px) r(32px)
		&-icon
			position: absolute
			top: r(8px)
			left: r(8px)
			width: r(45px)
			figure
				+res-ratio(1,1)
		&-title
			+text(primary-600)
			font-weight: 700
			margin-top: 0 !important
		&-content
			ul
				padding-left: 1rem
				list-style: disc
