.loader
	width: 50px
	aspect-ratio: 1
	border-radius: 50%
	border: 8px solid color(primary-100)
	border-right-color: color(primary-500)
	animation: l2 1s infinite linear
	display: inline-block

.loader-wrap
	display: flex
	width: 100%
	height: 100%
	+flex-center

@keyframes l2
	to
		transform: rotate(1turn)

.ajax-loader
	position: fixed
	top: 0
	left: 0
	width: 100%
	height: 100%
	text-align: center
	display: none
	z-index: 200
	+bg-opa(gray-500,0.3)
