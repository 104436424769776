.news-item
	display: flex
	&:hover
		.news-item-title
			+text(primary-500)
	&-date
		@extend .body-5
		+text(gray-400)
	&-title
		@extend .heading-5
		font-weight: 700
		+trans-all
	&-img
		figure
			a
				+radius(r(12px))
				display: block
				overflow: hidden
	&-caption
		+layout-col(16px)
		+mq-max(lg)
			+layout-col(8px)
	&-brief
		@extend .body-1
		+line(4)
	&-vertical
		flex-direction: column
		.news-item-caption
			flex: 1 1 0%
	&-horizontal
		.news-item-img
			+flex-width(200 / 640 * 100%)
			figure
				a
					+res-ratio(200,150)
		.news-item-caption
			padding-left: r(20px)
			flex: auto
		.news-item-title
			+line(2)
		&.news-item-no-img
			.news-item-caption
				padding-left: 0
	&-big
		.news-item-caption
			margin-top: r(28px)
			+mq-max(lg)
				margin-top: r(16px)
		.news-item-img
			figure
				a
					+res-ratio(640,440)
	&-small
		.news-item-caption
			margin-top: r(20px)
			+mq-max(lg)
				margin-top: r(16px)
		.news-item-img
			figure
				a
					+res-ratio(416,312)
		.news-item-title
			+line(2)
			margin-bottom: r(16px)
			+mq-max(lg)
				margin-bottom: r(8px)
		.news-item-brief
			margin-top: auto