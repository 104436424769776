$threshold: 55%

@function contrast-color($color, $dark, $light)
	@return if(lightness($color) < $threshold, $light, $dark)

@each $color, $value in $button
	.btn-#{$color}
		background-color: $value
		color: contrast-color($value, #333, #fff)
		+on-hover
			background-color: darken($value, 10%)

	.btn-border-#{$color}
		border: 1px solid $value
		color: $value
		+on-hover
			background-color: $value
			color: contrast-color($value, #333, #fff)

@each $gradient, $value in $gradients
	.btn-#{$gradient}
		background: $value

.btn
	padding: r(12px) r(20px)
	display: inline-flex
	+trans-all
	+font(400,16px,24px)
	+flex-center
	text-transform: uppercase
	min-width: r(168px)
	gap: r(8px)
	+content-margins
		display: inline-block
		margin-left: r(8px)
	span
		font-size: r(16px)
	&-pill
		border-radius: 1000px
	&-normal
		text-transform: none

.btn-icon
	display: inline-flex
	align-items: center
	padding-right: r(12px)
	border-radius: 9999px
	position: relative
	z-index: 1
	+trans-all
	+content-margins
		display: inline-flex
		margin-left: r(12px)
	em
		+circle(44px)
	span
		@extend .body-4
		text-transform: uppercase
		+trans-all
	&:before
		+pseudo
		+fill
		border-radius: 9999px
		+trans-all
		opacity: 0
		z-index: -1
	+on-hover
		&::before
			opacity: 1
	&-gradient
		em
			+bg-gra(primary)
			+text(white)
		span
			+text-gradient(primary)
		&:before
			+bg-gra(primary)
		+on-hover
			span
				background-image: linear-gradient(224.07deg,#fff 12.94%,#fff 94.92%)
	&-small
		em
			+circle(28px)

	&-white
		em
			+bg(white)
			+text(primary-500)
		span
			+text(white)
		&:before
			+bg(white)
		+on-hover
			span
				+text(primary-500)
.button-group
	+layout-row(32px)

.swiper-btn
	display: inline-flex
	+flex-center
	+circle(60px)
	+text(primary-500)
	border: 1px solid color(primary-500)
	font-size: r(24px)
	cursor: pointer
	z-index: 2
	transition: 0.3s all
	+remove(lg,max)
	+on-hover
		+bg(primary-500)
		+text(white)
	&.swiper-button-disabled
		opacity: 0
		pointer-events: none
	&-white
		+text(white)
		border: 1px solid color(white)

.swiper-navigation
	&.is-between
		.swiper-btn
			position: absolute
			+center(Y)
			margin: 0 r(28px)
			&.swiper-prev
				right: 100%
			&.swiper-next
				left: 100%
			+mq-max(2xl)
				margin: 0 r(8px)

		&.is-no-gap
			.swiper-btn
				margin: 0 r(10px)
				&.swiper-prev
					right: auto
					left: 0
				&.swiper-next
					left: auto
					right: 0
	&.is-left
		display: flex
		gap: r(12px)