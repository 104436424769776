.tab-nav
	+layout-row(8px)
	overflow-x: auto
	scroll-snap-type: x mandatory
	+mq-min(md)
		justify-content: center
	li
		a
			white-space: nowrap
			@extend .body-1
			display: inline-flex
			padding: r(8px) r(12px)
			+text(gray-400)
			+trans-all
			+radius(r(4px))
			+parent-state(".active",":hover")
				+bg(primary-500)
				+text(white)

.sub-nav
	ul
		+row(8px)
		justify-content: flex-start
		+mq-max(lg)
			flex-wrap: nowrap
			overflow: auto
		+mq-min(lg)
			justify-content: center
		li
			a
				white-space: nowrap
				@extend .body-1
				display: inline-flex
				padding: r(8px) r(12px)
				+text(gray-400)
				+trans-all
				+radius(r(4px))
				+parent-state(".active",":hover")
					+bg(primary-500)
					+text(white)