.news-side-list
	+layout-col(16px)
		padding-top: r(16px)
		border-top: 1px solid color(primary-100)
	+mq-min(lg)
		+layout-col(32px)
			padding-top: r(32px)
			border-top: 1px solid color(primary-100)

.news-cate
	padding-bottom: r(32px)
	border-bottom: 1px solid color(primary-600)
	&-heading
		display: flex
		align-items: center
		justify-content: space-between