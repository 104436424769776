.manufacture-item
	position: relative
	& + .manufacture-item
		margin-top: r(180px)
		+mq-max(md)
			margin-top: r(32px)
	&-img
		figure
			a
				+res-ratio(1312,550)
				+radius(r(48px))
				overflow: hidden
				display: block
			+mq-max(md)
				a
					border-radius: r(20px) r(20px) 0 0
					+res-ratio(16,10)
	&-caption
		+mq-min(md)
			position: absolute
			top: r(-60px)
			bottom: r(-60px)
			width: 100%
	&-content
		+bg-opa(primary-600,0.85)
		+text(white)
		padding: r(48px)
		justify-content: center
		+mq-max(md)
			padding: r(24px)
			border-radius: 0 0 r(20px) r(20px)