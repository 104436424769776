.home-4
	background-position: bottom right
	+text(white)
	+mq-max(lg)
		+bg-gra(primary)
	.img
		+mq-min(lg)
			padding-right: r(80px)
		figure
			filter: drop-shadow(6px 6px 15px rgba(0, 0, 0, 0.1))
			+res-ratio(572,496)
			img
				width: 100%
	.list-item
		.item
			margin-bottom: r(20px)
			padding-bottom: (20px)
			border-bottom: 1px dashed color(primary-400)
			display: flex
			align-items: center
			justify-content: space-between
			gap: r(24px)
			+mq-max(md)
				flex-direction: column
				align-items: flex-start
				gap: r(8px)
			.title
				+layout-row(24px)
				align-items: center
				@extend .body-1
				.num
					+circle(40px)
					flex: 0 0 r(40px)
					+border($color: white)
					+text(white)
					+trans-all
					font-weight: 700
					+context(".item",".item:hover")
						+bg(white)
						+text(primary-600)
			.link
				+mq-max(md)
					padding-left: r(64px)
					a
						padding-left: 0 !important
				a
					+layout-row(8px)
					align-items: center
					justify-content: flex-end
					padding-left: r(12px)
					+trans-all
					border-radius: 9999px
					span
						@extend .body-4
						text-transform: uppercase
						font-weight: 700
						white-space: nowrap
					em
						+circle(28px)
						+bg(white)
						+text(primary-600)
					&:hover
						+bg(white)
						+text(primary-600)
