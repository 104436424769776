.checkout-info-form
	.form-group
		display: flex
		align-items: center
		& + .form-group
			margin-top: r(20px)
		label
			white-space: nowrap
			flex: 1 1 0%
			text-transform: uppercase
			@extend .body-3
			font-weight: 700
			&.error
				color: #dd0705
				@extend .body-5
				margin-top: r(4px)
				text-transform: none
				font-weight: 400
				margin-top: r(4px)
				display: inline-block
		.form-control
			flex: 3 1 0%
			input[type="text"],select
				height: r(44px)
				@extend .body-4
				border-radius: r(20px)
				+placeholder
					+text(gray-300)
			textarea
				height: r(88px)
				@extend .body-4
				border-radius: r(20px)
				+placeholder
					+text(gray-300)
	.add-new-address
		a
			color: #068fdd
			text-decoration: underline
	@media screen and ( max-width: 576px )
		.form-group
			flex-wrap: wrap
			label
				flex: 0 0 100%
				margin-bottom: r(12px)
			.form-control
				flex: 0 0 100%

.checkout-shipping-wrap
	+mq-min(md)
		margin-top: r(32px)

.checkout-check-list
	padding-left: 0
	list-style: none

.checkout-check-item
	cursor: pointer
	& + .checkout-check-item
		margin-top: r(20px)
	label
		display: flex
		align-items: center
	input[type="radio"],input[type="checkbox"]
		position: absolute
		height: 1px
		width: 1px
		visibility: hidden
		clip: rect(0px, 0px, 0px, 0px)
		opacity: 0
		&:checked + .fake-radio
			&::before
				+icon("\f192")
				+text(primary-500)

	.fake-radio
		&::before
			+icon("\f111")
			margin-right: r(32px)
			font-size: r(16px)
			font-weight: 400

	.content
		width: calc( 100% - 2.08333333rem )
		border: 1px solid #e0e0e0
		border-radius: r(20px)
		display: flex
		.img
			width: r(80px)
			min-height: r(80px)
			display: flex
			align-items: center
			justify-content: center
			border-right: 1px solid #e0e0e0
		.text
			flex: 1
			display: flex
			justify-content: center
			padding: r(16px) r(20px)
			line-height: 1.333333
			flex: 1
			+layout-col(8px)
			table
				width: 100%
				td,th
					border: 1px solid #dee2e6
					padding: .75rem
					vertical-align: top

.checkout-issue-invoice-wrap
	.checkout-check-item
		.content
			border: 0
	.card-body
		display: none
		&.show
			display: block

.Module-194
	height: 100%
	.ModuleContent
		position: sticky
		top: r(160px)
		z-index: 3

.checkout-cart-summary
	.cart-summary-item
		display: flex
		justify-content: space-between
		margin-bottom: r(20px)
		padding-bottom: r(20px)
		border-bottom: 1px solid color(primary-100)
		.product-detail-promo
			width: 100%
		.product-link
			display: flex
			flex: auto
			align-items: center
			padding-right: r(20px)
			.img
				+flex-width(84px)
				margin-right: r(16px)
			.text
				flex: 1
		.product-price
			text-align: right
			flex: 1
			white-space: nowrap
			*
				* + *
					margin-top: r(4px)
			.total-price
				font-size: r(18px)
	.cart-summary-price
		hr
			overflow: visible
			text-align: inherit
			margin: r(20px) 0
			border: 0
			border-top: 1px solid #e5e5e5
		ul
			li
			+ li
				margin-top: 1rem

.checkout-point-wrap
	.point-slider
		height: r(4px)
		border: 0 !important
		background: #bcbcbc
		margin: 0 r(4px) r(12px)
		.ui-slider-handle
			border-radius: 50%
			top: 50%
			transform: translateY(-50%)
			width: 15px
			height: 15px
			border: 1px solid #666666
			background-color: #fff
		.ui-slider-range
			background-color: map-get($colors, primary-500)
			border: 0
	.point-slider-input
		label
			flex: 1
		input
			width: 156px
			background: #fafafa
			border: 1px solid #e0e0e0
			border-radius: 5px
			margin-left: r(12px)
			padding: 0 15px
			height: 40px

.checkout-shipping-wrap
	margin-top: r(32px)

.promotion-popup
	padding: r(40px) r(30px)
	border-radius: r(20px)
	width: r(600px)
	+mq-max(md)
		height: 60vh
	.promotion-list
		padding: r(20px)
	.promotion-item
		padding-bottom: r(12px)
		margin-bottom: r(12px)
		border-bottom: 1px solid color(primary-100)
		position: relative
		padding-right: r(114px)
		&-btn
			display: flex
			gap: r(8px)
			position: absolute
			right: 0
			top: 0
			a
				display: flex
				+flex-center
				+circle(30px)
				+bg(white)
				+text(primary-500)
		&-choose
			&.no-radio
				label
					padding-left: r(20px)
			label
				display: flex
				gap: r(8px)
				input
					+hide
					&:checked
						& ~ .radio
							&::before
								+icon("\f192")
								+text(primary-500)
				.radio
					position: relative
					top: r(2px)
					&::before
						+icon("\f111")
						@extend .body-3
						+text(gray-200)
				.desc
					@extend .body-3
					+text(gray-300)
					+layout-col(4px)
