.section-xlarge
	padding: r(100px) 0
	+mq-max(lg)
		padding: r(40px) 0

.section-large
	padding: r(80px) 0
	+mq-max(lg)
		padding: r(40px) 0

.section-medium
	padding: r(72px) 0
	+mq-max(lg)
		padding: r(40px) 0

.section
	padding: r(60px) 0
	+mq-max(lg)
		padding: r(40px) 0

.section-small
	padding: r(48px) 0
	+mq-max(lg)
		padding: r(40px) 0

.section-footer-overlay
	+mq-min(xl)
		min-height: r(868px)
		margin-bottom: r(-128px)
		padding-bottom: r(128px) !important
