.cart-section,.checkout-section,.checkout-complete-section
	.card-item
		@extend .body-3
		+bg(white)
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08)
		border-radius: r(20px)
		overflow: hidden
		& + .card-item
			margin-top: r(32px)
		.card-heading
			padding: r(16px) r(32px)
			+text(primary-500)
			border-bottom: 1px solid #d9d9d9
			+mq-max(sm)
				padding: r(20px)
			.card-title
				font-family: $font-2
				@extend .heading-5
				display: flex
				align-items: center
				gap: r(12px)
				font-weight: 700
				text-transform: uppercase
		.card-body
			padding: r(20px) r(32px)
			line-height: 1.333333
			+mq-max(sm)
				padding: r(20px)

.cart-product-header
	display: flex
	gap: r(20px)
	font-weight: 500
	margin-bottom: r(16px)
	padding-bottom: r(16px)
	border-bottom: 1px solid color(gray-50)
	+remove(md,max)
	&-img
		+flex-width(r(80px))
	&-content
		display: flex
		flex: 1
		gap: r(20px)
		align-items: center
	&-name
		flex: 1
	&-price
		+flex-width(r(160px))
	&-subtotal
		+flex-width(r(160px))
	&-amount
		+flex-width(r(160px))
	&-btn
		+flex-width(r(60px))
		text-align: right

.cart-product-item
	gap: r(20px)
	font-weight: 500
	& + .cart-product-item
		margin-top: r(20px)
		padding-top: r(20px)
		border-top: 1px solid color(primary-100)
	&-img
		+flex-width(r(80px))
		+mq-max(md)
			align-items: flex-start !important
	&-content
		flex: 1
		gap: r(20px)
		align-items: center
		+mq-max(md)
			gap: r(8px)
	&-name
		flex: 1
	&-price
		+layout-col(8px)
		+flex-width(100%)
		+mq-min(md)
			+flex-width(r(160px))
		del
			+text(gray-400)
		.discount
			display: inline-block
			padding: r(2px) r(4px) r(2px) r(4px)
			+bg(primary-400)
			+text(white)
			@extend .body-5
			font-weight: 700
			border-radius: r(4px)
	&-subtotal
		+text(primary-400)
		+flex-width(100%)
		+mq-min(md)
			+flex-width(r(160px))
	&-amount
		+flex-width(r(140px))
		.spin-input-wrap
			width: r(140px)
	&-btn
		+text(gray-400)
		+flex-width(r(60px))
		+mq-min(md)
			text-align: right

.cart-order-info
	.total-price
		padding-top: r(16px)
		border-top: 1px solid #d9d9d9
	ul
		+layout-col(16px)
		li
			span
				white-space: nowrap

.btn-cart-group
	justify-content: flex-end
	flex-wrap: wrap
	+mq-max(sm)
		justify-content: center
