.form-group,.input-group
	+all-text-inputs
		height: r(44px)
		width: 100%
		background-color: transparent
		border: 1px solid color(gray-100)
		padding: 0 r(20px)
		+radius(r(22px))
		+fz(14px)
		+placeholder
			+text(gray-300)
			opacity: 1
	input[type="password"]
		height: r(44px) !important
	select
		height: r(44px)
		width: 100%
		background-color: transparent
		border: 1px solid color(gray-100)
		padding: 0 r(20px)
		+radius(r(22px))
		+fz(14px)
		+select-bg($bg: "../img/sl-bg-gray.svg")
		background-size: r(14px) r(16px)
	textarea
		height: r(142px)
		padding: r(12px) r(20px)
		border-radius: r(20px)
	.settinglabel
		margin-bottom: r(8px)
		display: inline-block
		@extend .body-3
		text-transform: uppercase
		font-weight: 700
		small
			color: red

.frm-btnwrap
	margin-top: r(20px)
	.label
		display: none

.frm-btn
	position: relative
	display: inline-block
	margin-left: auto
	min-width: r(115px)
	&:before
		+center(Y)
		right: r(30px)
		content: '\f178'
		font-family: "Font Awesome 6 Pro"
		+text(white)
		pointer-events: none
		font-weight: 300
	input[type="submit"]
		+trans-all
		+bg(white)
		+text(secondary-500)
		border: 0
		height: r(44px)
		width: 100%
		cursor: pointer
		font-size: r(14px)
		text-transform: uppercase
		font-weight: 500