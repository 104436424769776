.dealer-map-hover
	background: radial-gradient(50% 50% at 50% 50%, #016CB9 0%, #00477B 100%)
	+text(white)

.dealer-group-list
	padding-left: r(24px)
	+mq-max(lg)
		padding-left: r(48px)
	+content-margins
		margin-top: r(32px)
		padding-top: r(32px)
		border-top: 1px solid #d9d9d9
	.dealer-group
		font-family: $font-2
		font-weight: 700
		.dealer-group-item
			position: relative
			+layout-row(32px)
			align-items: center
		.dot
			position: absolute
			left: r(-56px)
			+circle(20px)
			+center(Y)
			margin-left: 0 !important
			z-index: 2
			background-color: #00477B
		.quantity
			+flex-width(r(77px))
			+fz(64px)
			+trans-all
		.name
			+trans-all
			@extend .body-1
		+on-hover
			.quantity
				+fz(90px)
				+flex-width(r(108px))
			.name
				+fz(32px)
		&:nth-child(1)
			.dot
				border: r(5px) solid color(primary-50)
			.quantity
				+text(primary-50)
		&:nth-child(2)
			.dot
				border: r(5px) solid color(primary-200)
			.quantity
				+text(primary-200)
		&:nth-child(3)
			.dot
				border: r(5px) solid color(primary-400)
			.quantity
				+text(primary-400)

.dealer-map
	svg
		width: 100%
		height: auto
		+mq-min(lg)
			max-height: 80vh
		.pinpoint-group
			+trans-all
			path,rect
				+trans-all
			&.no-hovering
				path[fill="#00365D"],path[fill="#00365d"],path[fill="#3C9B12"],path[fill="#A80000"],path[style="fill:#e6f1f9"],rect
					fill: color(gray-100) !important
				path[fill="#333"]
					opacity: 0.5

.dealer-item
	display: flex
	flex-direction: column
	&-img
		figure
			a
				display: block
				+res-ratio(416,260)
				border-radius: r(32px)
				overflow: hidden
	&-caption
		+layout-col(20px)
		margin-top: r(20px)
	&-address
		+text(gray-900)
		@extend .body-1
		ul
			+layout-col(20px)
			li
				display: flex
				gap: r(8px)
				span
					+text(primary-500)
					position: relative
					top: r(6px)
					+flex-width(r(20px))