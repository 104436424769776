.supply-item
	+even
		.row
			flex-direction: row-reverse
	.img
		figure
			overflow: hidden
			+radius(r(12px))
			+res-ratio(640,428)
	.content
		+mq-min(lg)
			+context(".item",".item:nth-child(2n + 1)")
				padding-left: r(60px)
			+context(".item",".item:nth-child(2n)")
				padding-right: r(60px)
		+mq-max(md)
			+layout-row(20px)
			.icon
				+flex-width(r(60px))
			.layout
				flex: auto
