.article-content
	@extend .body-1
	+text(gray-700)
	> *
		+ *
			margin-top: r(32px)
	ul
		list-style: disc
		padding-left: 1.25rem
	table
		td,th
			border: 1px solid #dee2e6
			padding: .75rem
			vertical-align: top
	h2
		font-size: r(20px)
		font-weight: 700
	h3
		font-size: r(18px)
		font-weight: 600
	* + h2
		margin-top: r(28px)
	em,i
		font-style: italic
	sup
		vertical-align: super
		font-size: 80%

.expand-content
	overflow: hidden
	position: relative
	transition: max-height 1s ease-in-out
	&.show
		overflow: visible
		max-height: none
	.expand-content-overlay
		position: absolute
		bottom: 0
		width: 100%
		height: r(160px)
		background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 17.71%, rgba(255, 255, 255, 0.75) 35.94%, rgba(255, 255, 255, 0.904472) 56.25%, #FFFFFF 100%)
		display: flex
		justify-content: center
		align-items: flex-end
		padding-bottom: r(16px)
		pointer-events: none
		.expand-content-trigger
			pointer-events: all
		&.active
			background: transparent
			bottom: r(-48px)