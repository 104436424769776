.about-4
	position: relative
	z-index: 1
	+mq-min(lg)
		&::before
			+pseudo
			height: r(181px)
			width: r(976px)
			background-image: url(../img/about-bg-2.png)
			background-repeat: no-repeat
			background-position: center
			background-size: contain
			bottom: 0
			left: 0
			z-index: -1