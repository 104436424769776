.recruitment-detail-brief
	table
		width: 100%
		@extend .body-1
		border-top: 1px solid #D9D9D9
		td
			padding: r(20px)
			+first(1)
				font-weight: 700
				white-space: nowrap
		tr
			+even
				+bg(primary-50)

.recruitment-detait-attributes
	margin-top: r(20px)
	padding-top: r(20px)
	border-top: 1px solid #D9D9D9
	padding-left: r(20px)
	.attribute-item
		+row(20px)
		.title
			font-weight: 700
			+text(primary-500)
			+flex-width(100%)
			+mq-min(lg)
				+flex-width(2 / 9 * 100%)
		.content
			+flex-width(100%)
			+mq-min(lg)
				+flex-width(7 / 9 * 100%)

.recruitment-side-item
	border-radius: r(20px)
	overflow: hidden
	&-title
		padding: r(12px) r(32px)
		+bg(primary-600)
		+text(white)
		@extend .body-1
	&-body
		@extend .body-2
		padding: r(20px) r(32px)
		+bg(primary-50)

.recruitment-other-list
	ul
		li
			padding-bottom: r(8px)
			border-bottom: 1px solid #D9D9D9
			& + li
				margin-top: r(8px)

.apply-frm
	position: relative
	margin: 0
	background: #fff
	line-height: 1.375
	padding: r(52px) r(30px)
	overflow: hidden
	+mq-min(sm)
		font-size: 16px
	h4
		font-size: 1.5em
		line-height: 2em
	> .row
		+gap-override(20px)
		+mq-max(md)
			> *
				padding: 0 0 1.04167rem 1.04167rem !important
	.form-group
		.label
			display: none
		input[type="text"], input[type="date"], select, textarea
			border-radius: 0.25em
			height: 2.5em
			font-size: 1em
		textarea
			height: 6.25em
	.frm-btnwrap
		margin-top: 0
	.frm-btn
		min-width: auto
		width: 100%
		+mq-min(sm)
			font-size: 16px
		&::before
			display: none
		input[type="submit"]
			height: 2.5em
			border-radius: 999px
			font-size: inherit
	.attachfile1,.attachfile2
		position: relative
		.label
			display: flex
			align-items: center
			justify-content: center
			height: 2.5em
			padding: 0 2em
			background: #EDEDED
			border-radius: 999px
			+text(body)
			cursor: pointer
			font-size: 1em
		.ruBrowse
			display: none
		.ruFileInput
			height: r(40px)
			width: 100%
			cursor: pointer
		.ruInputs
			position: static
			li
				margin-bottom: 0
				height: auto
		.ruFileWrap
			height: auto
			position: static

.recruitment-detail-page
	.fancybox-iframe
		width: 928px !important
