.dealer-detail-address
	padding: r(56px) r(36px)
	border-radius: r(20px)
	@extend .box-shadow-1
	@extend .body-2
	+mq-max(lg)
		padding: r(28px)
	ul
		+layout-col(16px)
		li
			display: flex
			gap: r(32px)
			align-items: center
			+mq-max(lg)
				gap: r(8px)
			span
				+text(primary-500)
				+fz(24px)
				position: relative
				+flex-width(24px)
