.product-filter
	+row(64px)
	+mq-max(md)
		+row(20px)
	&-item
		+mq-min(md)
			+layout-row(20px)
			align-items: center
		+mq-max(md)
			+layout-col(8px)
		&-title
			+text(gray-700)
		&-body
			flex: 1
			.form-group
				select
					+text(gray-400)
					+select-bg($bg: "../img/sl-bg-gray.svg")
					background-size: r(14px) r(16px)
		&-1
			+flex-width((278 + 60) / 1312 * 100%)
			+mq-max(md)
				+flex-width(100%)
		&-2
			+flex-width((528 + 60) / 1312 * 100%)
			+mq-max(md)
				+flex-width(100%)
