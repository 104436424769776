.product-list
	.row
		+mq-min(md)
			+gap-override(32px,60px)

.product-slider
	.swiper-slide
		.product-item
			opacity: 0.2
		&-visible
			.product-item
				opacity: 1

.product-item
	display: flex
	flex-direction: column
	+trans-all
	&-img
		padding-top: 100%
		position: relative
		+trans-all
		figure
			+bg(primary-200)
			border-radius: r(8px)
			position: absolute
			+fill
			display: flex
			+flex-center
			overflow: hidden
			img
				+trans-all
	&-caption
		padding-top: r(20px)
		flex: 1 1 0%
		display: flex
		flex-direction: column
	&-price
		font-weight: 700
		@extend .body-1
	&-title
		@extend .heading-4
		+line(2)
		+text(primary-500)
		font-weight: 700
		margin-top: r(8px)
	&-brief
		margin-top: r(8px)
		@extend .body-3
		margin-bottom: r(32px)
		+mq-max(lg)
			margin-bottom: r(16px)
	&-purchase
		margin-top: auto
		.btn-icon
			i
				+remove(sm,max)
				margin: 0 r(4px)
	+on-hover
		.product-item-img
			box-shadow: 0px 0px 10px rgba(0,0,0,0.15)
			img
				transform: scale(1.05)
		.product-item-title
			text-decoration: underline

.cart-suggest-product
	.product-item
		&-title
			@extend .heading-5
