
.global-footer
	position: relative
	+text(white)
	@extend .body-3
	background-image: url(../img/footer-bg.png)
	background-size: cover
	background-repeat: no-repeat
	background-position: center top
	+mq-min(xl)
		&:before
			+pseudo
			width: 100%
			height: r(128px)
			background-image: url(../img/footer-divider.png)
			background-position: bottom center
			background-repeat: no-repeat
			background-size: contain
			bottom: 100%
			left: 0
			z-index: 1
			pointer-events: none
	.footer-top
		padding: r(40px) 0
		+mq-min(lg)
			padding: r(88px) 0 r(32px)

.footer-link
	ul
		+layout-col(8px)
		li
			a
				+text(primary-50)
				@extend .link-hover

.subscribefrm
	position: relative
	input
		padding: r(12px) r(60px) r(12px) r(24px)
		@extend .body-4
		font-weight: 500
		border: 0
		width: 100%
		+placeholder
			+text(gray-200)
			font-weight: 500
	button
		+reset-button
		position: absolute
		+center(Y)
		right: r(16px)
		+text(primary-500)
		+fz(20px)

	.fa-exclamation-triangle
		position: absolute
		top: 100%
		left: 0
		margin-top: r(4px)

.footer-address
	padding: r(32px) 0
	border-top: 1px solid color(primary-500)
	@extend .body-3
	h3
		font-family: 'Nunito Sans'
		margin-bottom: r(12px)
		font-weight: 700
		text-transform: uppercase
	ul
		+layout-col(12px)
		li
			+text(primary-50)
			font-weight: 400
			strong
				+text(white)

.footer-copyright
	padding: r(28px) 0
	+bg(primary-700)

.social-list
	ul
		+layout-row(12px)
		li
			a
				+circle(40px)
				+bg(primary-50)
				+trans-all
				span
					+text-gradient(primary)
					+fz(16px)
				+on-hover
					+bg(primary-100)

.copyright
	+mq-min(sm)
		text-align: right

body
	+mq-min(xl)
		.global-footer
			margin-top: r(128px)
