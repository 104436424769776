.product-detail-images
	.img
		padding-top: 100%
		position: relative
		figure
			// +bg(primary-200)
			border: 1px solid color(primary-200)
			border-radius: r(8px)
			position: absolute
			+fill
			display: flex
			+flex-center
			img
				width: 100%
	.swiper-slide-count
		position: absolute
		right: r(32px)
		top: r(32px)
		z-index: 2
		bottom: auto
		width: auto
		left: auto

.product-detail-content
	strong
		@extend .body-1
		+text(gray-500)

.product-detail-purchase
	+row(24px)
	> *
		+flex-width(50%)
	.btn-icon
		width: 100%
		height: r(48px)
		&::before
			opacity: 1

.product-detail-attributes
	.tabs-nav
		+layout-row(80px)
		+bg(primary-50)
		justify-content: center
		overflow: auto
		+mq-max(md)
			justify-content: flex-start
			padding: 0 r(20px)
			+layout-row(32px)
		li
			a
				display: flex
				align-items: center
				height: r(70px)
				@extend .heading-5
				+text(gray-300)
				border-bottom: 1px solid transparent
				white-space: nowrap
			&.active
				a
					+text(primary-500)
					border-bottom: 1px solid color(primary-500)
	.expand-content
		max-height: r(600px)
.spin-input-wrap
	+layout-row(16px)
	height: r(48px)
	border: 1px solid color(gray-100)
	align-items: center
	+radius(999px)
	padding: 0 r(20px)
	.btn-spin,.spin-btn
		display: flex
		+flex-center
		+text(secondary-200)
		+radius(12px)
		cursor: pointer
	input
		border: 0
		text-align: center
		width: 80%