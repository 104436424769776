.document-filter
	+row(64px)
	+mq-max(md)
		+row(20px)
	&-item
		.form-group
			select
				+select-bg($bg: "../img/sl-bg.svg")
				background-size: r(19px) r(10px)
				border-radius: r(8px)
		+mq-min(sm)
			+layout-row(20px)
			align-items: center
		+mq-max(sm)
			+layout-col(8px)
		&-title
			font-weight: 700
			+text(gray-600)
		&-body
			flex: 1
		&-search
			input
				+select-bg($bg: "../img/search-bg.svg")
				background-size: r(16px) r(16px)
		&-1
			+flex-width((448 + 64) / 1312 * 100%)
			+mq-max(md)
				+flex-width(50%)
			+mq-max(sm)
				+flex-width(100%)
		&-2
			+flex-width((205 + 64) / 1312 * 100%)
			+mq-max(md)
				+flex-width(50%)
			+mq-max(sm)
				+flex-width(100%)
		&-3
			flex: 1

.document-item-wrap
	+odd
		.document-item
			+bg(primary-50)
	+even
		.document-item
			+bg(primary-500)
			+text(white)
			&-meta
				+text(white)

.document-item
	padding: r(32px) (32px) r(20px)
	border-radius: r(20px)
	+layout-col(16px)
	&-title
		@extend .body-1
		font-weight: 700
		margin-bottom: r(16px)
	&-meta
		margin-top: auto
		display: flex
		gap: r(16px)
		justify-content: space-between
		align-items: center
		+text(gray-300)
		@extend .body-5
	&-download
		display: flex
		align-items: center
		gap: r(8px)
	&:hover
		.document-item-title
			text-decoration: underline