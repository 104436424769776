@charset "UTF-8";
/* Escape to Parent -------------------------*/
/* Context -------------------------*/
/* Abstract Extend -------------------------*/
/* Forces GPU acceleration of the item, speeding up performance */
/* Cover Background */
/* Flex Center Item */
/* Block Center Item */
/* Reset default style */
/* Text color -------------------------*/
/* Text color opacity -------------------------*/
/* Background color -------------------------*/
/* Background gradient -------------------------*/
/* Background color opacity -------------------------*/
/* Background color -------------------------*/
/* Breakpoint -------------------------*/
/* Pseudo -------------------------*/
/* Center Position -------------------------*/
/* Font size, line height rhythm -------------------------*/
/* Child element fill parent size -------------------------*/
/* Has Divider -------------------------*/
/* Content margins -------------------------*/
/* Border Radius -------------------------*/
/* TRBL -------------------------*/
/* Border -------------------------*/
/* Line Clamp -------------------------*/
/* Responsive Ratio -------------------------*/
/* Image Cover -------------------------*/
/* Image Contain -------------------------*/
/* Box size -------------------------*/
/* Input placeholder -------------------------*/
/* Select background -------------------------*/
/* Circle -------------------------*/
/* Scroll Bar -------------------------*/
/* Hide -------------------------*/
/* Remove -------------------------*/
/* Disable hover on mobile -------------------------*/
/* Calc -------------------------*/
/* Create Flex Row -------------------------*/
/* Override Row -------------------------*/
/* Flex width -------------------------*/
/* Create Flex Gap -------------------------*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*, *:before, *:after {
  outline: none;
  box-sizing: border-box; }

img, video, iframe {
  max-width: 100%; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

a {
  color: inherit; }

strong, b {
  font-weight: 700; }

input, select, textarea, button {
  font-family: inherit; }

button {
  cursor: pointer; }

/* ==========================================================================
    GRIDLEX
    Just a Flexbox Grid System - v. 2.7.1
========================================================================== */
.row {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  margin-left: -1.66667rem;
  margin-bottom: -1.66667rem; }

.row > * {
  width: 100%; }

.row > * {
  box-sizing: border-box;
  padding: 0 0 1.66667rem 1.66667rem; }

.col {
  flex: 1 1 0%; }

@media screen and (max-width: 767px) {
  .row {
    margin-left: -1.33333rem;
    margin-bottom: -1.33333rem; }
  .row > * {
    padding: 0 0 1.33333rem 1.33333rem; } }

/************************
    HELPERS SUFFIXES
*************************/
.row.no-gutter {
  margin: 0; }
  .row.no-gutter > * {
    padding: 0; }

.row.equal-height > * {
  align-self: stretch; }
  .row.equal-height > * > * {
    height: 100%; }

/************************
    GRID BY NUMBER
*************************/
.row.row-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row.row-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row.row-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row.row-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row.row-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row.row-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.row.row-7 > * {
  flex: 0 0 14.28571%;
  max-width: 14.28571%; }

.row.row-8 > * {
  flex: 0 0 12.5%;
  max-width: 12.5%; }

.row.row-9 > * {
  flex: 0 0 11.11111%;
  max-width: 11.11111%; }

.row.row-10 > * {
  flex: 0 0 10%;
  max-width: 10%; }

.row.row-11 > * {
  flex: 0 0 9.09091%;
  max-width: 9.09091%; }

.row.row-12 > * {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

@media (min-width: 576px) {
  .row.row-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row.row-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row.row-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row.row-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row.row-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row.row-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .row.row-sm-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .row.row-sm-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .row.row-sm-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%; }
  .row.row-sm-10 > * {
    flex: 0 0 10%;
    max-width: 10%; }
  .row.row-sm-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%; }
  .row.row-sm-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 768.98px) {
  .row.row-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row.row-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row.row-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row.row-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row.row-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row.row-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .row.row-md-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .row.row-md-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .row.row-md-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%; }
  .row.row-md-10 > * {
    flex: 0 0 10%;
    max-width: 10%; }
  .row.row-md-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%; }
  .row.row-md-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 1024.98px) {
  .row.row-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row.row-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row.row-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row.row-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row.row-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row.row-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .row.row-lg-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .row.row-lg-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .row.row-lg-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%; }
  .row.row-lg-10 > * {
    flex: 0 0 10%;
    max-width: 10%; }
  .row.row-lg-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%; }
  .row.row-lg-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 1280px) {
  .row.row-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row.row-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row.row-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row.row-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row.row-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row.row-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .row.row-xl-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .row.row-xl-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .row.row-xl-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%; }
  .row.row-xl-10 > * {
    flex: 0 0 10%;
    max-width: 10%; }
  .row.row-xl-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%; }
  .row.row-xl-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 1440px) {
  .row.row-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row.row-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row.row-xxl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row.row-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row.row-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row.row-xxl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .row.row-xxl-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .row.row-xxl-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .row.row-xxl-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%; }
  .row.row-xxl-10 > * {
    flex: 0 0 10%;
    max-width: 10%; }
  .row.row-xxl-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%; }
  .row.row-xxl-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

/************************
    COLS SIZES
*************************/
.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.off-0 {
  margin-left: 0; }

.off-1 {
  margin-left: 8.33333%; }

.off-2 {
  margin-left: 16.66667%; }

.off-3 {
  margin-left: 25%; }

.off-4 {
  margin-left: 33.33333%; }

.off-5 {
  margin-left: 41.66667%; }

.off-6 {
  margin-left: 50%; }

.off-7 {
  margin-left: 58.33333%; }

.off-8 {
  margin-left: 66.66667%; }

.off-9 {
  margin-left: 75%; }

.off-10 {
  margin-left: 83.33333%; }

.off-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768.98px) {
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1024.98px) {
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1280px) {
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1440px) {
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

html {
  font-size: 3.6vw; }
  @media (min-width: 577px) {
    html {
      font-size: 2.4vw; } }
  @media (min-width: 769px) {
    html {
      font-size: 1.5vw; } }
  @media (min-width: 1025px) {
    html {
      font-size: 1.2vw; } }
  @media (min-width: 1441px) {
    html {
      font-size: 1vw; } }

body {
  line-height: 1;
  font-family: 'Bai Jamjuree';
  color: #333333;
  font-weight: 500; }
  @media (min-width: 1281px) {
    body {
      font-size: clamp(14px, 0.83333rem, 1vw); } }

*, *:before, *:after {
  box-sizing: border-box;
  outline: none; }

a {
  text-decoration: none; }

.swiper-equal-height .swiper-slide {
  height: auto; }
  .swiper-equal-height .swiper-slide > * {
    height: 100%; }

.swiper-no-overflow-hidden {
  overflow: visible; }

.fa-exclamation-triangle {
  line-height: 2.5;
  color: #ff3333;
  font-weight: 400;
  font-family: 'Bai Jamjuree', sans-serif; }
  .fa-exclamation-triangle:before {
    font-family: 'Font Awesome 6 Pro'; }

.frm-btn-reset {
  display: none !important; }

.frm-btnwrap .label {
  display: none; }

div[id*="popup_"] {
  padding: 0; }
  div[id*="popup_"] .fancybox-close-small {
    opacity: 1;
    width: auto;
    height: auto;
    padding: 0;
    right: 0.83333rem;
    top: 0.83333rem; }
    div[id*="popup_"] .fancybox-close-small svg {
      height: 1.66667rem;
      width: 1.66667rem; }
      div[id*="popup_"] .fancybox-close-small svg path {
        fill: #999; }
  div[id*="popup_"] img {
    max-height: 90vh; }

.alert {
  position: relative;
  padding: 0.75rem 1.04167rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  display: block;
  line-height: 1.25; }
  .alert.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb; }
  .alert.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb; }
  .alert button {
    display: none; }

.login-box, .wrap-register, .wrap-recover, .wrap-resetpass, .wrap-confirmreg {
  max-width: 400px;
  margin: 60px auto;
  padding: 1.875rem;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03); }
  @media screen and (max-width: 1024.98px) {
    .login-box, .wrap-register, .wrap-recover, .wrap-resetpass, .wrap-confirmreg {
      font-size: 14px; } }

.wrap-confirmreg {
  background: transparent;
  max-width: 600px;
  box-shadow: none; }

.login-logo, .pagetitle, .postname, .wrap-recover h1 {
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.375;
  font-weight: 700;
  margin-bottom: 1.04167rem; }

.wrap-resetpass h1 {
  font-size: 1.04167rem;
  margin-bottom: 1.04167rem;
  line-height: 1.375; }

.loginstandard .cart-login .social-login {
  padding: 0; }

.postname {
  text-align: left; }

.login-box-msg {
  font-size: 1.125rem;
  margin-bottom: 1rem; }

.login-box input[type="text"], .login-box input[type="password"], .login-box select, .wrap-register input[type="text"], .wrap-register input[type="password"], .wrap-register select, .account-form input[type="text"], .account-form input[type="password"], .account-form select, .address-form input[type="text"], .address-form input[type="password"], .address-form select, .wrap-recover input[type="text"], .wrap-recover input[type="password"], .wrap-recover select, .wrap-resetpass input[type="text"], .wrap-resetpass input[type="password"], .wrap-resetpass select {
  height: 2.29167rem;
  border-radius: 499px; }

.login-box input[type=submit], .wrap-register input[type=submit], .account-form input[type=submit], .address-form input[type=submit], .wrap-recover input[type=submit], .wrap-resetpass input[type=submit] {
  height: 2.08333rem;
  width: 10.41667rem;
  border-radius: 999px;
  color: #fff;
  border: 0;
  background: #006CB9;
  font-weight: 700;
  display: block;
  margin-left: auto; }

.login-box input:disabled, .wrap-register input:disabled, .account-form input:disabled, .address-form input:disabled, .wrap-recover input:disabled, .wrap-resetpass input:disabled {
  color: #666;
  background-color: transparent;
  font-style: italic; }

.login-box span.fa-exclamation-triangle, .wrap-register span.fa-exclamation-triangle, .account-form span.fa-exclamation-triangle, .address-form span.fa-exclamation-triangle, .wrap-recover span.fa-exclamation-triangle, .wrap-resetpass span.fa-exclamation-triangle {
  font-size: 12px;
  color: red;
  line-height: 2.5; }

.login-box input[type=submit] {
  width: 100%;
  border-radius: 20px; }

.wrap-register .settingrow > *, .account-form .settingrow > *, .address-form .settingrow > *, .wrap-recover .settingrow > *, .wrap-resetpass .settingrow > * {
  flex: 0 0 100%;
  max-width: 100%; }

.wrap-register .form-group, .account-form .form-group, .address-form .form-group, .wrap-recover .form-group, .wrap-resetpass .form-group {
  margin-bottom: 20px; }
  .wrap-register .form-group label, .account-form .form-group label, .address-form .form-group label, .wrap-recover .form-group label, .wrap-resetpass .form-group label {
    text-transform: uppercase; }
  .wrap-register .form-group > *, .account-form .form-group > *, .address-form .form-group > *, .wrap-recover .form-group > *, .wrap-resetpass .form-group > * {
    flex: auto; }
  .wrap-register .form-group.check-group > *, .account-form .form-group.check-group > *, .address-form .form-group.check-group > *, .wrap-recover .form-group.check-group > *, .wrap-resetpass .form-group.check-group > * {
    flex: inherit; }
  .wrap-register .form-group.check-group label, .account-form .form-group.check-group label, .address-form .form-group.check-group label, .wrap-recover .form-group.check-group label, .wrap-resetpass .form-group.check-group label {
    flex: 1;
    max-width: none; }

.account-form .form-group {
  display: flex;
  gap: 1.04167rem;
  align-items: center; }
  .account-form .form-group label {
    max-width: 7.91667rem;
    flex: 0 0 7.91667rem;
    width: 100%; }

.passwordrecovery input[type=submit], .wrap-register input[type=submit] {
  margin: 0 auto; }

.fa-exclamation-triangle {
  font-size: 12px;
  color: red;
  line-height: 2; }

.profile-container {
  padding: 3rem 15px; }

.user-sidebar, .user-sidelink {
  padding: 1.66667rem;
  background: #fff;
  border-radius: 1.04167rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08); }

.userinfo figure {
  display: flex;
  align-items: center; }
  .userinfo figure img {
    border-radius: 50%; }
  .userinfo figure figcaption {
    padding-left: 1.04167rem;
    color: #006CB9;
    display: flex;
    flex-direction: column; }
    .userinfo figure figcaption > * + * {
      margin-top: 0.41667rem; }

.userinfo .user-name {
  font-weight: 700;
  font-size: clamp(12px, 1.04167rem, 1.25rem); }
  @media (max-width: 768px) {
    .userinfo .user-name {
      font-size: clamp(13px, 1.04167rem, 1.25rem); } }

.user-sidelink {
  margin-top: 1.66667rem; }
  .user-sidelink ul li + li {
    margin-top: 0.83333rem;
    border-top: 1px solid #eee;
    padding-top: 0.83333rem; }
  .user-sidelink ul li.active a {
    color: #006CB9; }

.account-info, .coin-info, .cart-history, .order-info, .order-information, .order-product, .order-total, .address-info {
  background: #fff;
  border-radius: 1.04167rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08); }
  .account-info .link, .coin-info .link, .cart-history .link, .order-info .link, .order-information .link, .order-product .link, .order-total .link, .address-info .link {
    color: #858585; }

.info-heading, .posttitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.83333rem 1.66667rem;
  font-weight: 700;
  color: #006CB9;
  font-family: "Nunito Sans";
  border-bottom: 1px solid #d9d9d9; }
  .info-heading .posttitle, .info-heading .postname, .posttitle .posttitle, .posttitle .postname {
    margin-bottom: 0;
    padding: 0;
    border-bottom: 0; }
  .info-heading .link, .posttitle .link {
    color: #858585; }

.account-info .info-detail {
  padding: 1.04167rem 1.66667rem; }
  .account-info .info-detail .group .label {
    color: #787878; }

.account-order, .address-list, .account-form, .order-info {
  padding: 1.04167rem 1.66667rem; }

.address-list .address-col + .address-col {
  padding-top: 1.04167rem;
  margin-top: 1.04167rem;
  border-top: 1px solid #CCE2F1; }

.address-list .address {
  display: flex;
  flex-direction: column;
  position: relative; }
  .address-list .address > * + * {
    margin-top: 0.41667rem; }
  .address-list .address .type {
    align-items: center;
    background-color: #006CB9;
    border-radius: 4px;
    color: #fff;
    display: inline-flex;
    font-size: .75rem;
    height: 2em;
    justify-content: center;
    line-height: 1.5;
    padding-left: .75em;
    padding-right: .75em;
    white-space: nowrap;
    margin-bottom: .9375rem;
    position: absolute;
    right: 0;
    top: 0; }
  .address-list .address .name {
    color: #006CB9;
    font-weight: 700; }
  .address-list .address .addr-detail ul {
    display: flex;
    flex-direction: column; }
    .address-list .address .addr-detail ul > * + * {
      margin-top: 0.41667rem; }
  .address-list .address .btn-editaddr {
    color: #006CB9;
    display: inline-flex;
    align-items: center;
    gap: 0.41667rem; }
    .address-list .address .btn-editaddr::before {
      content: "";
      font-family: 'Font Awesome 6 Pro'; }
  .address-list .address .btn-deleteaddr {
    color: #f60000;
    margin-left: 1.04167rem;
    display: inline-flex;
    align-items: center;
    gap: 0.41667rem; }
    .address-list .address .btn-deleteaddr::before {
      content: "";
      font-family: 'Font Awesome 6 Pro'; }

.cart-history, .order-product {
  overflow: auto; }
  .cart-history table, .order-product table {
    width: 100%;
    font-size: 14px; }
    .cart-history table thead th, .order-product table thead th {
      font-weight: 700;
      padding: .75rem .75rem;
      background-color: #006CB9;
      color: #fff;
      border: 1px solid #CCE2F1; }
    .cart-history table tbody td, .order-product table tbody td {
      border: 1px solid #CCE2F1;
      padding: .75rem .75rem;
      vertical-align: top; }
      .cart-history table tbody td a, .order-product table tbody td a {
        display: block;
        color: #006CB9; }
        .cart-history table tbody td a + .name, .order-product table tbody td a + .name {
          margin-top: 5px; }
        .cart-history table tbody td a:hover, .order-product table tbody td a:hover {
          text-decoration: underline; }

.order-product table td {
  text-align: center; }
  .order-product table td .product {
    display: flex;
    align-items: center; }
    .order-product table td .product .img {
      width: 80px; }
    .order-product table td .product .caption {
      flex: 1 1 0%;
      padding-left: 20px;
      font-size: 14px; }

@media screen and (max-width: 576px) {
  .order-product table {
    width: 150%; }
    .order-product table td:first-child, .order-product table th:first-child {
      width: 50%; }
    .order-product table td .product .caption, .order-product table th .product .caption {
      text-align: left; } }

.order-total {
  width: 400px;
  max-width: 100%;
  margin-left: auto;
  margin-top: 1rem;
  font-size: 14px; }
  .order-total table {
    width: 100%; }
    .order-total table tr td {
      text-align: right;
      padding: .5rem .75rem; }
      .order-total table tr td.total {
        font-weight: 700;
        font-size: 18px;
        color: #006CB9; }
    .order-total table tr:first-child td {
      padding-top: 0; }

.regcomplete span, .wrap-confirmreg span {
  display: block;
  margin-bottom: 20px;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  padding: 0.75rem 1.04167rem; }

.order-information .title {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #ccc; }

.order-information ul {
  font-size: 14px; }
  .order-information ul li.name {
    font-size: 16px; }

.cart-wrapper {
  position: relative; }

.cart {
  text-align: center;
  position: relative;
  top: 2px;
  height: 1.25rem; }
  .cart .cart-toggle {
    cursor: pointer;
    display: flex;
    align-items: center; }
    .cart .cart-toggle .icon {
      font-size: clamp(12px, 1.04167rem, 1.25rem); }
      @media (max-width: 768px) {
        .cart .cart-toggle .icon {
          font-size: clamp(13px, 1.04167rem, 1.25rem); } }
    .cart .cart-toggle .cart-amount {
      width: 1.04167rem;
      height: 1.04167rem;
      border-radius: 999999px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      font-size: 0.625rem;
      color: #006CB9;
      margin-left: 0.20833rem; }
    .cart .cart-toggle .cart-text {
      margin-left: 0.41667rem;
      font-size: clamp(12px, 0.72917rem, 0.9375rem); }
      @media (max-width: 768px) {
        .cart .cart-toggle .cart-text {
          font-size: clamp(13px, 0.72917rem, 0.9375rem); } }
  .cart .cart-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: 0.625rem;
    width: 18.75rem;
    background: #fff;
    color: #1A1A1A;
    border-radius: 0.41667rem;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
    z-index: 20;
    display: none; }
    @media (max-width: 1024px) {
      .cart .cart-dropdown {
        right: auto;
        left: 0; } }
    .cart .cart-dropdown.show {
      display: block; }
  .cart .cart-container {
    padding: 1.25rem; }
  .cart .cart-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.625rem;
    border-bottom: 2px solid #EBEBEB;
    margin-bottom: 0.83333rem; }
  .cart .cart-close {
    font-size: 1.04167rem;
    cursor: pointer;
    position: absolute;
    top: 0.625rem;
    right: 1.25rem; }
  .cart .cart-body {
    overflow-y: auto;
    height: 13.75rem;
    padding-right: 0.83333rem; }
    .cart .cart-body::-webkit-scrollbar {
      width: 4px;
      background-color: #ccc; }
    .cart .cart-body ul li + li {
      margin-top: 0.83333rem;
      padding-top: 0.83333rem;
      border-top: 1px solid #EBEBEB; }
  .cart .cart-product .img {
    display: block;
    width: 3.125rem; }
  .cart .cart-product .caption {
    flex: 1;
    text-align: left;
    padding-left: 1.04167rem; }
  .cart .cart-product .name {
    display: block;
    margin-bottom: 10px; }
    .cart .cart-product .name:hover {
      color: #006CB9; }
  .cart .cart-product .quantity {
    color: #999; }
  .cart .cart-button {
    margin-top: 1.04167rem; }
    .cart .cart-button a {
      width: 100%; }
  @media screen and (max-width: 576px) {
    .cart {
      position: static; } }

.product-review {
  font-weight: 500; }
  .product-review .product-review-wrap {
    display: flex;
    flex-direction: column; }
    .product-review .product-review-wrap > * + * {
      margin-top: 1.04167rem; }
  .product-review .medium-rating {
    display: flex;
    gap: 0.41667rem;
    align-items: center; }
    .product-review .medium-rating > * {
      display: inline-block; }
    .product-review .medium-rating .point, .product-review .medium-rating .total-comment {
      color: #006CB9; }
  .product-review .charts {
    display: flex;
    flex-direction: column; }
    .product-review .charts > * + * {
      margin-top: 1.04167rem; }
    .product-review .charts .chart {
      display: flex;
      align-items: center; }
      .product-review .charts .chart > * + * {
        margin-left: 1.04167rem; }
      .product-review .charts .chart label {
        max-width: 44px;
        flex: 0 0 44px;
        width: 100%; }
      .product-review .charts .chart .progress {
        flex: auto;
        height: 0.41667rem;
        border-radius: 99px;
        background-color: #ADADAD;
        overflow: hidden; }
        .product-review .charts .chart .progress .progress-bar {
          background-color: #00365D;
          height: 100%; }
      .product-review .charts .chart .percent {
        max-width: 120px;
        flex: 0 0 120px;
        width: 100%;
        white-space: nowrap; }

.comment-form .module-title {
  margin-bottom: 1.04167rem; }

.comment-form .row {
  margin-left: -1.04167rem;
  margin-bottom: -1.04167rem; }
  .comment-form .row > * {
    padding-left: 1.04167rem;
    padding-bottom: 1.04167rem; }

.comment-list {
  margin-top: 1.66667rem;
  padding-top: 1.66667rem;
  border-top: 1px solid #EBEBEB; }

.journalItems {
  display: flex;
  flex-direction: column; }
  .journalItems > * + * {
    margin-top: 1.66667rem; }
  .journalItems .cmt-item {
    display: flex;
    flex-direction: column; }
    .journalItems .cmt-item > * + * {
      margin-top: 0.83333rem; }
    .journalItems .cmt-item .item, .journalItems .cmt-item .cmt-area {
      display: flex; }
      .journalItems .cmt-item .item > * + *, .journalItems .cmt-item .cmt-area > * + * {
        margin-left: 1.04167rem; }
      .journalItems .cmt-item .item .img, .journalItems .cmt-item .cmt-area .img {
        max-width: 60px;
        flex: 0 0 60px;
        width: 100%;
        height: 3.125rem;
        border-radius: 50%;
        overflow: hidden; }
        .journalItems .cmt-item .item .img img, .journalItems .cmt-item .cmt-area .img img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .journalItems .cmt-item .item .caption, .journalItems .cmt-item .item .jsummary, .journalItems .cmt-item .cmt-area .caption, .journalItems .cmt-item .cmt-area .jsummary {
        display: flex;
        flex-direction: column;
        flex: 1; }
        .journalItems .cmt-item .item .caption > * + *, .journalItems .cmt-item .item .jsummary > * + *, .journalItems .cmt-item .cmt-area .caption > * + *, .journalItems .cmt-item .cmt-area .jsummary > * + * {
          margin-top: 0.41667rem; }
        .journalItems .cmt-item .item .caption .name, .journalItems .cmt-item .item .jsummary .name, .journalItems .cmt-item .cmt-area .caption .name, .journalItems .cmt-item .cmt-area .jsummary .name {
          display: flex;
          gap: 0.41667rem;
          font-weight: 700; }
          .journalItems .cmt-item .item .caption .name > * + *, .journalItems .cmt-item .item .jsummary .name > * + *, .journalItems .cmt-item .cmt-area .caption .name > * + *, .journalItems .cmt-item .cmt-area .jsummary .name > * + * {
            margin-left: 0.41667rem; }
        .journalItems .cmt-item .item .caption .mod, .journalItems .cmt-item .item .jsummary .mod, .journalItems .cmt-item .cmt-area .caption .mod, .journalItems .cmt-item .cmt-area .jsummary .mod {
          display: inline-block;
          padding: 0.20833rem 0.41667rem;
          background-color: #006CB9;
          color: #fff;
          font-weight: 700;
          border-radius: 0.20833rem; }
        .journalItems .cmt-item .item .caption time, .journalItems .cmt-item .item .caption abbr, .journalItems .cmt-item .item .jsummary time, .journalItems .cmt-item .item .jsummary abbr, .journalItems .cmt-item .cmt-area .caption time, .journalItems .cmt-item .cmt-area .caption abbr, .journalItems .cmt-item .cmt-area .jsummary time, .journalItems .cmt-item .cmt-area .jsummary abbr {
          color: #858585; }
      .journalItems .cmt-item .item .cmreply, .journalItems .cmt-item .cmt-area .cmreply {
        text-decoration: underline;
        color: #006CB9; }
    .journalItems .cmt-item .jcmt {
      margin-left: 4.375rem; }
      .journalItems .cmt-item .jcmt > * + * {
        margin-top: 1.04167rem; }
      .journalItems .cmt-item .jcmt .row {
        margin-left: -1.04167rem;
        margin-bottom: -1.04167rem; }
        .journalItems .cmt-item .jcmt .row > * {
          padding-left: 1.04167rem;
          padding-bottom: 1.04167rem; }
    .journalItems .cmt-item .cmteditarea, .journalItems .cmt-item .cmtbtn {
      display: none; }
    .journalItems .cmt-item .cmt-area {
      padding: 1.04167rem;
      background-color: #D6D6D6; }
      .journalItems .cmt-item .cmt-area .jsummary {
        margin-left: 0; }
      .journalItems .cmt-item .cmt-area .iconcom {
        display: none; }
      .journalItems .cmt-item .cmt-area .cmlike {
        display: none; }

.fixed-stuff {
  position: fixed;
  z-index: 50;
  bottom: 6.25rem;
  right: 1.04167rem; }
  .fixed-stuff ul li + li {
    margin-top: 0.625rem; }
  .fixed-stuff ul li a {
    align-items: center;
    justify-content: center;
    text-decoration: none;
    display: inline-flex;
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 999999px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    transition: 0.3s all;
    background-color: #fff; }
    .fixed-stuff ul li a:hover, .fixed-stuff ul li a:focus, .fixed-stuff ul li a:active {
      text-decoration: none;
      outline: none; }
    @media (hover: hover) and (pointer: fine) {
      .fixed-stuff ul li a:hover {
        background-color: #006CB9;
        color: #fff; } }
  .fixed-stuff ul li.has-tooltip {
    position: relative; }
    .fixed-stuff ul li.has-tooltip a {
      position: relative;
      z-index: 2; }
    .fixed-stuff ul li.has-tooltip .tooltip {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #fff;
      border-radius: 999px;
      z-index: 1;
      padding: 0;
      overflow: hidden;
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s ease-in-out; }
    .fixed-stuff ul li.has-tooltip:hover .tooltip {
      opacity: 1;
      pointer-events: all;
      padding: 0 4.16667rem 0 1.5625rem; }

.global-footer {
  position: relative;
  color: #fff;
  background-image: url(../img/footer-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top; }
  @media (min-width: 1281px) {
    .global-footer:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 6.66667rem;
      background-image: url(../img/footer-divider.png);
      background-position: bottom center;
      background-repeat: no-repeat;
      background-size: contain;
      bottom: 100%;
      left: 0;
      z-index: 1;
      pointer-events: none; } }
  .global-footer .footer-top {
    padding: 2.08333rem 0; }
    @media (min-width: 1025px) {
      .global-footer .footer-top {
        padding: 4.58333rem 0 1.66667rem; } }

.footer-link ul {
  display: flex;
  flex-direction: column; }
  .footer-link ul > * + * {
    margin-top: 0.41667rem; }
  .footer-link ul li a {
    color: #E6F1F9; }

.subscribefrm {
  position: relative; }
  .subscribefrm input {
    padding: 0.625rem 3.125rem 0.625rem 1.25rem;
    font-weight: 500;
    border: 0;
    width: 100%; }
    .subscribefrm input::placeholder {
      color: #ADADAD;
      font-weight: 500; }
  .subscribefrm button {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.83333rem;
    color: #006CB9;
    font-size: clamp(12px, 1.04167rem, 1.25rem); }
    @media (max-width: 768px) {
      .subscribefrm button {
        font-size: clamp(13px, 1.04167rem, 1.25rem); } }
  .subscribefrm .fa-exclamation-triangle {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 0.20833rem; }

.footer-address {
  padding: 1.66667rem 0;
  border-top: 1px solid #006CB9; }
  .footer-address h3 {
    font-family: 'Nunito Sans';
    margin-bottom: 0.625rem;
    font-weight: 700;
    text-transform: uppercase; }
  .footer-address ul {
    display: flex;
    flex-direction: column; }
    .footer-address ul > * + * {
      margin-top: 0.625rem; }
    .footer-address ul li {
      color: #E6F1F9;
      font-weight: 400; }
      .footer-address ul li strong {
        color: #fff; }

.footer-copyright {
  padding: 1.45833rem 0;
  background-color: #00365D; }

.social-list ul {
  display: flex; }
  .social-list ul > * + * {
    margin-left: 0.625rem; }
  .social-list ul li a {
    width: 2.08333rem;
    height: 2.08333rem;
    border-radius: 999999px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E6F1F9;
    transition: all 0.3s ease-in-out; }
    .social-list ul li a span {
      background-image: linear-gradient(224.07deg, #006CB9 12.94%, #00365D 94.92%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
      font-size: clamp(12px, 0.83333rem, 1.04167rem); }
      @media (max-width: 768px) {
        .social-list ul li a span {
          font-size: clamp(13px, 0.83333rem, 1.04167rem); } }
    @media (hover: hover) and (pointer: fine) {
      .social-list ul li a:hover {
        background-color: #CCE2F1; } }

@media (min-width: 577px) {
  .copyright {
    text-align: right; } }

@media (min-width: 1281px) {
  body .global-footer {
    margin-top: 6.66667rem; } }

.global-header {
  background-color: #006CB9; }
  @media (max-width: 1024px) {
    .global-header {
      position: relative; } }

.header-top {
  color: #fff; }
  @media (max-width: 1024px) {
    .header-top {
      padding: 0.625rem 0; } }

.header-bottom-wrap {
  transition: all 0.3s ease-in-out;
  background-color: #fff;
  border-bottom: 1px solid #e7e7e7;
  transition: all 0.3s ease-in-out;
  padding: 0.625rem 0; }

.logo {
  width: 7.29167rem; }
  .logo a {
    display: block;
    position: relative;
    padding-top: 51.40562%; }
    .logo a > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .logo a img {
      object-fit: contain; }
    @media (max-width: 1280px) {
      .logo a {
        padding-top: 30%; } }
    @media (max-width: 1024px) {
      .logo a {
        position: relative;
        padding-top: 51.40562%; }
        .logo a > * {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover; } }

.top-menu {
  transition: all 0.3s ease-in-out; }
  @media (min-width: 1025px) {
    .top-menu > ul {
      display: flex; }
      .top-menu > ul > * + * {
        margin-left: 1.25rem; } }
  @media (max-width: 1024px) {
    .top-menu > ul {
      display: flex;
      flex-direction: column; }
      .top-menu > ul > * + * {
        margin-top: 0.83333rem;
        padding-top: 0.83333rem;
        border-top: 1px solid #EBEBEB; } }
  @media (min-width: 1025px) {
    .top-menu > ul > li.active > a {
      text-decoration: underline; } }
  @media (max-width: 1024px) {
    .top-menu > ul > li.active > a {
      color: #006CB9; } }

@media (hover: hover) and (pointer: fine) and (min-width: 1025px) {
  .top-menu > ul > li:hover > a {
    text-decoration: underline; } }

@media (hover: hover) and (pointer: fine) and (max-width: 1024px) {
  .top-menu > ul > li:hover > a {
    color: #006CB9; } }
  .top-menu > ul > li > a {
    text-transform: uppercase;
    font-size: clamp(12px, 0.72917rem, 0.9375rem); }
    @media (max-width: 768px) {
      .top-menu > ul > li > a {
        font-size: clamp(13px, 0.72917rem, 0.9375rem); } }
    @media (min-width: 1025px) {
      .top-menu > ul > li > a {
        height: 2.5rem;
        display: flex;
        align-items: center; } }

@media (min-width: 1025px) {
  .menu > ul {
    display: flex;
    justify-content: flex-end; }
    .menu > ul > * + * {
      margin-left: 3.125rem; } }

@media (max-width: 1024px) {
  .menu > ul {
    display: flex;
    flex-direction: column; }
    .menu > ul > * + * {
      margin-top: 0.83333rem;
      padding-top: 0.83333rem;
      border-top: 1px solid #EBEBEB; } }

.menu > ul > li.active > a {
  color: #006CB9; }
  @media (min-width: 1025px) {
    .menu > ul > li.active > a {
      border-bottom: 0.10417rem solid #006CB9; } }

@media (hover: hover) and (pointer: fine) {
  .menu > ul > li:hover > a {
    color: #006CB9; } }
  @media (hover: hover) and (pointer: fine) and (min-width: 1025px) {
    .menu > ul > li:hover > a {
      border-bottom: 0.10417rem solid #006CB9; } }

.menu > ul > li > a {
  text-transform: uppercase;
  font-size: 0.83333rem; }
  @media (min-width: 1025px) {
    .menu > ul > li > a {
      height: 3.33333rem;
      display: flex;
      align-items: center; } }

.has-sub {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media (min-width: 1025px) {
    .has-sub .toggle-sub-menu {
      display: none; } }
  @media (max-width: 1024px) {
    .has-sub .toggle-sub-menu {
      width: 1.66667rem;
      height: 1.66667rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      background-color: #006CB9;
      color: #fff;
      margin-left: auto; } }
  .has-sub .sub-menu {
    display: none;
    z-index: 2; }
    @media (max-width: 1024px) {
      .has-sub .sub-menu {
        max-width: 100%;
        flex: 0 0 100%;
        width: 100%; } }
    @media (min-width: 1025px) {
      .has-sub .sub-menu {
        position: absolute;
        top: 100%;
        left: 0;
        padding-top: 0.10417rem; } }
    .has-sub .sub-menu ul {
      padding: 0.83333rem;
      background-color: #fff;
      color: #262626;
      display: flex;
      flex-direction: column; }
      .has-sub .sub-menu ul > * + * {
        margin-top: 0.83333rem; }
      @media (max-width: 1024px) {
        .has-sub .sub-menu ul {
          padding: 0 0 0 0.83333rem;
          margin-top: 0.83333rem;
          border-left: 1px dashed #EBEBEB; } }
      .has-sub .sub-menu ul li a {
        white-space: nowrap; }
      .has-sub .sub-menu ul li.active > a {
        text-decoration: underline; }
      @media (hover: hover) and (pointer: fine) {
        .has-sub .sub-menu ul li:hover > a {
          text-decoration: underline; } }
  @media (hover: hover) and (pointer: fine) {
    .has-sub:hover .sub-menu {
      display: block; } }

.header-util-list {
  display: flex;
  justify-content: flex-end;
  align-items: center; }
  .header-util-list > * + * {
    margin-left: 1.66667rem; }
  @media (max-width: 576px) {
    .header-util-list {
      justify-content: space-between; } }

.language {
  display: flex; }
  .language a.active {
    text-decoration: underline;
    text-underline-offset: 0.20833rem; }
  .language > * + * {
    margin-left: 0.41667rem;
    padding-left: 0.41667rem;
    border-left: 1px solid #66A7D5; }

.hotline.btn {
  height: clamp(32px, 1.66667rem, 1.66667rem);
  font-size: clamp(12px, 0.72917rem, 0.9375rem);
  text-transform: none; }
  @media (max-width: 768px) {
    .hotline.btn {
      font-size: clamp(13px, 0.72917rem, 0.9375rem); } }
  .hotline.btn em {
    font-size: clamp(12px, 0.83333rem, 1.04167rem); }
    @media (max-width: 768px) {
      .hotline.btn em {
        font-size: clamp(13px, 0.83333rem, 1.04167rem); } }
  @media (max-width: 1024px) {
    .hotline.btn {
      border: 1px solid #006CB9;
      color: #006CB9; } }

.account {
  display: block !important; }
  .account .firstnav > span {
    font-weight: 700;
    white-space: nowrap; }
  .account > a {
    display: flex;
    font-size: clamp(12px, 0.72917rem, 0.9375rem);
    align-items: center; }
    .account > a > * + * {
      margin-left: 0.41667rem; }
    @media (max-width: 768px) {
      .account > a {
        font-size: clamp(13px, 0.72917rem, 0.9375rem); } }
    .account > a::before {
      font-family: 'Font Awesome 6 Pro';
      content: "\f007";
      font-weight: 300;
      font-size: clamp(12px, 0.83333rem, 1.04167rem);
      display: inline-block;
      margin-right: 0.41667rem; }
      @media (max-width: 768px) {
        .account > a::before {
          font-size: clamp(13px, 0.83333rem, 1.04167rem); } }
    .account > a span {
      overflow: hidden;
      text-overflow: ellipsis; }
  .account .dropdown-content {
    right: 0;
    padding-top: 0.83333rem;
    z-index: 2;
    color: #1A1A1A; }
    .account .dropdown-content ul {
      display: flex;
      flex-direction: column; }
      .account .dropdown-content ul > * + * {
        margin-top: 0.41667rem; }
      .account .dropdown-content ul li a {
        white-space: nowrap; }

.menu-toggle {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #006CB9;
  font-size: clamp(12px, 1.45833rem, 1.66667rem);
  color: #fff;
  margin-left: auto; }
  @media (max-width: 768px) {
    .menu-toggle {
      font-size: clamp(13px, 1.45833rem, 1.66667rem); } }

.menu-mobile {
  position: fixed;
  padding: 2.5rem 1.25rem;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: auto;
  z-index: 1000;
  transition: all 0.3s ease-in-out; }
  .menu-mobile.show {
    left: 0; }
  .menu-mobile .close-menu {
    position: absolute;
    width: 2.29167rem;
    height: 2.29167rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ADADAD;
    top: 0;
    right: 0;
    font-size: 1.25rem; }
  .menu-mobile .menu-mobile-header {
    margin-bottom: 1.04167rem;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .menu-mobile .menu-mobile-body {
    display: flex;
    flex-direction: column; }
    .menu-mobile .menu-mobile-body > * + * {
      margin-top: 0.83333rem;
      padding-top: 0.83333rem;
      border-top: 1px solid #EBEBEB; }

.backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 800;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  cursor: pointer; }

.search-popup {
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  display: none; }
  .search-popup .search-container {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    pointer-events: none; }
    @media (max-width: 768px) {
      .search-popup .search-container {
        align-items: flex-start;
        padding-top: 9.375rem; } }
  .search-popup .searchbox {
    pointer-events: all;
    position: relative; }
    .search-popup .searchbox input {
      width: 70vw;
      height: 2.5rem;
      padding: 0 3.75rem 0 1.04167rem;
      font-size: clamp(12px, 1.04167rem, 1.25rem); }
      @media (max-width: 768px) {
        .search-popup .searchbox input {
          font-size: clamp(13px, 1.04167rem, 1.25rem); } }
      @media (max-width: 768px) {
        .search-popup .searchbox input {
          width: 95vw; } }
    .search-popup .searchbox button {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 2.5rem;
      background: transparent;
      border: 0;
      font-size: clamp(12px, 1.04167rem, 1.25rem); }
      @media (max-width: 768px) {
        .search-popup .searchbox button {
          font-size: clamp(13px, 1.04167rem, 1.25rem); } }
  .search-popup .suggestsearch {
    position: absolute;
    top: 100%;
    padding: 1.04167rem;
    background: #fff;
    border-radius: 0.20833rem;
    border: 1px solid #eeeeee;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
    margin-top: 0.625rem;
    z-index: 10;
    display: none;
    width: 100%;
    max-height: 30vh;
    overflow: auto;
    line-height: 1.375;
    right: 0;
    scrollbar-width: thin; }
    .search-popup .suggestsearch::-webkit-scrollbar {
      width: 4px;
      height: 4px; }
    .search-popup .suggestsearch::-webkit-scrollbar-thumb {
      background: #006CB9; }
    .search-popup .suggestsearch::-webkit-scrollbar-track {
      background: #EBEBEB; }
    .search-popup .suggestsearch .keyword-list {
      line-height: 1.375; }
      .search-popup .suggestsearch .keyword-list h2 {
        margin-bottom: 0.83333rem; }
      .search-popup .suggestsearch .keyword-list + .keyword-list {
        margin-top: 1.25rem; }
      .search-popup .suggestsearch .keyword-list ul li + li {
        margin-top: 10px; }
      .search-popup .suggestsearch .keyword-list ul li a {
        font-size: 0.83333rem;
        color: #666;
        font-weight: 400; }
        .search-popup .suggestsearch .keyword-list ul li a .img {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 0 0 3.125rem; }
          .search-popup .suggestsearch .keyword-list ul li a .img img {
            max-height: 3.125rem; }
        .search-popup .suggestsearch .keyword-list ul li a .text {
          flex: auto; }

.popup-content-more {
  width: 48.33333rem;
  padding: 4.16667rem 3.125rem; }
  @media (max-width: 1024px) {
    .popup-content-more {
      padding: 1.66667rem; } }
  .popup-content-more .article-img figure {
    position: relative;
    padding-top: 54.11471%; }
    .popup-content-more .article-img figure > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }

.search-page {
  padding: 3.125rem 0; }
  @media (max-width: 1024px) {
    .search-page {
      padding: 2.08333rem 0; } }
  .search-page h1 {
    margin-bottom: 1.04167rem;
    line-height: 1.2;
    font-size: clamp(12px, 2.08333rem, 2.29167rem);
    font-weight: 700;
    color: #333333; }
    @media (max-width: 768px) {
      .search-page h1 {
        font-size: clamp(13px, 2.08333rem, 2.29167rem); } }
    .search-page h1.center {
      text-align: center; }
  .search-page .btn-reindex {
    display: none; }
  .search-page .searchcontrols .form-inline {
    position: relative; }
    .search-page .searchcontrols .form-inline:before {
      position: absolute;
      content: '\f002';
      font-family: "Font Awesome 6 Pro";
      top: 0;
      right: 0.52083rem;
      z-index: 1;
      font-size: 1.25rem;
      width: 3.125rem;
      height: 3.125rem;
      display: flex;
      align-items: center;
      justify-content: center; }
  .search-page .searchcontrols .frm-btn, .search-page .searchcontrols input[type="submit"] {
    position: absolute;
    top: 0;
    right: 0.52083rem;
    background-color: rgba(0, 0, 0, 0);
    z-index: 2;
    width: 3.125rem;
    height: 3.125rem;
    min-width: auto; }
  .search-page input[type="text"] {
    width: 100%;
    height: 100%;
    padding-left: 1.04167rem;
    padding-right: 2.8125rem;
    border: 1px solid #dfe1e5;
    background-color: #fff;
    color: #333;
    font-size: .875rem;
    font-weight: 400;
    height: 3.125rem !important;
    border-radius: 1.5625rem !important;
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
    transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    outline: none; }
    .search-page input[type="text"]:focus {
      box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
      border-color: rgba(223, 225, 229, 0); }
  .search-page input[type="submit"] {
    border-radius: 2px;
    user-select: none;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    box-shadow: none;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.08333rem;
    outline: none;
    width: 2.29167rem;
    height: 2.29167rem;
    border-radius: 50%;
    font-size: 0; }
  .search-page .text-danger {
    margin-top: 1.04167rem;
    font-size: 0.72917rem;
    color: #333;
    font-style: italic;
    font-weight: 600; }
  .search-page .searchresultsummary {
    margin-bottom: 20px; }
  .search-page .searchresults {
    margin-top: 1.5625rem; }
    .search-page .searchresults .modulepager:first-child {
      display: none; }
  .search-page .searchresultlist {
    margin-bottom: 1.5625rem; }
  .search-page .searchresult {
    margin-bottom: 1.5625rem;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    padding: 1rem;
    border-radius: 0.5rem; }
    .search-page .searchresult:last-child {
      margin-bottom: 0; }
    .search-page .searchresult h3 {
      font-size: 0.9375rem;
      line-height: 1.33;
      font-weight: 400;
      margin-bottom: 0.52083rem; }
      .search-page .searchresult h3 a {
        color: #1A0DAB;
        text-decoration: none;
        font-weight: 500; }
        .search-page .searchresult h3 a:hover {
          text-decoration: underline; }
    .search-page .searchresult .searchresultdesc {
      color: #545454;
      line-height: 1.54;
      word-wrap: break-word;
      font-size: small;
      font-weight: 400;
      margin-bottom: 0.52083rem; }
    .search-page .searchresult .searchterm {
      color: #6a6a6a;
      font-weight: bold;
      font-style: normal;
      line-height: 1.54;
      word-wrap: break-word;
      font-size: small; }
    .search-page .searchresult hr {
      display: none !important; }

.AspNet-TreeView {
  line-height: 1.5; }
  .AspNet-TreeView > ul {
    columns: 3 auto;
    margin: 10px 0 0;
    padding-bottom: 25px; }
    .AspNet-TreeView > ul > .AspNet-TreeView-Root {
      margin-bottom: 26px;
      break-inside: avoid-column; }
      .AspNet-TreeView > ul > .AspNet-TreeView-Root > a {
        display: inline-block;
        font-size: 20px;
        font-weight: 600;
        padding-bottom: 7px; }
      .AspNet-TreeView > ul > .AspNet-TreeView-Root .AspNet-TreeView-Collapse {
        display: none; }
      .AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li {
        padding-bottom: 7px;
        font-size: 18px;
        color: #06c; }
        .AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li > a {
          font-weight: 500;
          display: inline-block; }
        .AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Parent > a {
          margin-bottom: 7px; }
        .AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Parent > ul {
          list-style: disc;
          padding-left: 1.25em; }
          .AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Parent > ul > li {
            font-size: 16px;
            margin-bottom: 7px; }
  @media screen and (max-width: 768.98px) {
    .AspNet-TreeView > ul {
      column-count: 1; } }

.sitemap {
  padding: 2.08333rem 0; }
  .sitemap a:hover {
    text-decoration: underline; }

.sitemap-heading {
  height: 2.70833rem;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  margin-bottom: 1.66667rem; }
  .sitemap-heading h1 {
    font-weight: 600;
    font-size: 1.66667rem;
    line-height: 1.5; }

.breadcrumb-wrapper {
  background-color: #fff;
  color: #5C5C5C;
  border-bottom: 1px solid #EBEBEB; }
  @media (max-width: 1024px) {
    .breadcrumb-wrapper {
      display: none; } }
  .breadcrumb-wrapper .breadcrumb {
    padding: 0.625rem 0;
    display: flex;
    align-items: center; }
    .breadcrumb-wrapper .breadcrumb li a {
      display: flex;
      align-items: center; }
    .breadcrumb-wrapper .breadcrumb li + li {
      padding-left: 0.20833rem;
      display: flex;
      align-items: center; }
      .breadcrumb-wrapper .breadcrumb li + li::before {
        content: "|";
        margin-right: 0.20833rem; }

.cart-section .card-item, .checkout-section .card-item, .checkout-complete-section .card-item {
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 1.04167rem;
  overflow: hidden; }
  .cart-section .card-item + .card-item, .checkout-section .card-item + .card-item, .checkout-complete-section .card-item + .card-item {
    margin-top: 1.66667rem; }
  .cart-section .card-item .card-heading, .checkout-section .card-item .card-heading, .checkout-complete-section .card-item .card-heading {
    padding: 0.83333rem 1.66667rem;
    color: #006CB9;
    border-bottom: 1px solid #d9d9d9; }
    @media (max-width: 576px) {
      .cart-section .card-item .card-heading, .checkout-section .card-item .card-heading, .checkout-complete-section .card-item .card-heading {
        padding: 1.04167rem; } }
    .cart-section .card-item .card-heading .card-title, .checkout-section .card-item .card-heading .card-title, .checkout-complete-section .card-item .card-heading .card-title {
      font-family: "Nunito Sans";
      display: flex;
      align-items: center;
      gap: 0.625rem;
      font-weight: 700;
      text-transform: uppercase; }
  .cart-section .card-item .card-body, .checkout-section .card-item .card-body, .checkout-complete-section .card-item .card-body {
    padding: 1.04167rem 1.66667rem;
    line-height: 1.333333; }
    @media (max-width: 576px) {
      .cart-section .card-item .card-body, .checkout-section .card-item .card-body, .checkout-complete-section .card-item .card-body {
        padding: 1.04167rem; } }

.cart-product-header {
  display: flex;
  gap: 1.04167rem;
  font-weight: 500;
  margin-bottom: 0.83333rem;
  padding-bottom: 0.83333rem;
  border-bottom: 1px solid #EBEBEB; }
  @media (max-width: 768px) {
    .cart-product-header {
      display: none; } }
  .cart-product-header-img {
    max-width: 4.16667rem;
    flex: 0 0 4.16667rem;
    width: 100%; }
  .cart-product-header-content {
    display: flex;
    flex: 1;
    gap: 1.04167rem;
    align-items: center; }
  .cart-product-header-name {
    flex: 1; }
  .cart-product-header-price {
    max-width: 8.33333rem;
    flex: 0 0 8.33333rem;
    width: 100%; }
  .cart-product-header-subtotal {
    max-width: 8.33333rem;
    flex: 0 0 8.33333rem;
    width: 100%; }
  .cart-product-header-amount {
    max-width: 8.33333rem;
    flex: 0 0 8.33333rem;
    width: 100%; }
  .cart-product-header-btn {
    max-width: 3.125rem;
    flex: 0 0 3.125rem;
    width: 100%;
    text-align: right; }

.cart-product-item {
  gap: 1.04167rem;
  font-weight: 500; }
  .cart-product-item + .cart-product-item {
    margin-top: 1.04167rem;
    padding-top: 1.04167rem;
    border-top: 1px solid #CCE2F1; }
  .cart-product-item-img {
    max-width: 4.16667rem;
    flex: 0 0 4.16667rem;
    width: 100%; }
    @media (max-width: 768px) {
      .cart-product-item-img {
        align-items: flex-start !important; } }
  .cart-product-item-content {
    flex: 1;
    gap: 1.04167rem;
    align-items: center; }
    @media (max-width: 768px) {
      .cart-product-item-content {
        gap: 0.41667rem; } }
  .cart-product-item-name {
    flex: 1; }
  .cart-product-item-price {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    flex: 0 0 100%;
    width: 100%; }
    .cart-product-item-price > * + * {
      margin-top: 0.41667rem; }
    @media (min-width: 769px) {
      .cart-product-item-price {
        max-width: 8.33333rem;
        flex: 0 0 8.33333rem;
        width: 100%; } }
    .cart-product-item-price del {
      color: #5C5C5C; }
    .cart-product-item-price .discount {
      display: inline-block;
      padding: 0.10417rem 0.20833rem 0.10417rem 0.20833rem;
      background-color: #3389C7;
      color: #fff;
      font-weight: 700;
      border-radius: 0.20833rem; }
  .cart-product-item-subtotal {
    color: #3389C7;
    max-width: 100%;
    flex: 0 0 100%;
    width: 100%; }
    @media (min-width: 769px) {
      .cart-product-item-subtotal {
        max-width: 8.33333rem;
        flex: 0 0 8.33333rem;
        width: 100%; } }
  .cart-product-item-amount {
    max-width: 7.29167rem;
    flex: 0 0 7.29167rem;
    width: 100%; }
    .cart-product-item-amount .spin-input-wrap {
      width: 7.29167rem; }
  .cart-product-item-btn {
    color: #5C5C5C;
    max-width: 3.125rem;
    flex: 0 0 3.125rem;
    width: 100%; }
    @media (min-width: 769px) {
      .cart-product-item-btn {
        text-align: right; } }

.cart-order-info .total-price {
  padding-top: 0.83333rem;
  border-top: 1px solid #d9d9d9; }

.cart-order-info ul {
  display: flex;
  flex-direction: column; }
  .cart-order-info ul > * + * {
    margin-top: 0.83333rem; }
  .cart-order-info ul li span {
    white-space: nowrap; }

.btn-cart-group {
  justify-content: flex-end;
  flex-wrap: wrap; }
  @media (max-width: 576px) {
    .btn-cart-group {
      justify-content: center; } }

.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between; }

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1; }

@media (max-width: 768px) {
  font-size: 12px; }

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2; }

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2; }

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  margin-bottom: 6px; }

.stepper-item.active {
  font-weight: bold; }

.stepper-item.completed .step-counter {
  color: #fff; }

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3; }

.stepper-item:first-child::before {
  content: none; }

.stepper-item:last-child::after {
  content: none; }

.document-filter {
  display: flex;
  flex-wrap: wrap;
  margin-left: -3.33333rem;
  margin-bottom: -3.33333rem; }
  .document-filter > * {
    padding-left: 3.33333rem;
    padding-bottom: 3.33333rem; }
  @media (max-width: 768px) {
    .document-filter {
      display: flex;
      flex-wrap: wrap;
      margin-left: -1.04167rem;
      margin-bottom: -1.04167rem; }
      .document-filter > * {
        padding-left: 1.04167rem;
        padding-bottom: 1.04167rem; } }
  .document-filter-item .form-group select {
    background-image: url("../img/sl-bg.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 0.625rem) 50%;
    appearance: none;
    background-size: 0.98958rem 0.52083rem;
    border-radius: 0.41667rem; }
  @media (min-width: 577px) {
    .document-filter-item {
      display: flex;
      align-items: center; }
      .document-filter-item > * + * {
        margin-left: 1.04167rem; } }
  @media (max-width: 576px) {
    .document-filter-item {
      display: flex;
      flex-direction: column; }
      .document-filter-item > * + * {
        margin-top: 0.41667rem; } }
  .document-filter-item-title {
    font-weight: 700;
    color: #262626; }
  .document-filter-item-body {
    flex: 1; }
  .document-filter-item-search input {
    background-image: url("../img/search-bg.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 0.625rem) 50%;
    appearance: none;
    background-size: 0.83333rem 0.83333rem; }
  .document-filter-item-1 {
    max-width: 39.02439%;
    flex: 0 0 39.02439%;
    width: 100%; }
    @media (max-width: 768px) {
      .document-filter-item-1 {
        max-width: 50%;
        flex: 0 0 50%;
        width: 100%; } }
    @media (max-width: 576px) {
      .document-filter-item-1 {
        max-width: 100%;
        flex: 0 0 100%;
        width: 100%; } }
  .document-filter-item-2 {
    max-width: 20.50305%;
    flex: 0 0 20.50305%;
    width: 100%; }
    @media (max-width: 768px) {
      .document-filter-item-2 {
        max-width: 50%;
        flex: 0 0 50%;
        width: 100%; } }
    @media (max-width: 576px) {
      .document-filter-item-2 {
        max-width: 100%;
        flex: 0 0 100%;
        width: 100%; } }
  .document-filter-item-3 {
    flex: 1; }

.document-item-wrap:nth-child(odd) .document-item {
  background-color: #E6F1F9; }

.document-item-wrap:nth-child(even) .document-item {
  background-color: #006CB9;
  color: #fff; }
  .document-item-wrap:nth-child(even) .document-item-meta {
    color: #fff; }

.document-item {
  padding: 1.66667rem 32px 1.04167rem;
  border-radius: 1.04167rem;
  display: flex;
  flex-direction: column; }
  .document-item > * + * {
    margin-top: 0.83333rem; }
  .document-item-title {
    font-weight: 700;
    margin-bottom: 0.83333rem; }
  .document-item-meta {
    margin-top: auto;
    display: flex;
    gap: 0.83333rem;
    justify-content: space-between;
    align-items: center;
    color: #858585; }
  .document-item-download {
    display: flex;
    align-items: center;
    gap: 0.41667rem; }
  .document-item:hover .document-item-title {
    text-decoration: underline; }

.news-side-list {
  display: flex;
  flex-direction: column; }
  .news-side-list > * + * {
    margin-top: 0.83333rem;
    padding-top: 0.83333rem;
    border-top: 1px solid #CCE2F1; }
  @media (min-width: 1025px) {
    .news-side-list {
      display: flex;
      flex-direction: column; }
      .news-side-list > * + * {
        margin-top: 1.66667rem;
        padding-top: 1.66667rem;
        border-top: 1px solid #CCE2F1; } }

.news-cate {
  padding-bottom: 1.66667rem;
  border-bottom: 1px solid #00518B; }
  .news-cate-heading {
    display: flex;
    align-items: center;
    justify-content: space-between; }

.news-item {
  display: flex; }
  .news-item:hover .news-item-title {
    color: #006CB9; }
  .news-item-date {
    color: #5C5C5C; }
  .news-item-title {
    font-weight: 700;
    transition: all 0.3s ease-in-out; }
  .news-item-img figure a {
    border-radius: 0.625rem;
    background-clip: padding-box;
    display: block;
    overflow: hidden; }
  .news-item-caption {
    display: flex;
    flex-direction: column; }
    .news-item-caption > * + * {
      margin-top: 0.83333rem; }
    @media (max-width: 1024px) {
      .news-item-caption {
        display: flex;
        flex-direction: column; }
        .news-item-caption > * + * {
          margin-top: 0.41667rem; } }
  .news-item-brief {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical; }
  .news-item-vertical {
    flex-direction: column; }
    .news-item-vertical .news-item-caption {
      flex: 1 1 0%; }
  .news-item-horizontal .news-item-img {
    max-width: 31.25%;
    flex: 0 0 31.25%;
    width: 100%; }
    .news-item-horizontal .news-item-img figure a {
      position: relative;
      padding-top: 75%; }
      .news-item-horizontal .news-item-img figure a > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .news-item-horizontal .news-item-caption {
    padding-left: 1.04167rem;
    flex: auto; }
  .news-item-horizontal .news-item-title {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; }
  .news-item-horizontal.news-item-no-img .news-item-caption {
    padding-left: 0; }
  .news-item-big .news-item-caption {
    margin-top: 1.45833rem; }
    @media (max-width: 1024px) {
      .news-item-big .news-item-caption {
        margin-top: 0.83333rem; } }
  .news-item-big .news-item-img figure a {
    position: relative;
    padding-top: 68.75%; }
    .news-item-big .news-item-img figure a > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .news-item-small .news-item-caption {
    margin-top: 1.04167rem; }
    @media (max-width: 1024px) {
      .news-item-small .news-item-caption {
        margin-top: 0.83333rem; } }
  .news-item-small .news-item-img figure a {
    position: relative;
    padding-top: 75%; }
    .news-item-small .news-item-img figure a > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .news-item-small .news-item-title {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 0.83333rem; }
    @media (max-width: 1024px) {
      .news-item-small .news-item-title {
        margin-bottom: 0.41667rem; } }
  .news-item-small .news-item-brief {
    margin-top: auto; }

@media (min-width: 769px) {
  .product-list .row {
    margin-left: -1.66667rem;
    margin-bottom: -3.125rem; }
    .product-list .row > * {
      padding-left: 1.66667rem;
      padding-bottom: 3.125rem; } }

.product-slider .swiper-slide .product-item {
  opacity: 0.2; }

.product-slider .swiper-slide-visible .product-item {
  opacity: 1; }

.product-item {
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out; }
  .product-item-img {
    padding-top: 100%;
    position: relative;
    transition: all 0.3s ease-in-out; }
    .product-item-img figure {
      background-color: #99C4E3;
      border-radius: 0.41667rem;
      position: absolute;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden; }
      .product-item-img figure img {
        transition: all 0.3s ease-in-out; }
  .product-item-caption {
    padding-top: 1.04167rem;
    flex: 1 1 0%;
    display: flex;
    flex-direction: column; }
  .product-item-price {
    font-weight: 700; }
  .product-item-title {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #006CB9;
    font-weight: 700;
    margin-top: 0.41667rem; }
  .product-item-brief {
    margin-top: 0.41667rem;
    margin-bottom: 1.66667rem; }
    @media (max-width: 1024px) {
      .product-item-brief {
        margin-bottom: 0.83333rem; } }
  .product-item-purchase {
    margin-top: auto; }
    .product-item-purchase .btn-icon i {
      margin: 0 0.20833rem; }
      @media (max-width: 576px) {
        .product-item-purchase .btn-icon i {
          display: none; } }
  @media (hover: hover) and (pointer: fine) {
    .product-item:hover .product-item-img {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15); }
      .product-item:hover .product-item-img img {
        transform: scale(1.05); }
    .product-item:hover .product-item-title {
      text-decoration: underline; } }

.register-box {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 1.04167rem;
  padding: 2.08333rem 4.16667rem; }
  @media (max-width: 1024px) {
    .register-box {
      padding: 1.66667rem; } }
  .register-box .form-radio ul {
    display: flex;
    gap: 1.04167rem; }
    .register-box .form-radio ul li {
      display: flex;
      align-items: center;
      gap: 0.41667rem; }
      .register-box .form-radio ul li input {
        margin: 0; }
      .register-box .form-radio ul li label {
        color: #858585; }
      .register-box .form-radio ul li input:checked {
        color: #006CB9; }
        .register-box .form-radio ul li input:checked ~ label {
          color: #006CB9; }
  .register-box .form-box + .form-box {
    margin-top: 1.66667rem; }
  .register-box .form-row.row {
    margin-left: -1.04167rem;
    margin-bottom: -1.04167rem; }
    .register-box .form-row.row > * {
      padding-left: 1.04167rem;
      padding-bottom: 1.04167rem; }
  .register-box .form-submit input {
    border: 0; }

.register-nav ul {
  display: flex;
  justify-content: center; }
  .register-nav ul > * + * {
    margin-left: 1.04167rem; }
  .register-nav ul li a {
    color: #858585;
    display: inline-flex;
    align-items: center;
    gap: 0.41667rem; }
    .register-nav ul li a:before {
      content: "";
      font-family: 'Font Awesome 6 Pro';
      font-size: 0.72917rem; }
  .register-nav ul li.active a {
    color: #006CB9; }
    .register-nav ul li.active a:before {
      content: '\f192'; }

.sub-banner .banner-item {
  position: relative; }
  .sub-banner .banner-item .banner-icon {
    position: absolute;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: clamp(12px, 4.16667rem, 4.375rem);
    color: #fff; }
    @media (max-width: 768px) {
      .sub-banner .banner-item .banner-icon {
        font-size: clamp(13px, 4.16667rem, 4.375rem); } }
  .sub-banner .banner-item-caption {
    position: absolute;
    bottom: 3.64583rem;
    color: #fff;
    width: 100%;
    text-align: center; }
    @media (max-width: 768px) {
      .sub-banner .banner-item-caption {
        bottom: 1.66667rem; } }

.sub-banner .swiper-pagination {
  bottom: 2.08333rem;
  z-index: 2;
  position: absolute;
  left: 50%;
  transform: translateX(-50%); }
  @media (max-width: 1024px) {
    .sub-banner .swiper-pagination {
      bottom: 0.83333rem; } }
  .sub-banner .swiper-pagination-bullet {
    height: 0.83333rem;
    width: 0.83333rem;
    border-radius: 50%;
    background-color: #fff;
    opacity: 1;
    margin: 0 0.52083rem !important; }
    .sub-banner .swiper-pagination-bullet-active {
      background-color: #006CB9; }

.news-detail-page .sub-banner, .recruitment-detail-page .sub-banner {
  display: none; }

.tab-nav {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory; }
  .tab-nav > * + * {
    margin-left: 0.41667rem; }
  @media (min-width: 769px) {
    .tab-nav {
      justify-content: center; } }
  .tab-nav li a {
    white-space: nowrap;
    display: inline-flex;
    padding: 0.41667rem 0.625rem;
    color: #5C5C5C;
    transition: all 0.3s ease-in-out;
    border-radius: 0.20833rem;
    background-clip: padding-box; }
    .tab-nav li.active a {
      background-color: #006CB9;
      color: #fff; }
    .tab-nav li:hover a {
      background-color: #006CB9;
      color: #fff; }

.sub-nav ul {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.41667rem;
  margin-bottom: -0.41667rem;
  justify-content: flex-start; }
  .sub-nav ul > * {
    padding-left: 0.41667rem;
    padding-bottom: 0.41667rem; }
  @media (max-width: 1024px) {
    .sub-nav ul {
      flex-wrap: nowrap;
      overflow: auto; } }
  @media (min-width: 1025px) {
    .sub-nav ul {
      justify-content: center; } }
  .sub-nav ul li a {
    white-space: nowrap;
    display: inline-flex;
    padding: 0.41667rem 0.625rem;
    color: #5C5C5C;
    transition: all 0.3s ease-in-out;
    border-radius: 0.20833rem;
    background-clip: padding-box; }
    .sub-nav ul li.active a {
      background-color: #006CB9;
      color: #fff; }
    .sub-nav ul li:hover a {
      background-color: #006CB9;
      color: #fff; }

.about-nav ul {
  display: flex;
  gap: 0.10417rem;
  overflow: auto; }
  .about-nav ul li {
    flex: auto; }
    .about-nav ul li a {
      display: flex;
      align-items: center;
      height: 2.70833rem;
      padding: 0 1.66667rem;
      justify-content: center;
      text-align: center;
      border-radius: 0 0 0.41667rem 0.41667rem;
      background-color: #EBEBEB;
      white-space: nowrap; }
      .about-nav ul li.active a {
        background-color: #006CB9;
        color: #fff; }
      @media (max-width: 768px) {
        .about-nav ul li a {
          padding: 0 1.04167rem; } }

@media (max-width: 768px) {
  .about-nav .container {
    max-width: none;
    padding: 0; } }

.about-1 {
  position: relative; }
  .about-1 .bg-icon {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 13.48958rem; }
    @media (max-width: 1024px) {
      .about-1 .bg-icon {
        display: none; } }
    .about-1 .bg-icon figure {
      position: relative;
      padding-top: 100%; }
      .about-1 .bg-icon figure > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }
    @media (max-width: 1440px) {
      .about-1 .bg-icon {
        left: -5.20833rem; } }
  @media (max-width: 1024px) {
    .about-1 .content-container {
      padding: 2.08333rem 0; } }
  @media (min-width: 1025px) {
    .about-1 .content-container {
      position: absolute;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 100%; } }
  .about-1 .content-container .article-content {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical; }
  @media (max-width: 1024px) {
    .about-1 .img-container .img figure {
      position: relative;
      padding-top: 54.16667%; }
      .about-1 .img-container .img figure > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; } }
  @media (min-width: 1025px) {
    .about-1 .img-container {
      width: 62.5%;
      margin-left: auto; }
      .about-1 .img-container .img figure {
        height: 33.85417rem; }
        .about-1 .img-container .img figure img {
          width: 100%;
          height: 100%;
          object-fit: cover; } }

.history-slider .swiper-btn {
  top: 3.38542rem !important; }

.history-item-year {
  width: 6.77083rem;
  height: 6.77083rem;
  border-radius: 999999px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(224.07deg, #006CB9 12.94%, #00365D 94.92%);
  color: #fff;
  margin: 0 auto 1.66667rem;
  border: 0.41667rem solid #fff;
  box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);
  font-weight: 700;
  font-family: "Nunito Sans"; }

.history-item-content {
  display: flex;
  flex-direction: column; }
  .history-item-content > * + * {
    margin-top: 1.04167rem; }

.history-item-img figure {
  position: relative;
  padding-top: 56.25%;
  border-radius: 0.41667rem;
  overflow: hidden; }
  .history-item-img figure > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.history-item-brief {
  color: #262626;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0.625rem; }

.history-item-link {
  margin-top: auto; }

.about-4 {
  position: relative;
  z-index: 1; }
  @media (min-width: 1025px) {
    .about-4::before {
      content: "";
      display: block;
      position: absolute;
      height: 9.42708rem;
      width: 50.83333rem;
      background-image: url(../img/about-bg-2.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      bottom: 0;
      left: 0;
      z-index: -1; } }

.about-5 .item {
  display: flex;
  flex-direction: column; }
  .about-5 .item > * + * {
    margin-top: 0.83333rem; }
  .about-5 .item .icon {
    width: 4.16667rem;
    height: 4.16667rem;
    border-radius: 999999px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00518B;
    border: 0.15625rem solid #FFFFFF;
    box-shadow: -4px 4px 20px rgba(25, 64, 92, 0.25); }
    .about-5 .item .icon figure {
      position: relative;
      padding-top: 100%;
      width: 2.60417rem; }
      .about-5 .item .icon figure > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .about-5 .item .icon figure img {
        object-fit: contain; }

.about-5 .about-5-content {
  padding: 2.60417rem;
  background: linear-gradient(224.07deg, rgba(0, 108, 185, 0.5) 12.94%, rgba(0, 54, 93, 0.5) 94.92%);
  border-radius: 1.04167rem; }

.checkout-complete-section .button-group {
  margin-top: 1.04167rem; }

@media (min-width: 1025px) {
  .checkout-complete-section .card-item .card-body {
    padding: 2.91667rem 2.1875rem; } }

.checkout-info-form .form-group {
  display: flex;
  align-items: center; }
  .checkout-info-form .form-group + .form-group {
    margin-top: 1.04167rem; }
  .checkout-info-form .form-group label {
    white-space: nowrap;
    flex: 1 1 0%;
    text-transform: uppercase;
    font-weight: 700; }
    .checkout-info-form .form-group label.error {
      color: #dd0705;
      margin-top: 0.20833rem;
      text-transform: none;
      font-weight: 400;
      margin-top: 0.20833rem;
      display: inline-block; }
  .checkout-info-form .form-group .form-control {
    flex: 3 1 0%; }
    .checkout-info-form .form-group .form-control input[type="text"], .checkout-info-form .form-group .form-control select {
      height: 2.29167rem;
      border-radius: 1.04167rem; }
      .checkout-info-form .form-group .form-control input[type="text"]::placeholder, .checkout-info-form .form-group .form-control select::placeholder {
        color: #858585; }
    .checkout-info-form .form-group .form-control textarea {
      height: 4.58333rem;
      border-radius: 1.04167rem; }
      .checkout-info-form .form-group .form-control textarea::placeholder {
        color: #858585; }

.checkout-info-form .add-new-address a {
  color: #068fdd;
  text-decoration: underline; }

@media screen and (max-width: 576px) {
  .checkout-info-form .form-group {
    flex-wrap: wrap; }
    .checkout-info-form .form-group label {
      flex: 0 0 100%;
      margin-bottom: 0.625rem; }
    .checkout-info-form .form-group .form-control {
      flex: 0 0 100%; } }

@media (min-width: 769px) {
  .checkout-shipping-wrap {
    margin-top: 1.66667rem; } }

.checkout-check-list {
  padding-left: 0;
  list-style: none; }

.checkout-check-item {
  cursor: pointer; }
  .checkout-check-item + .checkout-check-item {
    margin-top: 1.04167rem; }
  .checkout-check-item label {
    display: flex;
    align-items: center; }
  .checkout-check-item input[type="radio"], .checkout-check-item input[type="checkbox"] {
    position: absolute;
    height: 1px;
    width: 1px;
    visibility: hidden;
    clip: rect(0px, 0px, 0px, 0px);
    opacity: 0; }
    .checkout-check-item input[type="radio"]:checked + .fake-radio::before, .checkout-check-item input[type="checkbox"]:checked + .fake-radio::before {
      content: "";
      font-family: 'Font Awesome 6 Pro';
      color: #006CB9; }
  .checkout-check-item .fake-radio::before {
    content: "";
    font-family: 'Font Awesome 6 Pro';
    margin-right: 1.66667rem;
    font-size: 0.83333rem;
    font-weight: 400; }
  .checkout-check-item .content {
    width: calc( 100% - 2.08333333rem);
    border: 1px solid #e0e0e0;
    border-radius: 1.04167rem;
    display: flex; }
    .checkout-check-item .content .img {
      width: 4.16667rem;
      min-height: 4.16667rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #e0e0e0; }
    .checkout-check-item .content .text {
      flex: 1;
      display: flex;
      justify-content: center;
      padding: 0.83333rem 1.04167rem;
      line-height: 1.333333;
      flex: 1;
      display: flex;
      flex-direction: column; }
      .checkout-check-item .content .text > * + * {
        margin-top: 0.41667rem; }
      .checkout-check-item .content .text table {
        width: 100%; }
        .checkout-check-item .content .text table td, .checkout-check-item .content .text table th {
          border: 1px solid #dee2e6;
          padding: .75rem;
          vertical-align: top; }

.checkout-issue-invoice-wrap .checkout-check-item .content {
  border: 0; }

.checkout-issue-invoice-wrap .card-body {
  display: none; }
  .checkout-issue-invoice-wrap .card-body.show {
    display: block; }

.Module-194 {
  height: 100%; }
  .Module-194 .ModuleContent {
    position: sticky;
    top: 8.33333rem;
    z-index: 3; }

.checkout-cart-summary .cart-summary-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.04167rem;
  padding-bottom: 1.04167rem;
  border-bottom: 1px solid #CCE2F1; }
  .checkout-cart-summary .cart-summary-item .product-detail-promo {
    width: 100%; }
  .checkout-cart-summary .cart-summary-item .product-link {
    display: flex;
    flex: auto;
    align-items: center;
    padding-right: 1.04167rem; }
    .checkout-cart-summary .cart-summary-item .product-link .img {
      max-width: 84px;
      flex: 0 0 84px;
      width: 100%;
      margin-right: 0.83333rem; }
    .checkout-cart-summary .cart-summary-item .product-link .text {
      flex: 1; }
  .checkout-cart-summary .cart-summary-item .product-price {
    text-align: right;
    flex: 1;
    white-space: nowrap; }
    .checkout-cart-summary .cart-summary-item .product-price * * + * {
      margin-top: 0.20833rem; }
    .checkout-cart-summary .cart-summary-item .product-price .total-price {
      font-size: 0.9375rem; }

.checkout-cart-summary .cart-summary-price hr {
  overflow: visible;
  text-align: inherit;
  margin: 1.04167rem 0;
  border: 0;
  border-top: 1px solid #e5e5e5; }

.checkout-cart-summary .cart-summary-price ul + li {
  margin-top: 1rem; }

.checkout-point-wrap .point-slider {
  height: 0.20833rem;
  border: 0 !important;
  background: #bcbcbc;
  margin: 0 0.20833rem 0.625rem; }
  .checkout-point-wrap .point-slider .ui-slider-handle {
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    border: 1px solid #666666;
    background-color: #fff; }
  .checkout-point-wrap .point-slider .ui-slider-range {
    background-color: #006CB9;
    border: 0; }

.checkout-point-wrap .point-slider-input label {
  flex: 1; }

.checkout-point-wrap .point-slider-input input {
  width: 156px;
  background: #fafafa;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-left: 0.625rem;
  padding: 0 15px;
  height: 40px; }

.checkout-shipping-wrap {
  margin-top: 1.66667rem; }

.promotion-popup {
  padding: 2.08333rem 1.5625rem;
  border-radius: 1.04167rem;
  width: 31.25rem; }
  @media (max-width: 768px) {
    .promotion-popup {
      height: 60vh; } }
  .promotion-popup .promotion-list {
    padding: 1.04167rem; }
  .promotion-popup .promotion-item {
    padding-bottom: 0.625rem;
    margin-bottom: 0.625rem;
    border-bottom: 1px solid #CCE2F1;
    position: relative;
    padding-right: 5.9375rem; }
    .promotion-popup .promotion-item-btn {
      display: flex;
      gap: 0.41667rem;
      position: absolute;
      right: 0;
      top: 0; }
      .promotion-popup .promotion-item-btn a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5625rem;
        height: 1.5625rem;
        border-radius: 999999px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        color: #006CB9; }
    .promotion-popup .promotion-item-choose.no-radio label {
      padding-left: 1.04167rem; }
    .promotion-popup .promotion-item-choose label {
      display: flex;
      gap: 0.41667rem; }
      .promotion-popup .promotion-item-choose label input {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        border: 0;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(100%);
        white-space: nowrap; }
        .promotion-popup .promotion-item-choose label input:checked ~ .radio::before {
          content: "";
          font-family: 'Font Awesome 6 Pro';
          color: #006CB9; }
      .promotion-popup .promotion-item-choose label .radio {
        position: relative;
        top: 0.10417rem; }
        .promotion-popup .promotion-item-choose label .radio::before {
          content: "";
          font-family: 'Font Awesome 6 Pro';
          color: #ADADAD; }
      .promotion-popup .promotion-item-choose label .desc {
        color: #858585;
        display: flex;
        flex-direction: column; }
        .promotion-popup .promotion-item-choose label .desc > * + * {
          margin-top: 0.20833rem; }

.cart-login {
  width: 26.04167rem;
  max-width: 100%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
  margin: 0 auto; }
  .cart-login .login-heading {
    padding: 1.04167rem 1.25rem 0.625rem;
    border-bottom: 1px solid #ebebeb;
    font-size: 0.9375rem;
    text-transform: uppercase;
    font-weight: 700; }
  .cart-login .login-form {
    padding: 1.25rem; }
    .cart-login .login-form .select-group {
      line-height: 1.375; }
      .cart-login .login-form .select-group ul {
        display: flex;
        flex-direction: column; }
        .cart-login .login-form .select-group ul > * + * {
          margin-top: 0.41667rem; }
        .cart-login .login-form .select-group ul li, .cart-login .login-form .select-group ul li > span {
          display: flex;
          align-items: center; }
          .cart-login .login-form .select-group ul li label, .cart-login .login-form .select-group ul li > span label {
            margin-left: 0.41667rem; }
          .cart-login .login-form .select-group ul li input, .cart-login .login-form .select-group ul li > span input {
            position: relative;
            margin: 0; }
    .cart-login .login-form .module-title {
      text-transform: uppercase;
      margin-top: 1.25rem;
      margin-bottom: 0.83333rem;
      font-weight: 700; }
    .cart-login .login-form .form-group + .form-group {
      margin-top: 1.04167rem; }
    .cart-login .login-form .form-group label {
      display: block;
      margin-bottom: 0.41667rem; }
    .cart-login .login-form .form-group input[type="text"], .cart-login .login-form .form-group input[type="password"] {
      width: 100%;
      height: 2.1875rem;
      border-radius: 5px;
      border: 1px solid #e1e1e1;
      background-color: #fafafa;
      padding: 0 20px; }
      .cart-login .login-form .form-group input[type="text"]::placeholder, .cart-login .login-form .form-group input[type="password"]::placeholder {
        color: #999999; }
    .cart-login .login-form .form-group.form-link {
      display: flex;
      justify-content: space-between; }
    .cart-login .login-form .form-group .register-link, .cart-login .login-form .form-group #ctl00_mainContent_ctl00_LoginCtrl_lnkPasswordRecovery {
      font-size: 14px; }
    .cart-login .login-form .form-group #ctl00_mainContent_ctl00_LoginCtrl_lnkRegisterExtraLink {
      color: #00c1ef;
      text-decoration: underline; }
    .cart-login .login-form input[type="submit"] {
      height: 2.1875rem;
      width: 100%;
      background: #fff;
      border-radius: 5px;
      border: 2px solid #006CB9;
      color: #006CB9;
      font-weight: 700;
      margin-top: 1.04167rem;
      font-size: 0.83333rem;
      cursor: pointer; }

.social-login {
  display: none; }
  .social-login a {
    width: 100%; }
    .social-login a span {
      color: #fff; }
    .social-login a + a {
      margin-top: 1.04167rem; }
    .social-login a.facebook-login {
      background: #4267B2; }
    .social-login a.google-login {
      background: #df4a32; }

.input-group-append {
  display: none; }

.cooperation-detail-heading .content-container {
  pointer-events: none;
  padding: 1.66667rem 0;
  background-color: #E6F1F9; }
  @media (min-width: 1025px) {
    .cooperation-detail-heading .content-container {
      position: absolute;
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      padding: 4.58333rem 0; } }

.cooperation-detail-heading .content {
  pointer-events: all;
  margin-left: 1.66667rem;
  position: relative; }
  @media (min-width: 1025px) {
    .cooperation-detail-heading .content {
      margin-left: 3.125rem; } }
  .cooperation-detail-heading .content .quote {
    font-family: "Nunito Sans";
    position: absolute;
    right: 100%;
    background-image: linear-gradient(224.07deg, #006CB9 12.94%, #00365D 94.92%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    font-size: clamp(12px, 2.5rem, 2.70833rem);
    margin-right: 0.83333rem;
    top: -0.83333rem; }
    @media (max-width: 768px) {
      .cooperation-detail-heading .content .quote {
        font-size: clamp(13px, 2.5rem, 2.70833rem); } }

.cooperation-detail-heading .img {
  position: relative;
  display: block;
  z-index: 2; }
  @media (min-width: 1025px) {
    .cooperation-detail-heading .img {
      width: 55%; } }
  .cooperation-detail-heading .img .icon {
    position: absolute;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 4.16667rem;
    height: 4.16667rem;
    border-radius: 999999px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #006CB9;
    color: #fff;
    font-size: clamp(12px, 1.66667rem, 1.875rem);
    border: 1px solid #99C4E3; }
    @media (max-width: 768px) {
      .cooperation-detail-heading .img .icon {
        font-size: clamp(13px, 1.66667rem, 1.875rem); } }
  .cooperation-detail-heading .img figure figcaption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1.04167rem;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    color: #fff; }

.cooperation-item {
  padding: 2.08333rem 2.70833rem;
  display: flex;
  flex-direction: column;
  text-align: center; }
  .cooperation-item-title {
    color: #00518B;
    font-weight: 700;
    margin-bottom: 1.04167rem; }
  .cooperation-item-brief {
    margin-bottom: 0.83333rem;
    margin-top: auto; }

@media (min-width: 1025px) {
  .images-slider .swiper-container {
    overflow: visible; } }

.images-slider .swiper-slide .caption {
  opacity: 0; }

.images-slider .swiper-slide img {
  opacity: 0.5; }

.images-slider .swiper-slide-active img, .images-slider .swiper-slide-active .caption {
  opacity: 1; }

.images-slider .img {
  position: relative;
  background: #000; }
  .images-slider .img::before {
    content: "";
    display: block;
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    height: 10.41667rem;
    bottom: 0;
    width: 100%;
    z-index: 1; }
  .images-slider .img figure {
    position: relative;
    padding-top: 62.03704%; }
    .images-slider .img figure > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .images-slider .img figure img {
      transition: all 0.3s ease-in-out; }
  .images-slider .img .caption {
    transition: all 0.3s ease-in-out;
    position: absolute;
    padding: 0 0.83333rem;
    bottom: 2.08333rem;
    z-index: 2;
    text-align: center;
    color: #fff;
    font-weight: 700;
    width: 100%; }
    @media (max-width: 1024px) {
      .images-slider .img .caption {
        bottom: 1.04167rem; } }

.dealer-detail-address {
  padding: 2.91667rem 1.875rem;
  border-radius: 1.04167rem; }
  @media (max-width: 1024px) {
    .dealer-detail-address {
      padding: 1.45833rem; } }
  .dealer-detail-address ul {
    display: flex;
    flex-direction: column; }
    .dealer-detail-address ul > * + * {
      margin-top: 0.83333rem; }
    .dealer-detail-address ul li {
      display: flex;
      gap: 1.66667rem;
      align-items: center; }
      @media (max-width: 1024px) {
        .dealer-detail-address ul li {
          gap: 0.41667rem; } }
      .dealer-detail-address ul li span {
        color: #006CB9;
        font-size: clamp(12px, 1.25rem, 1.45833rem);
        position: relative;
        max-width: 24px;
        flex: 0 0 24px;
        width: 100%; }
        @media (max-width: 768px) {
          .dealer-detail-address ul li span {
            font-size: clamp(13px, 1.25rem, 1.45833rem); } }

.banner-home .swiper-pagination {
  bottom: 3.125rem;
  z-index: 2; }
  @media (max-width: 1024px) {
    .banner-home .swiper-pagination {
      bottom: 1.04167rem; } }
  .banner-home .swiper-pagination-bullet {
    height: 0.83333rem;
    width: 0.83333rem;
    background-color: #fff;
    opacity: 1;
    margin: 0 0.52083rem !important; }
    .banner-home .swiper-pagination-bullet-active {
      background-color: #006CB9; }

.banner-item {
  position: relative; }
  .banner-item .banner-icon {
    position: absolute;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: clamp(12px, 4.16667rem, 4.375rem);
    color: #fff; }
    @media (max-width: 768px) {
      .banner-item .banner-icon {
        font-size: clamp(13px, 4.16667rem, 4.375rem); } }
  .banner-item .banner-caption {
    position: absolute;
    bottom: 7.5rem;
    width: 100%;
    color: #fff; }
    @media (max-width: 1024px) {
      .banner-item .banner-caption {
        bottom: 1.66667rem; } }
    .banner-item .banner-caption .title {
      font-size: 3.64583rem;
      font-weight: 900; }
      @media (max-width: 1024px) {
        .banner-item .banner-caption .title {
          font-size: 1.25rem;
          margin-bottom: 0.625rem; } }
    @media (max-width: 1024px) {
      .banner-item .banner-caption .desc {
        font-size: 0.9375rem; } }

.home-3 .item-col .item {
  background: #F8F8F8; }

.home-3 .item-col:nth-child(2) .item {
  background: rgba(203, 203, 203, 0.3); }

.home-3 .item-col:nth-child(3) .item {
  background-color: #D6D6D6; }

.home-3 .item {
  padding: 3.125rem;
  align-items: center; }
  .home-3 .item .icon {
    width: 8.33333rem;
    height: 8.33333rem;
    border-radius: 999999px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 5px solid #FFFFFF;
    box-shadow: -4px 4px 20px rgba(25, 64, 92, 0.25);
    background-color: #00518B; }
    .home-3 .item .icon figure img {
      max-width: 4.6875rem; }
  .home-3 .item .title {
    text-align: center; }
    @media (min-width: 1025px) {
      .home-3 .item .title {
        max-width: 14.58333rem;
        margin-left: auto;
        margin-right: auto; } }

.home-2 {
  background-position: bottom right; }
  @media (min-width: 1025px) {
    .home-2 {
      padding-bottom: 11.04167rem !important; } }
  @media (max-width: 768px) {
    .home-2 {
      background-image: none !important;
      background-color: #E6F1F9; }
      .home-2 .heading-3 {
        text-align: center; } }
  .home-2 .img figure {
    filter: drop-shadow(6px 6px 15px rgba(0, 0, 0, 0.1));
    position: relative;
    padding-top: 86.71329%; }
    .home-2 .img figure > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .home-2 .img figure img {
      width: 100%; }

.home-4 {
  background-position: bottom right;
  color: #fff; }
  @media (max-width: 1024px) {
    .home-4 {
      background-image: linear-gradient(224.07deg, #006CB9 12.94%, #00365D 94.92%); } }
  @media (min-width: 1025px) {
    .home-4 .img {
      padding-right: 4.16667rem; } }
  .home-4 .img figure {
    filter: drop-shadow(6px 6px 15px rgba(0, 0, 0, 0.1));
    position: relative;
    padding-top: 86.71329%; }
    .home-4 .img figure > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .home-4 .img figure img {
      width: 100%; }
  .home-4 .list-item .item {
    margin-bottom: 1.04167rem;
    padding-bottom: 20px;
    border-bottom: 1px dashed #3389C7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.25rem; }
    @media (max-width: 768px) {
      .home-4 .list-item .item {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.41667rem; } }
    .home-4 .list-item .item .title {
      display: flex;
      align-items: center; }
      .home-4 .list-item .item .title > * + * {
        margin-left: 1.25rem; }
      .home-4 .list-item .item .title .num {
        width: 2.08333rem;
        height: 2.08333rem;
        border-radius: 999999px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 2.08333rem;
        border: 1px solid #fff;
        color: #fff;
        transition: all 0.3s ease-in-out;
        font-weight: 700; }
        .home-4 .list-item .item:hover .title .num {
          background-color: #fff;
          color: #00518B; }
    @media (max-width: 768px) {
      .home-4 .list-item .item .link {
        padding-left: 3.33333rem; }
        .home-4 .list-item .item .link a {
          padding-left: 0 !important; } }
    .home-4 .list-item .item .link a {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-left: 0.625rem;
      transition: all 0.3s ease-in-out;
      border-radius: 9999px; }
      .home-4 .list-item .item .link a > * + * {
        margin-left: 0.41667rem; }
      .home-4 .list-item .item .link a span {
        text-transform: uppercase;
        font-weight: 700;
        white-space: nowrap; }
      .home-4 .list-item .item .link a em {
        width: 1.45833rem;
        height: 1.45833rem;
        border-radius: 999999px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        color: #00518B; }
      .home-4 .list-item .item .link a:hover {
        background-color: #fff;
        color: #00518B; }

.dealer-map-hover {
  background: radial-gradient(50% 50% at 50% 50%, #016CB9 0%, #00477B 100%);
  color: #fff; }

.dealer-group-list {
  padding-left: 1.25rem; }
  @media (max-width: 1024px) {
    .dealer-group-list {
      padding-left: 2.5rem; } }
  .dealer-group-list > * + * {
    margin-top: 1.66667rem;
    padding-top: 1.66667rem;
    border-top: 1px solid #d9d9d9; }
  .dealer-group-list .dealer-group {
    font-family: "Nunito Sans";
    font-weight: 700; }
    .dealer-group-list .dealer-group .dealer-group-item {
      position: relative;
      display: flex;
      align-items: center; }
      .dealer-group-list .dealer-group .dealer-group-item > * + * {
        margin-left: 1.66667rem; }
    .dealer-group-list .dealer-group .dot {
      position: absolute;
      left: -2.91667rem;
      width: 1.04167rem;
      height: 1.04167rem;
      border-radius: 999999px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin-left: 0 !important;
      z-index: 2;
      background-color: #00477B; }
    .dealer-group-list .dealer-group .quantity {
      max-width: 4.01042rem;
      flex: 0 0 4.01042rem;
      width: 100%;
      font-size: clamp(12px, 3.33333rem, 3.54167rem);
      transition: all 0.3s ease-in-out; }
      @media (max-width: 768px) {
        .dealer-group-list .dealer-group .quantity {
          font-size: clamp(13px, 3.33333rem, 3.54167rem); } }
    .dealer-group-list .dealer-group .name {
      transition: all 0.3s ease-in-out; }
    @media (hover: hover) and (pointer: fine) {
      .dealer-group-list .dealer-group:hover .quantity {
        font-size: clamp(12px, 4.6875rem, 4.89583rem);
        max-width: 5.625rem;
        flex: 0 0 5.625rem;
        width: 100%; } }
  @media (hover: hover) and (pointer: fine) and (max-width: 768px) {
    .dealer-group-list .dealer-group:hover .quantity {
      font-size: clamp(13px, 4.6875rem, 4.89583rem); } }
    @media (hover: hover) and (pointer: fine) {
      .dealer-group-list .dealer-group:hover .name {
        font-size: clamp(12px, 1.66667rem, 1.875rem); } }
  @media (hover: hover) and (pointer: fine) and (max-width: 768px) {
    .dealer-group-list .dealer-group:hover .name {
      font-size: clamp(13px, 1.66667rem, 1.875rem); } }
    .dealer-group-list .dealer-group:nth-child(1) .dot {
      border: 0.26042rem solid #E6F1F9; }
    .dealer-group-list .dealer-group:nth-child(1) .quantity {
      color: #E6F1F9; }
    .dealer-group-list .dealer-group:nth-child(2) .dot {
      border: 0.26042rem solid #99C4E3; }
    .dealer-group-list .dealer-group:nth-child(2) .quantity {
      color: #99C4E3; }
    .dealer-group-list .dealer-group:nth-child(3) .dot {
      border: 0.26042rem solid #3389C7; }
    .dealer-group-list .dealer-group:nth-child(3) .quantity {
      color: #3389C7; }

.dealer-map svg {
  width: 100%;
  height: auto; }
  @media (min-width: 1025px) {
    .dealer-map svg {
      max-height: 80vh; } }
  .dealer-map svg .pinpoint-group {
    transition: all 0.3s ease-in-out; }
    .dealer-map svg .pinpoint-group path, .dealer-map svg .pinpoint-group rect {
      transition: all 0.3s ease-in-out; }
    .dealer-map svg .pinpoint-group.no-hovering path[fill="#00365D"], .dealer-map svg .pinpoint-group.no-hovering path[fill="#00365d"], .dealer-map svg .pinpoint-group.no-hovering path[fill="#3C9B12"], .dealer-map svg .pinpoint-group.no-hovering path[fill="#A80000"], .dealer-map svg .pinpoint-group.no-hovering path[style="fill:#e6f1f9"], .dealer-map svg .pinpoint-group.no-hovering rect {
      fill: #D6D6D6 !important; }
    .dealer-map svg .pinpoint-group.no-hovering path[fill="#333"] {
      opacity: 0.5; }

.dealer-item {
  display: flex;
  flex-direction: column; }
  .dealer-item-img figure a {
    display: block;
    position: relative;
    padding-top: 62.5%;
    border-radius: 1.66667rem;
    overflow: hidden; }
    .dealer-item-img figure a > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .dealer-item-caption {
    display: flex;
    flex-direction: column;
    margin-top: 1.04167rem; }
    .dealer-item-caption > * + * {
      margin-top: 1.04167rem; }
  .dealer-item-address {
    color: #050505; }
    .dealer-item-address ul {
      display: flex;
      flex-direction: column; }
      .dealer-item-address ul > * + * {
        margin-top: 1.04167rem; }
      .dealer-item-address ul li {
        display: flex;
        gap: 0.41667rem; }
        .dealer-item-address ul li span {
          color: #006CB9;
          position: relative;
          top: 0.3125rem;
          max-width: 1.04167rem;
          flex: 0 0 1.04167rem;
          width: 100%; }

.home-5 {
  position: relative;
  z-index: 1; }
  @media (min-width: 1025px) {
    .home-5::before {
      content: "";
      display: block;
      position: absolute;
      height: 9.42708rem;
      width: 50.83333rem;
      background-image: url(../img/home-bg-3.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      bottom: 0;
      left: 0;
      z-index: -1; } }

.home-6 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3.125rem 0; }
  @media (min-width: 1025px) {
    .home-6 {
      min-height: 45.20833rem; } }

.manufacture-image-slider .swiper-container {
  overflow: hidden !important; }

.manufacture-detail-2 .item-title {
  font-weight: 700; }

.manufacture-detail-2 .item-img figure {
  position: relative;
  padding-top: 56.25%; }
  .manufacture-detail-2 .item-img figure > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.manufacture-detail-2 .item:nth-child(even) .row {
  flex-direction: row-reverse; }

.manufacture-standard-slider .item {
  position: relative;
  padding: 3.75rem 3.125rem;
  background-color: #fff;
  border-radius: 1.04167rem;
  background-clip: padding-box;
  display: flex;
  flex-direction: column; }
  .manufacture-standard-slider .item > * + * {
    margin-top: 1.04167rem; }
  @media (max-width: 1024px) {
    .manufacture-standard-slider .item {
      padding: 2.08333rem 1.66667rem; } }
  @media (max-width: 576px) {
    .manufacture-standard-slider .item {
      padding: 3.125rem 1.04167rem 1.66667rem; } }
  .manufacture-standard-slider .item-icon {
    position: absolute;
    top: 0.41667rem;
    left: 0.41667rem;
    width: 2.34375rem; }
    .manufacture-standard-slider .item-icon figure {
      position: relative;
      padding-top: 100%; }
      .manufacture-standard-slider .item-icon figure > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .manufacture-standard-slider .item-title {
    color: #00518B;
    font-weight: 700;
    margin-top: 0 !important; }
  .manufacture-standard-slider .item-content ul {
    padding-left: 1rem;
    list-style: disc; }

.manufacture-item {
  position: relative; }
  .manufacture-item + .manufacture-item {
    margin-top: 9.375rem; }
    @media (max-width: 768px) {
      .manufacture-item + .manufacture-item {
        margin-top: 1.66667rem; } }
  .manufacture-item-img figure a {
    position: relative;
    padding-top: 41.92073%;
    border-radius: 2.5rem;
    background-clip: padding-box;
    overflow: hidden;
    display: block; }
    .manufacture-item-img figure a > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  @media (max-width: 768px) {
    .manufacture-item-img figure a {
      border-radius: 1.04167rem 1.04167rem 0 0;
      position: relative;
      padding-top: 62.5%; }
      .manufacture-item-img figure a > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; } }
  @media (min-width: 769px) {
    .manufacture-item-caption {
      position: absolute;
      top: -3.125rem;
      bottom: -3.125rem;
      width: 100%; } }
  .manufacture-item-content {
    background-color: rgba(0, 81, 139, 0.85);
    color: #fff;
    padding: 2.5rem;
    justify-content: center; }
    @media (max-width: 768px) {
      .manufacture-item-content {
        padding: 1.25rem;
        border-radius: 0 0 1.04167rem 1.04167rem; } }

.news-detail-meta {
  padding-bottom: 0.625rem;
  border-bottom: 1px solid #d9d9d9; }

.recruitment-detail-brief table {
  width: 100%;
  border-top: 1px solid #D9D9D9; }
  .recruitment-detail-brief table td {
    padding: 1.04167rem; }
    .recruitment-detail-brief table td:first-child {
      font-weight: 700;
      white-space: nowrap; }
  .recruitment-detail-brief table tr:nth-child(even) {
    background-color: #E6F1F9; }

.recruitment-detait-attributes {
  margin-top: 1.04167rem;
  padding-top: 1.04167rem;
  border-top: 1px solid #D9D9D9;
  padding-left: 1.04167rem; }
  .recruitment-detait-attributes .attribute-item {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1.04167rem;
    margin-bottom: -1.04167rem; }
    .recruitment-detait-attributes .attribute-item > * {
      padding-left: 1.04167rem;
      padding-bottom: 1.04167rem; }
    .recruitment-detait-attributes .attribute-item .title {
      font-weight: 700;
      color: #006CB9;
      max-width: 100%;
      flex: 0 0 100%;
      width: 100%; }
      @media (min-width: 1025px) {
        .recruitment-detait-attributes .attribute-item .title {
          max-width: 22.22222%;
          flex: 0 0 22.22222%;
          width: 100%; } }
    .recruitment-detait-attributes .attribute-item .content {
      max-width: 100%;
      flex: 0 0 100%;
      width: 100%; }
      @media (min-width: 1025px) {
        .recruitment-detait-attributes .attribute-item .content {
          max-width: 77.77778%;
          flex: 0 0 77.77778%;
          width: 100%; } }

.recruitment-side-item {
  border-radius: 1.04167rem;
  overflow: hidden; }
  .recruitment-side-item-title {
    padding: 0.625rem 1.66667rem;
    background-color: #00518B;
    color: #fff; }
  .recruitment-side-item-body {
    padding: 1.04167rem 1.66667rem;
    background-color: #E6F1F9; }

.recruitment-other-list ul li {
  padding-bottom: 0.41667rem;
  border-bottom: 1px solid #D9D9D9; }
  .recruitment-other-list ul li + li {
    margin-top: 0.41667rem; }

.apply-frm {
  position: relative;
  margin: 0;
  background: #fff;
  line-height: 1.375;
  padding: 2.70833rem 1.5625rem;
  overflow: hidden; }
  @media (min-width: 577px) {
    .apply-frm {
      font-size: 16px; } }
  .apply-frm h4 {
    font-size: 1.5em;
    line-height: 2em; }
  .apply-frm > .row {
    margin-left: -1.04167rem;
    margin-bottom: -1.04167rem; }
    .apply-frm > .row > * {
      padding-left: 1.04167rem;
      padding-bottom: 1.04167rem; }
    @media (max-width: 768px) {
      .apply-frm > .row > * {
        padding: 0 0 1.04167rem 1.04167rem !important; } }
  .apply-frm .form-group .label {
    display: none; }
  .apply-frm .form-group input[type="text"], .apply-frm .form-group input[type="date"], .apply-frm .form-group select, .apply-frm .form-group textarea {
    border-radius: 0.25em;
    height: 2.5em;
    font-size: 1em; }
  .apply-frm .form-group textarea {
    height: 6.25em; }
  .apply-frm .frm-btnwrap {
    margin-top: 0; }
  .apply-frm .frm-btn {
    min-width: auto;
    width: 100%; }
    @media (min-width: 577px) {
      .apply-frm .frm-btn {
        font-size: 16px; } }
    .apply-frm .frm-btn::before {
      display: none; }
    .apply-frm .frm-btn input[type="submit"] {
      height: 2.5em;
      border-radius: 999px;
      font-size: inherit; }
  .apply-frm .attachfile1, .apply-frm .attachfile2 {
    position: relative; }
    .apply-frm .attachfile1 .label, .apply-frm .attachfile2 .label {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2.5em;
      padding: 0 2em;
      background: #EDEDED;
      border-radius: 999px;
      cursor: pointer;
      font-size: 1em; }
    .apply-frm .attachfile1 .ruBrowse, .apply-frm .attachfile2 .ruBrowse {
      display: none; }
    .apply-frm .attachfile1 .ruFileInput, .apply-frm .attachfile2 .ruFileInput {
      height: 2.08333rem;
      width: 100%;
      cursor: pointer; }
    .apply-frm .attachfile1 .ruInputs, .apply-frm .attachfile2 .ruInputs {
      position: static; }
      .apply-frm .attachfile1 .ruInputs li, .apply-frm .attachfile2 .ruInputs li {
        margin-bottom: 0;
        height: auto; }
    .apply-frm .attachfile1 .ruFileWrap, .apply-frm .attachfile2 .ruFileWrap {
      height: auto;
      position: static; }

.recruitment-detail-page .fancybox-iframe {
  width: 928px !important; }

.recruitment-list {
  counter-reset: section; }
  @media (max-width: 1024px) {
    .recruitment-list {
      overflow: auto !important; } }
  .recruitment-list table {
    width: 100%;
    text-align: center; }
    @media (max-width: 1024px) {
      .recruitment-list table {
        width: 150%; } }
    .recruitment-list table th, .recruitment-list table td {
      padding: 0.83333rem 2.08333rem; }
      @media (max-width: 576px) {
        .recruitment-list table th, .recruitment-list table td {
          padding: 0.83333rem 1.04167rem; } }
    .recruitment-list table th {
      background-color: #006CB9;
      color: #fff;
      text-transform: uppercase;
      font-weight: 700;
      white-space: nowrap; }
    .recruitment-list table tr:nth-child(even) {
      background-color: #EBEBEB; }
    .recruitment-list table .num::before {
      counter-increment: section;
      content: counter(section); }

.recruitment-policy-item + .recruitment-policy-item {
  margin-top: 2.08333rem; }

@media (max-width: 768px) {
  .recruitment-policy-item {
    text-align: center; } }

.recruitment-policy-item:nth-child(even) .row {
  flex-direction: row-reverse; }

.recruitment-policy-item-img {
  max-width: 21.04167rem;
  margin: 0 auto;
  position: relative; }
  .recruitment-policy-item-img figure {
    position: relative;
    padding-top: 100%;
    border-radius: 50%;
    overflow: hidden; }
    .recruitment-policy-item-img figure > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  @media (min-width: 577px) {
    .recruitment-policy-item-img::before {
      content: "";
      display: block;
      position: absolute;
      top: 4.375rem;
      left: -3.75rem;
      width: 29.5rem;
      height: 11.625rem;
      border-radius: 50%;
      background-color: #E6F1F9;
      transform: rotate(-30deg); } }

@media (max-width: 768px) {
  .recruitment-slogan .layout > * {
    text-align: center !important; } }

@media (min-width: 1025px) {
  .culture-trigger-item-list {
    display: flex;
    flex-direction: column; }
    .culture-trigger-item-list .culture-trigger-item {
      flex: 1; } }

@media (max-width: 1024px) {
  .culture-trigger-item-list {
    padding: 2.08333rem 0; }
    .culture-trigger-item-list > * + * {
      margin-top: 1.04167rem; } }

.culture-trigger-item {
  display: flex;
  gap: 1.04167rem;
  align-items: center;
  background-color: #fff;
  padding: 1.875rem 0;
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
  cursor: pointer; }
  @media (max-width: 1024px) {
    .culture-trigger-item {
      padding: 1.04167rem; } }
  @media (min-width: 1025px) {
    .culture-trigger-item::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 1000px;
      background-color: #fff;
      height: 100%;
      transition: all 0.3s ease-in-out;
      box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.1);
      z-index: -1; } }
  .culture-trigger-item::after {
    content: "";
    font-family: 'Font Awesome 6 Pro';
    position: absolute;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    width: 1.66667rem;
    background-color: #3389C7;
    color: #66A7D5;
    font-weight: 900;
    font-size: clamp(12px, 1.66667rem, 1.875rem);
    opacity: 0;
    transition: all 0.3s ease-in-out; }
    @media (max-width: 768px) {
      .culture-trigger-item::after {
        font-size: clamp(13px, 1.66667rem, 1.875rem); } }
  .culture-trigger-item-icon {
    max-width: 4.79167rem;
    flex: 0 0 4.79167rem;
    width: 100%; }
    .culture-trigger-item-icon figure {
      display: flex;
      height: 4.79167rem;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
      background-color: #fff;
      transition: all 0.3s ease-in-out; }
      .culture-trigger-item-icon figure img {
        transition: all 0.3s ease-in-out;
        width: 3.125rem; }
  .culture-trigger-item-title {
    font-weight: 700;
    flex: 1;
    padding-right: 2.08333rem; }
  @media (min-width: 1025px) {
    .culture-trigger-item.active {
      background-color: #006CB9;
      color: #fff; }
      .culture-trigger-item.active::before {
        background-color: #006CB9; } }
  .culture-trigger-item.active::after {
    opacity: 1; }
  .culture-trigger-item.active .culture-trigger-item-icon figure {
    background-color: #006CB9; }
    .culture-trigger-item.active .culture-trigger-item-icon figure img {
      filter: brightness(0) invert(1); }

.culture-target-item, .culture-mobile-item {
  padding: 3.125rem 0 1.875rem;
  color: #fff; }
  @media (max-width: 1024px) {
    .culture-target-item, .culture-mobile-item {
      padding: 0; } }
  .culture-target-item-img figure, .culture-mobile-item-img figure {
    position: relative;
    padding-top: 48.55172%;
    border-radius: 1.04167rem;
    overflow: hidden; }
    .culture-target-item-img figure > *, .culture-mobile-item-img figure > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .culture-target-item-brief ul, .culture-mobile-item-brief ul {
    list-style: disc;
    padding-left: 1rem; }

.product-detail-images .img {
  padding-top: 100%;
  position: relative; }
  .product-detail-images .img figure {
    border: 1px solid #99C4E3;
    border-radius: 0.41667rem;
    position: absolute;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .product-detail-images .img figure img {
      width: 100%; }

.product-detail-images .swiper-slide-count {
  position: absolute;
  right: 1.66667rem;
  top: 1.66667rem;
  z-index: 2;
  bottom: auto;
  width: auto;
  left: auto; }

.product-detail-content strong {
  color: #333333; }

.product-detail-purchase {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.25rem;
  margin-bottom: -1.25rem; }
  .product-detail-purchase > * {
    padding-left: 1.25rem;
    padding-bottom: 1.25rem; }
  .product-detail-purchase > * {
    max-width: 50%;
    flex: 0 0 50%;
    width: 100%; }
  .product-detail-purchase .btn-icon {
    width: 100%;
    height: 2.5rem; }
    .product-detail-purchase .btn-icon::before {
      opacity: 1; }

.product-detail-attributes .tabs-nav {
  display: flex;
  background-color: #E6F1F9;
  justify-content: center;
  overflow: auto; }
  .product-detail-attributes .tabs-nav > * + * {
    margin-left: 4.16667rem; }
  @media (max-width: 768px) {
    .product-detail-attributes .tabs-nav {
      justify-content: flex-start;
      padding: 0 1.04167rem;
      display: flex; }
      .product-detail-attributes .tabs-nav > * + * {
        margin-left: 1.66667rem; } }
  .product-detail-attributes .tabs-nav li a {
    display: flex;
    align-items: center;
    height: 3.64583rem;
    color: #858585;
    border-bottom: 1px solid transparent;
    white-space: nowrap; }
  .product-detail-attributes .tabs-nav li.active a {
    color: #006CB9;
    border-bottom: 1px solid #006CB9; }

.product-detail-attributes .expand-content {
  max-height: 31.25rem; }

.spin-input-wrap {
  display: flex;
  height: 2.5rem;
  border: 1px solid #D6D6D6;
  align-items: center;
  border-radius: 999px;
  background-clip: padding-box;
  padding: 0 1.04167rem; }
  .spin-input-wrap > * + * {
    margin-left: 0.83333rem; }
  .spin-input-wrap .btn-spin, .spin-input-wrap .spin-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-clip: padding-box;
    cursor: pointer; }
  .spin-input-wrap input {
    border: 0;
    text-align: center;
    width: 80%; }

.product-filter {
  display: flex;
  flex-wrap: wrap;
  margin-left: -3.33333rem;
  margin-bottom: -3.33333rem; }
  .product-filter > * {
    padding-left: 3.33333rem;
    padding-bottom: 3.33333rem; }
  @media (max-width: 768px) {
    .product-filter {
      display: flex;
      flex-wrap: wrap;
      margin-left: -1.04167rem;
      margin-bottom: -1.04167rem; }
      .product-filter > * {
        padding-left: 1.04167rem;
        padding-bottom: 1.04167rem; } }
  @media (min-width: 769px) {
    .product-filter-item {
      display: flex;
      align-items: center; }
      .product-filter-item > * + * {
        margin-left: 1.04167rem; } }
  @media (max-width: 768px) {
    .product-filter-item {
      display: flex;
      flex-direction: column; }
      .product-filter-item > * + * {
        margin-top: 0.41667rem; } }
  .product-filter-item-title {
    color: #1A1A1A; }
  .product-filter-item-body {
    flex: 1; }
    .product-filter-item-body .form-group select {
      color: #5C5C5C;
      background-image: url("../img/sl-bg-gray.svg");
      background-repeat: no-repeat;
      background-position: calc(100% - 0.625rem) 50%;
      appearance: none;
      background-size: 0.72917rem 0.83333rem; }
  .product-filter-item-1 {
    max-width: 25.7622%;
    flex: 0 0 25.7622%;
    width: 100%; }
    @media (max-width: 768px) {
      .product-filter-item-1 {
        max-width: 100%;
        flex: 0 0 100%;
        width: 100%; } }
  .product-filter-item-2 {
    max-width: 44.81707%;
    flex: 0 0 44.81707%;
    width: 100%; }
    @media (max-width: 768px) {
      .product-filter-item-2 {
        max-width: 100%;
        flex: 0 0 100%;
        width: 100%; } }

.supply-detail .container {
  background-image: url(../img/supply-bg.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center; }

.supply-detail .attribute-tab {
  display: flex;
  overflow: auto; }
  .supply-detail .attribute-tab > * + * {
    margin-left: 1.875rem; }
  .supply-detail .attribute-tab li a {
    display: flex;
    align-items: center;
    height: 2.29167rem;
    color: #858585;
    border-bottom: 1px solid transparent;
    white-space: nowrap; }
  .supply-detail .attribute-tab li.active a {
    color: #006CB9;
    border-bottom: 1px solid #006CB9; }

.supply-detail .tab-content table {
  width: 100%;
  text-align: center; }
  .supply-detail .tab-content table th, .supply-detail .tab-content table td {
    padding: 0.83333rem 2.08333rem; }
    @media (max-width: 576px) {
      .supply-detail .tab-content table th, .supply-detail .tab-content table td {
        padding: 0.83333rem 1.04167rem; } }
  .supply-detail .tab-content table th {
    background-color: #00518B;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700; }
  .supply-detail .tab-content table tr {
    background-color: #fff; }
    .supply-detail .tab-content table tr:nth-child(even) {
      background-color: #EBEBEB; }

.supply-item:nth-child(even) .row {
  flex-direction: row-reverse; }

.supply-item .img figure {
  overflow: hidden;
  border-radius: 0.625rem;
  background-clip: padding-box;
  position: relative;
  padding-top: 66.875%; }
  .supply-item .img figure > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

@media (min-width: 1025px) {
  .supply-item .content {
    padding-left: 3.125rem; }
  .supply-item .content {
    padding-right: 3.125rem; } }

@media (max-width: 768px) {
  .supply-item .content {
    display: flex; }
    .supply-item .content > * + * {
      margin-left: 1.04167rem; }
    .supply-item .content .icon {
      max-width: 3.125rem;
      flex: 0 0 3.125rem;
      width: 100%; }
    .supply-item .content .layout {
      flex: auto; } }

.technology-item-title {
  color: #006CB9;
  font-weight: 700; }

.technology-item-img figure {
  border-radius: 2.5rem;
  background-clip: padding-box;
  overflow: hidden; }
  .technology-item-img figure a {
    display: block;
    position: relative;
    padding-top: 86.71329%; }
    .technology-item-img figure a > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  @media (max-width: 1024px) {
    .technology-item-img figure {
      border-radius: 0.83333rem;
      background-clip: padding-box; }
      .technology-item-img figure a {
        position: relative;
        padding-top: 62.5%; }
        .technology-item-img figure a > * {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover; } }

@media (min-width: 1025px) {
  .technology-item-horizontal:nth-child(odd) .technology-item-img {
    padding-left: 3.54167rem; }
  .technology-item-horizontal:nth-child(even) .technology-item-img {
    padding-right: 3.54167rem; }
  .technology-item-horizontal:nth-child(even) .row {
    flex-direction: row-reverse; } }

.technology-item-horizontal + .technology-item-horizontal {
  margin-top: 3.125rem; }
  @media (max-width: 1024px) {
    .technology-item-horizontal + .technology-item-horizontal {
      margin-top: 2.08333rem; } }

.technology-item-vertical {
  display: flex;
  flex-direction: column; }
  .technology-item-vertical .technology-item-img figure a {
    position: relative;
    padding-top: 86.53846%; }
    .technology-item-vertical .technology-item-img figure a > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .technology-item-vertical .technology-item-caption {
    flex: 1 1 0%; }
  .technology-item-vertical .technology-item-brief {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    margin-bottom: 0.83333rem; }
  .technology-item-vertical .technology-item-title {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; }
  .technology-item-vertical .technology-item-btn {
    margin-top: auto !important; }

.article-content {
  color: #1A1A1A; }
  .article-content > * + * {
    margin-top: 1.66667rem; }
  .article-content ul {
    list-style: disc;
    padding-left: 1.25rem; }
  .article-content table td, .article-content table th {
    border: 1px solid #dee2e6;
    padding: .75rem;
    vertical-align: top; }
  .article-content h2 {
    font-size: 1.04167rem;
    font-weight: 700; }
  .article-content h3 {
    font-size: 0.9375rem;
    font-weight: 600; }
  .article-content * + h2 {
    margin-top: 1.45833rem; }
  .article-content em, .article-content i {
    font-style: italic; }
  .article-content sup {
    vertical-align: super;
    font-size: 80%; }

.expand-content {
  overflow: hidden;
  position: relative;
  transition: max-height 1s ease-in-out; }
  .expand-content.show {
    overflow: visible;
    max-height: none; }
  .expand-content .expand-content-overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 8.33333rem;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 17.71%, rgba(255, 255, 255, 0.75) 35.94%, rgba(255, 255, 255, 0.904472) 56.25%, #FFFFFF 100%);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 0.83333rem;
    pointer-events: none; }
    .expand-content .expand-content-overlay .expand-content-trigger {
      pointer-events: all; }
    .expand-content .expand-content-overlay.active {
      background: transparent;
      bottom: -2.5rem; }

.border-gray {
  border: 1px solid #DDDDDD; }

@media (min-width: 576px) {
  .border-sm-gray {
    border: 1px solid #DDDDDD; } }

.border-primary-600 {
  border: 1px solid #00518B; }

@media (min-width: 576px) {
  .border-sm-primary-600 {
    border: 1px solid #00518B; } }

.border-none {
  border: none; }

@media (min-width: 576px) {
  .border-sm-none {
    border: none; } }

.border-none {
  border: none; }

@media (min-width: 576px) {
  .border-sm-none {
    border: none; } }

.border-top-gray {
  border-top: 1px solid #DDDDDD; }

@media (min-width: 576px) {
  .border-top-sm-gray {
    border-top: 1px solid #DDDDDD; } }

.border-top-primary-600 {
  border-top: 1px solid #00518B; }

@media (min-width: 576px) {
  .border-top-sm-primary-600 {
    border-top: 1px solid #00518B; } }

.border-right-gray {
  border-right: 1px solid #DDDDDD; }

@media (min-width: 576px) {
  .border-right-sm-gray {
    border-right: 1px solid #DDDDDD; } }

.border-right-primary-600 {
  border-right: 1px solid #00518B; }

@media (min-width: 576px) {
  .border-right-sm-primary-600 {
    border-right: 1px solid #00518B; } }

.border-bottom-gray {
  border-bottom: 1px solid #DDDDDD; }

@media (min-width: 576px) {
  .border-bottom-sm-gray {
    border-bottom: 1px solid #DDDDDD; } }

.border-bottom-primary-600 {
  border-bottom: 1px solid #00518B; }

@media (min-width: 576px) {
  .border-bottom-sm-primary-600 {
    border-bottom: 1px solid #00518B; } }

.border-left-gray {
  border-left: 1px solid #DDDDDD; }

@media (min-width: 576px) {
  .border-left-sm-gray {
    border-left: 1px solid #DDDDDD; } }

.border-left-primary-600 {
  border-left: 1px solid #00518B; }

@media (min-width: 576px) {
  .border-left-sm-primary-600 {
    border-left: 1px solid #00518B; } }

.border-gray {
  border: 1px solid #DDDDDD; }

@media (min-width: 768px) {
  .border-md-gray {
    border: 1px solid #DDDDDD; } }

.border-primary-600 {
  border: 1px solid #00518B; }

@media (min-width: 768px) {
  .border-md-primary-600 {
    border: 1px solid #00518B; } }

.border-none {
  border: none; }

@media (min-width: 768px) {
  .border-md-none {
    border: none; } }

.border-none {
  border: none; }

@media (min-width: 768px) {
  .border-md-none {
    border: none; } }

.border-top-gray {
  border-top: 1px solid #DDDDDD; }

@media (min-width: 768px) {
  .border-top-md-gray {
    border-top: 1px solid #DDDDDD; } }

.border-top-primary-600 {
  border-top: 1px solid #00518B; }

@media (min-width: 768px) {
  .border-top-md-primary-600 {
    border-top: 1px solid #00518B; } }

.border-right-gray {
  border-right: 1px solid #DDDDDD; }

@media (min-width: 768px) {
  .border-right-md-gray {
    border-right: 1px solid #DDDDDD; } }

.border-right-primary-600 {
  border-right: 1px solid #00518B; }

@media (min-width: 768px) {
  .border-right-md-primary-600 {
    border-right: 1px solid #00518B; } }

.border-bottom-gray {
  border-bottom: 1px solid #DDDDDD; }

@media (min-width: 768px) {
  .border-bottom-md-gray {
    border-bottom: 1px solid #DDDDDD; } }

.border-bottom-primary-600 {
  border-bottom: 1px solid #00518B; }

@media (min-width: 768px) {
  .border-bottom-md-primary-600 {
    border-bottom: 1px solid #00518B; } }

.border-left-gray {
  border-left: 1px solid #DDDDDD; }

@media (min-width: 768px) {
  .border-left-md-gray {
    border-left: 1px solid #DDDDDD; } }

.border-left-primary-600 {
  border-left: 1px solid #00518B; }

@media (min-width: 768px) {
  .border-left-md-primary-600 {
    border-left: 1px solid #00518B; } }

.border-gray {
  border: 1px solid #DDDDDD; }

@media (min-width: 1024px) {
  .border-lg-gray {
    border: 1px solid #DDDDDD; } }

.border-primary-600 {
  border: 1px solid #00518B; }

@media (min-width: 1024px) {
  .border-lg-primary-600 {
    border: 1px solid #00518B; } }

.border-none {
  border: none; }

@media (min-width: 1024px) {
  .border-lg-none {
    border: none; } }

.border-none {
  border: none; }

@media (min-width: 1024px) {
  .border-lg-none {
    border: none; } }

.border-top-gray {
  border-top: 1px solid #DDDDDD; }

@media (min-width: 1024px) {
  .border-top-lg-gray {
    border-top: 1px solid #DDDDDD; } }

.border-top-primary-600 {
  border-top: 1px solid #00518B; }

@media (min-width: 1024px) {
  .border-top-lg-primary-600 {
    border-top: 1px solid #00518B; } }

.border-right-gray {
  border-right: 1px solid #DDDDDD; }

@media (min-width: 1024px) {
  .border-right-lg-gray {
    border-right: 1px solid #DDDDDD; } }

.border-right-primary-600 {
  border-right: 1px solid #00518B; }

@media (min-width: 1024px) {
  .border-right-lg-primary-600 {
    border-right: 1px solid #00518B; } }

.border-bottom-gray {
  border-bottom: 1px solid #DDDDDD; }

@media (min-width: 1024px) {
  .border-bottom-lg-gray {
    border-bottom: 1px solid #DDDDDD; } }

.border-bottom-primary-600 {
  border-bottom: 1px solid #00518B; }

@media (min-width: 1024px) {
  .border-bottom-lg-primary-600 {
    border-bottom: 1px solid #00518B; } }

.border-left-gray {
  border-left: 1px solid #DDDDDD; }

@media (min-width: 1024px) {
  .border-left-lg-gray {
    border-left: 1px solid #DDDDDD; } }

.border-left-primary-600 {
  border-left: 1px solid #00518B; }

@media (min-width: 1024px) {
  .border-left-lg-primary-600 {
    border-left: 1px solid #00518B; } }

.border-gray {
  border: 1px solid #DDDDDD; }

@media (min-width: 1280px) {
  .border-xl-gray {
    border: 1px solid #DDDDDD; } }

.border-primary-600 {
  border: 1px solid #00518B; }

@media (min-width: 1280px) {
  .border-xl-primary-600 {
    border: 1px solid #00518B; } }

.border-none {
  border: none; }

@media (min-width: 1280px) {
  .border-xl-none {
    border: none; } }

.border-none {
  border: none; }

@media (min-width: 1280px) {
  .border-xl-none {
    border: none; } }

.border-top-gray {
  border-top: 1px solid #DDDDDD; }

@media (min-width: 1280px) {
  .border-top-xl-gray {
    border-top: 1px solid #DDDDDD; } }

.border-top-primary-600 {
  border-top: 1px solid #00518B; }

@media (min-width: 1280px) {
  .border-top-xl-primary-600 {
    border-top: 1px solid #00518B; } }

.border-right-gray {
  border-right: 1px solid #DDDDDD; }

@media (min-width: 1280px) {
  .border-right-xl-gray {
    border-right: 1px solid #DDDDDD; } }

.border-right-primary-600 {
  border-right: 1px solid #00518B; }

@media (min-width: 1280px) {
  .border-right-xl-primary-600 {
    border-right: 1px solid #00518B; } }

.border-bottom-gray {
  border-bottom: 1px solid #DDDDDD; }

@media (min-width: 1280px) {
  .border-bottom-xl-gray {
    border-bottom: 1px solid #DDDDDD; } }

.border-bottom-primary-600 {
  border-bottom: 1px solid #00518B; }

@media (min-width: 1280px) {
  .border-bottom-xl-primary-600 {
    border-bottom: 1px solid #00518B; } }

.border-left-gray {
  border-left: 1px solid #DDDDDD; }

@media (min-width: 1280px) {
  .border-left-xl-gray {
    border-left: 1px solid #DDDDDD; } }

.border-left-primary-600 {
  border-left: 1px solid #00518B; }

@media (min-width: 1280px) {
  .border-left-xl-primary-600 {
    border-left: 1px solid #00518B; } }

.border-gray {
  border: 1px solid #DDDDDD; }

@media (min-width: 1440px) {
  .border-2xl-gray {
    border: 1px solid #DDDDDD; } }

.border-primary-600 {
  border: 1px solid #00518B; }

@media (min-width: 1440px) {
  .border-2xl-primary-600 {
    border: 1px solid #00518B; } }

.border-none {
  border: none; }

@media (min-width: 1440px) {
  .border-2xl-none {
    border: none; } }

.border-none {
  border: none; }

@media (min-width: 1440px) {
  .border-2xl-none {
    border: none; } }

.border-top-gray {
  border-top: 1px solid #DDDDDD; }

@media (min-width: 1440px) {
  .border-top-2xl-gray {
    border-top: 1px solid #DDDDDD; } }

.border-top-primary-600 {
  border-top: 1px solid #00518B; }

@media (min-width: 1440px) {
  .border-top-2xl-primary-600 {
    border-top: 1px solid #00518B; } }

.border-right-gray {
  border-right: 1px solid #DDDDDD; }

@media (min-width: 1440px) {
  .border-right-2xl-gray {
    border-right: 1px solid #DDDDDD; } }

.border-right-primary-600 {
  border-right: 1px solid #00518B; }

@media (min-width: 1440px) {
  .border-right-2xl-primary-600 {
    border-right: 1px solid #00518B; } }

.border-bottom-gray {
  border-bottom: 1px solid #DDDDDD; }

@media (min-width: 1440px) {
  .border-bottom-2xl-gray {
    border-bottom: 1px solid #DDDDDD; } }

.border-bottom-primary-600 {
  border-bottom: 1px solid #00518B; }

@media (min-width: 1440px) {
  .border-bottom-2xl-primary-600 {
    border-bottom: 1px solid #00518B; } }

.border-left-gray {
  border-left: 1px solid #DDDDDD; }

@media (min-width: 1440px) {
  .border-left-2xl-gray {
    border-left: 1px solid #DDDDDD; } }

.border-left-primary-600 {
  border-left: 1px solid #00518B; }

@media (min-width: 1440px) {
  .border-left-2xl-primary-600 {
    border-left: 1px solid #00518B; } }

.btn-primary-500 {
  background-color: #006CB9;
  color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .btn-primary-500:hover {
      background-color: #004e86; } }

.btn-border-primary-500 {
  border: 1px solid #006CB9;
  color: #006CB9; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-primary-500:hover {
      background-color: #006CB9;
      color: #fff; } }

.btn-secondary {
  background-color: #99C4E3;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-secondary:hover {
      background-color: #71add8; } }

.btn-border-secondary {
  border: 1px solid #99C4E3;
  color: #99C4E3; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-secondary:hover {
      background-color: #99C4E3;
      color: #333; } }

.btn-white {
  background-color: #fff;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-white:hover {
      background-color: #e6e6e6; } }

.btn-border-white {
  border: 1px solid #fff;
  color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-white:hover {
      background-color: #fff;
      color: #333; } }

.btn-primary {
  background: linear-gradient(224.07deg, #006CB9 12.94%, #00365D 94.92%); }

.btn {
  padding: 0.625rem 1.04167rem;
  display: inline-flex;
  transition: all 0.3s ease-in-out;
  font-weight: 400;
  line-height: 1.5;
  font-size: clamp(12px, 0.83333rem, 1.04167rem);
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  min-width: 8.75rem;
  gap: 0.41667rem; }
  @media (max-width: 768px) {
    .btn {
      font-size: clamp(13px, 0.83333rem, 1.04167rem); } }
  .btn > * + * {
    display: inline-block;
    margin-left: 0.41667rem; }
  .btn span {
    font-size: 0.83333rem; }
  .btn-pill {
    border-radius: 1000px; }
  .btn-normal {
    text-transform: none; }

.btn-icon {
  display: inline-flex;
  align-items: center;
  padding-right: 0.625rem;
  border-radius: 9999px;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out; }
  .btn-icon > * + * {
    display: inline-flex;
    margin-left: 0.625rem; }
  .btn-icon em {
    width: 2.29167rem;
    height: 2.29167rem;
    border-radius: 999999px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .btn-icon span {
    text-transform: uppercase;
    transition: all 0.3s ease-in-out; }
  .btn-icon:before {
    content: "";
    display: block;
    position: absolute;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 9999px;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    z-index: -1; }
  @media (hover: hover) and (pointer: fine) {
    .btn-icon:hover::before {
      opacity: 1; } }
  .btn-icon-gradient em {
    background-image: linear-gradient(224.07deg, #006CB9 12.94%, #00365D 94.92%);
    color: #fff; }
  .btn-icon-gradient span {
    background-image: linear-gradient(224.07deg, #006CB9 12.94%, #00365D 94.92%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent; }
  .btn-icon-gradient:before {
    background-image: linear-gradient(224.07deg, #006CB9 12.94%, #00365D 94.92%); }
  @media (hover: hover) and (pointer: fine) {
    .btn-icon-gradient:hover span {
      background-image: linear-gradient(224.07deg, #fff 12.94%, #fff 94.92%); } }
  .btn-icon-small em {
    width: 1.45833rem;
    height: 1.45833rem;
    border-radius: 999999px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .btn-icon-white em {
    background-color: #fff;
    color: #006CB9; }
  .btn-icon-white span {
    color: #fff; }
  .btn-icon-white:before {
    background-color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .btn-icon-white:hover span {
      color: #006CB9; } }

.button-group {
  display: flex; }
  .button-group > * + * {
    margin-left: 1.66667rem; }

.swiper-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 999999px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #006CB9;
  border: 1px solid #006CB9;
  font-size: 1.25rem;
  cursor: pointer;
  z-index: 2;
  transition: 0.3s all; }
  @media (max-width: 1024px) {
    .swiper-btn {
      display: none; } }
  @media (hover: hover) and (pointer: fine) {
    .swiper-btn:hover {
      background-color: #006CB9;
      color: #fff; } }
  .swiper-btn.swiper-button-disabled {
    opacity: 0;
    pointer-events: none; }
  .swiper-btn-white {
    color: #fff;
    border: 1px solid #fff; }

.swiper-navigation.is-between .swiper-btn {
  position: absolute;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 1.45833rem; }
  .swiper-navigation.is-between .swiper-btn.swiper-prev {
    right: 100%; }
  .swiper-navigation.is-between .swiper-btn.swiper-next {
    left: 100%; }
  @media (max-width: 1440px) {
    .swiper-navigation.is-between .swiper-btn {
      margin: 0 0.41667rem; } }

.swiper-navigation.is-between.is-no-gap .swiper-btn {
  margin: 0 0.52083rem; }
  .swiper-navigation.is-between.is-no-gap .swiper-btn.swiper-prev {
    right: auto;
    left: 0; }
  .swiper-navigation.is-between.is-no-gap .swiper-btn.swiper-next {
    left: auto;
    right: 0; }

.swiper-navigation.is-left {
  display: flex;
  gap: 0.625rem; }

.container {
  width: 100%;
  max-width: 96vw;
  padding-right: 0.83333rem;
  padding-left: 0.83333rem;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 577px) {
    .container {
      max-width: 90vw; } }
  @media (min-width: 769px) {
    .container {
      max-width: 96vw; } }
  @media (min-width: 1025px) {
    .container {
      max-width: 90vw; } }
  @media (min-width: 1281px) {
    .container {
      max-width: 88vw; } }
  @media (min-width: 1441px) {
    .container {
      max-width: clamp(1344px, 70rem, 100vw); } }

.dropdown {
  position: relative;
  display: inline-block; }

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  right: 0;
  z-index: 1; }
  .dropdown-content > * {
    background-color: #ffffff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 20px; }

.dropdown:hover .dropdown-content {
  display: block; }

.fz-12 {
  font-size: clamp(13px, 0.625rem, 16px); }

.fz-14 {
  font-size: clamp(13px, 0.72917rem, 18px); }

.fz-16 {
  font-size: clamp(13px, 0.83333rem, 20px); }

.fz-18 {
  font-size: clamp(13px, 0.9375rem, 22px); }

.fz-20 {
  font-size: clamp(13px, 1.04167rem, 24px); }

.fz-22 {
  font-size: clamp(13px, 1.14583rem, 26px); }

.fz-24 {
  font-size: clamp(13px, 1.25rem, 28px); }

.fz-26 {
  font-size: clamp(13px, 1.35417rem, 30px); }

.fz-28 {
  font-size: clamp(13px, 1.45833rem, 32px); }

.fz-30 {
  font-size: clamp(13px, 1.5625rem, 34px); }

.fz-32 {
  font-size: clamp(13px, 1.66667rem, 36px); }

.fz-34 {
  font-size: clamp(13px, 1.77083rem, 38px); }

.fz-36 {
  font-size: clamp(13px, 1.875rem, 40px); }

.fz-38 {
  font-size: clamp(13px, 1.97917rem, 42px); }

.fz-40 {
  font-size: clamp(13px, 2.08333rem, 44px); }

@media (min-width: 577px) {
  .fz-sm-12 {
    font-size: clamp(12px, 0.625rem, 16px); }
  .fz-sm-14 {
    font-size: clamp(12px, 0.72917rem, 18px); }
  .fz-sm-16 {
    font-size: clamp(12px, 0.83333rem, 20px); }
  .fz-sm-18 {
    font-size: clamp(12px, 0.9375rem, 22px); }
  .fz-sm-20 {
    font-size: clamp(12px, 1.04167rem, 24px); }
  .fz-sm-22 {
    font-size: clamp(12px, 1.14583rem, 26px); }
  .fz-sm-24 {
    font-size: clamp(12px, 1.25rem, 28px); }
  .fz-sm-26 {
    font-size: clamp(12px, 1.35417rem, 30px); }
  .fz-sm-28 {
    font-size: clamp(12px, 1.45833rem, 32px); }
  .fz-sm-30 {
    font-size: clamp(12px, 1.5625rem, 34px); }
  .fz-sm-32 {
    font-size: clamp(12px, 1.66667rem, 36px); }
  .fz-sm-34 {
    font-size: clamp(12px, 1.77083rem, 38px); }
  .fz-sm-36 {
    font-size: clamp(12px, 1.875rem, 40px); }
  .fz-sm-38 {
    font-size: clamp(12px, 1.97917rem, 42px); }
  .fz-sm-40 {
    font-size: clamp(12px, 2.08333rem, 44px); } }

@media (min-width: 769px) {
  .fz-md-12 {
    font-size: clamp(12px, 0.625rem, 16px); }
  .fz-md-14 {
    font-size: clamp(12px, 0.72917rem, 18px); }
  .fz-md-16 {
    font-size: clamp(12px, 0.83333rem, 20px); }
  .fz-md-18 {
    font-size: clamp(12px, 0.9375rem, 22px); }
  .fz-md-20 {
    font-size: clamp(12px, 1.04167rem, 24px); }
  .fz-md-22 {
    font-size: clamp(12px, 1.14583rem, 26px); }
  .fz-md-24 {
    font-size: clamp(12px, 1.25rem, 28px); }
  .fz-md-26 {
    font-size: clamp(12px, 1.35417rem, 30px); }
  .fz-md-28 {
    font-size: clamp(12px, 1.45833rem, 32px); }
  .fz-md-30 {
    font-size: clamp(12px, 1.5625rem, 34px); }
  .fz-md-32 {
    font-size: clamp(12px, 1.66667rem, 36px); }
  .fz-md-34 {
    font-size: clamp(12px, 1.77083rem, 38px); }
  .fz-md-36 {
    font-size: clamp(12px, 1.875rem, 40px); }
  .fz-md-38 {
    font-size: clamp(12px, 1.97917rem, 42px); }
  .fz-md-40 {
    font-size: clamp(12px, 2.08333rem, 44px); } }

@media (min-width: 1025px) {
  .fz-lg-12 {
    font-size: clamp(12px, 0.625rem, 16px); }
  .fz-lg-14 {
    font-size: clamp(12px, 0.72917rem, 18px); }
  .fz-lg-16 {
    font-size: clamp(12px, 0.83333rem, 20px); }
  .fz-lg-18 {
    font-size: clamp(12px, 0.9375rem, 22px); }
  .fz-lg-20 {
    font-size: clamp(12px, 1.04167rem, 24px); }
  .fz-lg-22 {
    font-size: clamp(12px, 1.14583rem, 26px); }
  .fz-lg-24 {
    font-size: clamp(12px, 1.25rem, 28px); }
  .fz-lg-26 {
    font-size: clamp(12px, 1.35417rem, 30px); }
  .fz-lg-28 {
    font-size: clamp(12px, 1.45833rem, 32px); }
  .fz-lg-30 {
    font-size: clamp(12px, 1.5625rem, 34px); }
  .fz-lg-32 {
    font-size: clamp(12px, 1.66667rem, 36px); }
  .fz-lg-34 {
    font-size: clamp(12px, 1.77083rem, 38px); }
  .fz-lg-36 {
    font-size: clamp(12px, 1.875rem, 40px); }
  .fz-lg-38 {
    font-size: clamp(12px, 1.97917rem, 42px); }
  .fz-lg-40 {
    font-size: clamp(12px, 2.08333rem, 44px); } }

@media (min-width: 1281px) {
  .fz-xl-12 {
    font-size: clamp(12px, 0.625rem, 16px); }
  .fz-xl-14 {
    font-size: clamp(12px, 0.72917rem, 18px); }
  .fz-xl-16 {
    font-size: clamp(12px, 0.83333rem, 20px); }
  .fz-xl-18 {
    font-size: clamp(12px, 0.9375rem, 22px); }
  .fz-xl-20 {
    font-size: clamp(12px, 1.04167rem, 24px); }
  .fz-xl-22 {
    font-size: clamp(12px, 1.14583rem, 26px); }
  .fz-xl-24 {
    font-size: clamp(12px, 1.25rem, 28px); }
  .fz-xl-26 {
    font-size: clamp(12px, 1.35417rem, 30px); }
  .fz-xl-28 {
    font-size: clamp(12px, 1.45833rem, 32px); }
  .fz-xl-30 {
    font-size: clamp(12px, 1.5625rem, 34px); }
  .fz-xl-32 {
    font-size: clamp(12px, 1.66667rem, 36px); }
  .fz-xl-34 {
    font-size: clamp(12px, 1.77083rem, 38px); }
  .fz-xl-36 {
    font-size: clamp(12px, 1.875rem, 40px); }
  .fz-xl-38 {
    font-size: clamp(12px, 1.97917rem, 42px); }
  .fz-xl-40 {
    font-size: clamp(12px, 2.08333rem, 44px); } }

@media (min-width: 1441px) {
  .fz-2xl-12 {
    font-size: clamp(12px, 0.625rem, 16px); }
  .fz-2xl-14 {
    font-size: clamp(12px, 0.72917rem, 18px); }
  .fz-2xl-16 {
    font-size: clamp(12px, 0.83333rem, 20px); }
  .fz-2xl-18 {
    font-size: clamp(12px, 0.9375rem, 22px); }
  .fz-2xl-20 {
    font-size: clamp(12px, 1.04167rem, 24px); }
  .fz-2xl-22 {
    font-size: clamp(12px, 1.14583rem, 26px); }
  .fz-2xl-24 {
    font-size: clamp(12px, 1.25rem, 28px); }
  .fz-2xl-26 {
    font-size: clamp(12px, 1.35417rem, 30px); }
  .fz-2xl-28 {
    font-size: clamp(12px, 1.45833rem, 32px); }
  .fz-2xl-30 {
    font-size: clamp(12px, 1.5625rem, 34px); }
  .fz-2xl-32 {
    font-size: clamp(12px, 1.66667rem, 36px); }
  .fz-2xl-34 {
    font-size: clamp(12px, 1.77083rem, 38px); }
  .fz-2xl-36 {
    font-size: clamp(12px, 1.875rem, 40px); }
  .fz-2xl-38 {
    font-size: clamp(12px, 1.97917rem, 42px); }
  .fz-2xl-40 {
    font-size: clamp(12px, 2.08333rem, 44px); } }

.form-group [type='color'], .form-group [type='date'], .form-group [type='datetime'], .form-group [type='datetime-local'], .form-group [type='email'], .form-group [type='month'], .form-group [type='number'], .form-group [type='password'], .form-group [type='search'], .form-group [type='tel'], .form-group [type='text'], .form-group [type='time'], .form-group [type='url'], .form-group [type='week'], .form-group input:not([type]), .form-group textarea, .input-group [type='color'], .input-group [type='date'], .input-group [type='datetime'], .input-group [type='datetime-local'], .input-group [type='email'], .input-group [type='month'], .input-group [type='number'], .input-group [type='password'], .input-group [type='search'], .input-group [type='tel'], .input-group [type='text'], .input-group [type='time'], .input-group [type='url'], .input-group [type='week'], .input-group input:not([type]), .input-group textarea {
  height: 2.29167rem;
  width: 100%;
  background-color: transparent;
  border: 1px solid #D6D6D6;
  padding: 0 1.04167rem;
  border-radius: 1.14583rem;
  background-clip: padding-box;
  font-size: clamp(12px, 0.72917rem, 0.9375rem); }
  @media (max-width: 768px) {
    .form-group [type='color'], .form-group [type='date'], .form-group [type='datetime'], .form-group [type='datetime-local'], .form-group [type='email'], .form-group [type='month'], .form-group [type='number'], .form-group [type='password'], .form-group [type='search'], .form-group [type='tel'], .form-group [type='text'], .form-group [type='time'], .form-group [type='url'], .form-group [type='week'], .form-group input:not([type]), .form-group textarea, .input-group [type='color'], .input-group [type='date'], .input-group [type='datetime'], .input-group [type='datetime-local'], .input-group [type='email'], .input-group [type='month'], .input-group [type='number'], .input-group [type='password'], .input-group [type='search'], .input-group [type='tel'], .input-group [type='text'], .input-group [type='time'], .input-group [type='url'], .input-group [type='week'], .input-group input:not([type]), .input-group textarea {
      font-size: clamp(13px, 0.72917rem, 0.9375rem); } }
  .form-group [type='color']::placeholder, .form-group [type='date']::placeholder, .form-group [type='datetime']::placeholder, .form-group [type='datetime-local']::placeholder, .form-group [type='email']::placeholder, .form-group [type='month']::placeholder, .form-group [type='number']::placeholder, .form-group [type='password']::placeholder, .form-group [type='search']::placeholder, .form-group [type='tel']::placeholder, .form-group [type='text']::placeholder, .form-group [type='time']::placeholder, .form-group [type='url']::placeholder, .form-group [type='week']::placeholder, .form-group input:not([type])::placeholder, .form-group textarea::placeholder, .input-group [type='color']::placeholder, .input-group [type='date']::placeholder, .input-group [type='datetime']::placeholder, .input-group [type='datetime-local']::placeholder, .input-group [type='email']::placeholder, .input-group [type='month']::placeholder, .input-group [type='number']::placeholder, .input-group [type='password']::placeholder, .input-group [type='search']::placeholder, .input-group [type='tel']::placeholder, .input-group [type='text']::placeholder, .input-group [type='time']::placeholder, .input-group [type='url']::placeholder, .input-group [type='week']::placeholder, .input-group input:not([type])::placeholder, .input-group textarea::placeholder {
    color: #858585;
    opacity: 1; }

.form-group input[type="password"], .input-group input[type="password"] {
  height: 2.29167rem !important; }

.form-group select, .input-group select {
  height: 2.29167rem;
  width: 100%;
  background-color: transparent;
  border: 1px solid #D6D6D6;
  padding: 0 1.04167rem;
  border-radius: 1.14583rem;
  background-clip: padding-box;
  font-size: clamp(12px, 0.72917rem, 0.9375rem);
  background-image: url("../img/sl-bg-gray.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 0.625rem) 50%;
  appearance: none;
  background-size: 0.72917rem 0.83333rem; }
  @media (max-width: 768px) {
    .form-group select, .input-group select {
      font-size: clamp(13px, 0.72917rem, 0.9375rem); } }

.form-group textarea, .input-group textarea {
  height: 7.39583rem;
  padding: 0.625rem 1.04167rem;
  border-radius: 1.04167rem; }

.form-group .settinglabel, .input-group .settinglabel {
  margin-bottom: 0.41667rem;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 700; }
  .form-group .settinglabel small, .input-group .settinglabel small {
    color: red; }

.frm-btnwrap {
  margin-top: 1.04167rem; }
  .frm-btnwrap .label {
    display: none; }

.frm-btn {
  position: relative;
  display: inline-block;
  margin-left: auto;
  min-width: 5.98958rem; }
  .frm-btn:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.5625rem;
    content: '\f178';
    font-family: "Font Awesome 6 Pro";
    color: #fff;
    pointer-events: none;
    font-weight: 300; }
  .frm-btn input[type="submit"] {
    transition: all 0.3s ease-in-out;
    background-color: #fff;
    border: 0;
    height: 2.29167rem;
    width: 100%;
    cursor: pointer;
    font-size: 0.72917rem;
    text-transform: uppercase;
    font-weight: 500; }

.gap-0 {
  gap: 0rem; }

.gap-4 {
  gap: 0.20833rem; }

.gap-8 {
  gap: 0.41667rem; }

.gap-12 {
  gap: 0.625rem; }

.gap-16 {
  gap: 0.83333rem; }

.gap-20 {
  gap: 1.04167rem; }

.gap-24 {
  gap: 1.25rem; }

.gap-28 {
  gap: 1.45833rem; }

.gap-32 {
  gap: 1.66667rem; }

.gap-36 {
  gap: 1.875rem; }

@media (min-width: 577px) {
  .gap-sm-0 {
    gap: 0rem; }
  .gap-sm-4 {
    gap: 0.20833rem; }
  .gap-sm-8 {
    gap: 0.41667rem; }
  .gap-sm-12 {
    gap: 0.625rem; }
  .gap-sm-16 {
    gap: 0.83333rem; }
  .gap-sm-20 {
    gap: 1.04167rem; }
  .gap-sm-24 {
    gap: 1.25rem; }
  .gap-sm-28 {
    gap: 1.45833rem; }
  .gap-sm-32 {
    gap: 1.66667rem; }
  .gap-sm-36 {
    gap: 1.875rem; } }

@media (min-width: 769px) {
  .gap-md-0 {
    gap: 0rem; }
  .gap-md-4 {
    gap: 0.20833rem; }
  .gap-md-8 {
    gap: 0.41667rem; }
  .gap-md-12 {
    gap: 0.625rem; }
  .gap-md-16 {
    gap: 0.83333rem; }
  .gap-md-20 {
    gap: 1.04167rem; }
  .gap-md-24 {
    gap: 1.25rem; }
  .gap-md-28 {
    gap: 1.45833rem; }
  .gap-md-32 {
    gap: 1.66667rem; }
  .gap-md-36 {
    gap: 1.875rem; } }

@media (min-width: 1025px) {
  .gap-lg-0 {
    gap: 0rem; }
  .gap-lg-4 {
    gap: 0.20833rem; }
  .gap-lg-8 {
    gap: 0.41667rem; }
  .gap-lg-12 {
    gap: 0.625rem; }
  .gap-lg-16 {
    gap: 0.83333rem; }
  .gap-lg-20 {
    gap: 1.04167rem; }
  .gap-lg-24 {
    gap: 1.25rem; }
  .gap-lg-28 {
    gap: 1.45833rem; }
  .gap-lg-32 {
    gap: 1.66667rem; }
  .gap-lg-36 {
    gap: 1.875rem; } }

@media (min-width: 1281px) {
  .gap-xl-0 {
    gap: 0rem; }
  .gap-xl-4 {
    gap: 0.20833rem; }
  .gap-xl-8 {
    gap: 0.41667rem; }
  .gap-xl-12 {
    gap: 0.625rem; }
  .gap-xl-16 {
    gap: 0.83333rem; }
  .gap-xl-20 {
    gap: 1.04167rem; }
  .gap-xl-24 {
    gap: 1.25rem; }
  .gap-xl-28 {
    gap: 1.45833rem; }
  .gap-xl-32 {
    gap: 1.66667rem; }
  .gap-xl-36 {
    gap: 1.875rem; } }

@media (min-width: 1441px) {
  .gap-2xl-0 {
    gap: 0rem; }
  .gap-2xl-4 {
    gap: 0.20833rem; }
  .gap-2xl-8 {
    gap: 0.41667rem; }
  .gap-2xl-12 {
    gap: 0.625rem; }
  .gap-2xl-16 {
    gap: 0.83333rem; }
  .gap-2xl-20 {
    gap: 1.04167rem; }
  .gap-2xl-24 {
    gap: 1.25rem; }
  .gap-2xl-28 {
    gap: 1.45833rem; }
  .gap-2xl-32 {
    gap: 1.66667rem; }
  .gap-2xl-36 {
    gap: 1.875rem; } }

.img-zoom-out {
  overflow: hidden; }
  .img-zoom-out img {
    transition: all 0.3s ease-in-out; }
  @media (hover: hover) and (pointer: fine) {
    .img-zoom-out:hover img {
      transform: scale(1.05); } }

.img-zoom-in {
  overflow: hidden; }
  .img-zoom-in img {
    transition: all 0.3s ease-in-out;
    transform: scale(1.05); }
  @media (hover: hover) and (pointer: fine) {
    .img-zoom-in:hover img {
      transform: scale(1); } }

@media (hover: hover) and (pointer: fine) {
  .link-hover:hover, .footer-link ul li a:hover {
    text-decoration: underline; } }

.ratio-1-1 {
  position: relative;
  padding-top: 100%; }
  .ratio-1-1 > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.ratio-16-9 {
  position: relative;
  padding-top: 56.25%; }
  .ratio-16-9 > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.ratio-16-10 {
  position: relative;
  padding-top: 62.5%; }
  .ratio-16-10 > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.object-cover img {
  height: 100%;
  width: 100%;
  object-fit: cover; }

.object-contain img {
  height: 100%;
  width: 100%;
  object-fit: contain; }

[class*="ratio-"] {
  display: block; }
  [class*="ratio-"].is-contain img {
    object-fit: contain; }

.layout.is-gap-0 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-0 > * + * {
    margin-top: 0.05208rem; }

.layout.is-gap-4 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-4 > * + * {
    margin-top: 0.26042rem; }

.layout.is-gap-8 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-8 > * + * {
    margin-top: 0.46875rem; }

.layout.is-gap-12 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-12 > * + * {
    margin-top: 0.67708rem; }

.layout.is-gap-16 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-16 > * + * {
    margin-top: 0.88542rem; }

.layout.is-gap-20 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-20 > * + * {
    margin-top: 1.09375rem; }

.layout.is-gap-24 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-24 > * + * {
    margin-top: 1.30208rem; }

.layout.is-gap-28 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-28 > * + * {
    margin-top: 1.51042rem; }

.layout.is-gap-32 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-32 > * + * {
    margin-top: 1.71875rem; }

.layout.is-gap-36 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-36 > * + * {
    margin-top: 1.92708rem; }

.layout.is-gap-40 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-40 > * + * {
    margin-top: 2.13542rem; }

.layout.is-gap-44 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-44 > * + * {
    margin-top: 2.34375rem; }

.layout.is-gap-48 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-48 > * + * {
    margin-top: 2.55208rem; }

.layout.is-gap-52 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-52 > * + * {
    margin-top: 2.76042rem; }

.layout.is-gap-56 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-56 > * + * {
    margin-top: 2.96875rem; }

.layout.is-gap-60 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-60 > * + * {
    margin-top: 3.17708rem; }

@media (min-width: 577px) {
  .layout.is-gap-sm-0 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-0 > * + * {
      margin-top: 0rem; }
  .layout.is-gap-sm-4 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-4 > * + * {
      margin-top: 0.20833rem; }
  .layout.is-gap-sm-8 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-8 > * + * {
      margin-top: 0.41667rem; }
  .layout.is-gap-sm-12 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-12 > * + * {
      margin-top: 0.625rem; }
  .layout.is-gap-sm-16 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-16 > * + * {
      margin-top: 0.83333rem; }
  .layout.is-gap-sm-20 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-20 > * + * {
      margin-top: 1.04167rem; }
  .layout.is-gap-sm-24 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-24 > * + * {
      margin-top: 1.25rem; }
  .layout.is-gap-sm-28 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-28 > * + * {
      margin-top: 1.45833rem; }
  .layout.is-gap-sm-32 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-32 > * + * {
      margin-top: 1.66667rem; }
  .layout.is-gap-sm-36 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-36 > * + * {
      margin-top: 1.875rem; }
  .layout.is-gap-sm-40 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-40 > * + * {
      margin-top: 2.08333rem; }
  .layout.is-gap-sm-44 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-44 > * + * {
      margin-top: 2.29167rem; }
  .layout.is-gap-sm-48 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-48 > * + * {
      margin-top: 2.5rem; }
  .layout.is-gap-sm-52 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-52 > * + * {
      margin-top: 2.70833rem; }
  .layout.is-gap-sm-56 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-56 > * + * {
      margin-top: 2.91667rem; }
  .layout.is-gap-sm-60 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-60 > * + * {
      margin-top: 3.125rem; } }

@media (min-width: 769px) {
  .layout.is-gap-md-0 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-0 > * + * {
      margin-top: 0rem; }
  .layout.is-gap-md-4 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-4 > * + * {
      margin-top: 0.20833rem; }
  .layout.is-gap-md-8 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-8 > * + * {
      margin-top: 0.41667rem; }
  .layout.is-gap-md-12 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-12 > * + * {
      margin-top: 0.625rem; }
  .layout.is-gap-md-16 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-16 > * + * {
      margin-top: 0.83333rem; }
  .layout.is-gap-md-20 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-20 > * + * {
      margin-top: 1.04167rem; }
  .layout.is-gap-md-24 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-24 > * + * {
      margin-top: 1.25rem; }
  .layout.is-gap-md-28 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-28 > * + * {
      margin-top: 1.45833rem; }
  .layout.is-gap-md-32 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-32 > * + * {
      margin-top: 1.66667rem; }
  .layout.is-gap-md-36 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-36 > * + * {
      margin-top: 1.875rem; }
  .layout.is-gap-md-40 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-40 > * + * {
      margin-top: 2.08333rem; }
  .layout.is-gap-md-44 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-44 > * + * {
      margin-top: 2.29167rem; }
  .layout.is-gap-md-48 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-48 > * + * {
      margin-top: 2.5rem; }
  .layout.is-gap-md-52 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-52 > * + * {
      margin-top: 2.70833rem; }
  .layout.is-gap-md-56 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-56 > * + * {
      margin-top: 2.91667rem; }
  .layout.is-gap-md-60 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-60 > * + * {
      margin-top: 3.125rem; } }

@media (min-width: 1025px) {
  .layout.is-gap-lg-0 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-0 > * + * {
      margin-top: 0rem; }
  .layout.is-gap-lg-4 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-4 > * + * {
      margin-top: 0.20833rem; }
  .layout.is-gap-lg-8 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-8 > * + * {
      margin-top: 0.41667rem; }
  .layout.is-gap-lg-12 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-12 > * + * {
      margin-top: 0.625rem; }
  .layout.is-gap-lg-16 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-16 > * + * {
      margin-top: 0.83333rem; }
  .layout.is-gap-lg-20 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-20 > * + * {
      margin-top: 1.04167rem; }
  .layout.is-gap-lg-24 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-24 > * + * {
      margin-top: 1.25rem; }
  .layout.is-gap-lg-28 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-28 > * + * {
      margin-top: 1.45833rem; }
  .layout.is-gap-lg-32 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-32 > * + * {
      margin-top: 1.66667rem; }
  .layout.is-gap-lg-36 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-36 > * + * {
      margin-top: 1.875rem; }
  .layout.is-gap-lg-40 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-40 > * + * {
      margin-top: 2.08333rem; }
  .layout.is-gap-lg-44 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-44 > * + * {
      margin-top: 2.29167rem; }
  .layout.is-gap-lg-48 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-48 > * + * {
      margin-top: 2.5rem; }
  .layout.is-gap-lg-52 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-52 > * + * {
      margin-top: 2.70833rem; }
  .layout.is-gap-lg-56 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-56 > * + * {
      margin-top: 2.91667rem; }
  .layout.is-gap-lg-60 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-60 > * + * {
      margin-top: 3.125rem; } }

@media (min-width: 1281px) {
  .layout.is-gap-xl-0 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-0 > * + * {
      margin-top: 0rem; }
  .layout.is-gap-xl-4 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-4 > * + * {
      margin-top: 0.20833rem; }
  .layout.is-gap-xl-8 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-8 > * + * {
      margin-top: 0.41667rem; }
  .layout.is-gap-xl-12 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-12 > * + * {
      margin-top: 0.625rem; }
  .layout.is-gap-xl-16 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-16 > * + * {
      margin-top: 0.83333rem; }
  .layout.is-gap-xl-20 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-20 > * + * {
      margin-top: 1.04167rem; }
  .layout.is-gap-xl-24 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-24 > * + * {
      margin-top: 1.25rem; }
  .layout.is-gap-xl-28 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-28 > * + * {
      margin-top: 1.45833rem; }
  .layout.is-gap-xl-32 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-32 > * + * {
      margin-top: 1.66667rem; }
  .layout.is-gap-xl-36 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-36 > * + * {
      margin-top: 1.875rem; }
  .layout.is-gap-xl-40 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-40 > * + * {
      margin-top: 2.08333rem; }
  .layout.is-gap-xl-44 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-44 > * + * {
      margin-top: 2.29167rem; }
  .layout.is-gap-xl-48 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-48 > * + * {
      margin-top: 2.5rem; }
  .layout.is-gap-xl-52 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-52 > * + * {
      margin-top: 2.70833rem; }
  .layout.is-gap-xl-56 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-56 > * + * {
      margin-top: 2.91667rem; }
  .layout.is-gap-xl-60 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-60 > * + * {
      margin-top: 3.125rem; } }

@media (min-width: 1441px) {
  .layout.is-gap-2xl-0 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-0 > * + * {
      margin-top: 0rem; }
  .layout.is-gap-2xl-4 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-4 > * + * {
      margin-top: 0.20833rem; }
  .layout.is-gap-2xl-8 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-8 > * + * {
      margin-top: 0.41667rem; }
  .layout.is-gap-2xl-12 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-12 > * + * {
      margin-top: 0.625rem; }
  .layout.is-gap-2xl-16 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-16 > * + * {
      margin-top: 0.83333rem; }
  .layout.is-gap-2xl-20 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-20 > * + * {
      margin-top: 1.04167rem; }
  .layout.is-gap-2xl-24 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-24 > * + * {
      margin-top: 1.25rem; }
  .layout.is-gap-2xl-28 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-28 > * + * {
      margin-top: 1.45833rem; }
  .layout.is-gap-2xl-32 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-32 > * + * {
      margin-top: 1.66667rem; }
  .layout.is-gap-2xl-36 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-36 > * + * {
      margin-top: 1.875rem; }
  .layout.is-gap-2xl-40 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-40 > * + * {
      margin-top: 2.08333rem; }
  .layout.is-gap-2xl-44 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-44 > * + * {
      margin-top: 2.29167rem; }
  .layout.is-gap-2xl-48 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-48 > * + * {
      margin-top: 2.5rem; }
  .layout.is-gap-2xl-52 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-52 > * + * {
      margin-top: 2.70833rem; }
  .layout.is-gap-2xl-56 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-56 > * + * {
      margin-top: 2.91667rem; }
  .layout.is-gap-2xl-60 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-60 > * + * {
      margin-top: 3.125rem; } }

.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid #CCE2F1;
  border-right-color: #006CB9;
  animation: l2 1s infinite linear;
  display: inline-block; }

.loader-wrap {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center; }

@keyframes l2 {
  to {
    transform: rotate(1turn); } }

.ajax-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: none;
  z-index: 200;
  background-color: rgba(51, 51, 51, 0.3); }

.text-primary-50 {
  color: #E6F1F9; }

.text-primary-100 {
  color: #CCE2F1; }

.text-primary-200 {
  color: #99C4E3; }

.text-primary-300 {
  color: #66A7D5; }

.text-primary-400 {
  color: #3389C7; }

.text-primary-500 {
  color: #006CB9; }

.text-primary-600 {
  color: #00518B; }

.text-primary-700 {
  color: #00365D; }

.text-primary-800 {
  color: #001B2E; }

.text-primary-900 {
  color: #000B13; }

.text-gray-50 {
  color: #EBEBEB; }

.text-gray-100 {
  color: #D6D6D6; }

.text-gray-200 {
  color: #ADADAD; }

.text-gray-300 {
  color: #858585; }

.text-gray-400 {
  color: #5C5C5C; }

.text-gray-500 {
  color: #333333; }

.text-gray-600 {
  color: #262626; }

.text-gray-700 {
  color: #1A1A1A; }

.text-gray-800 {
  color: #0D0D0D; }

.text-gray-900 {
  color: #050505; }

.text-white {
  color: #fff; }

.background-primary-50 {
  background: #E6F1F9; }

.background-primary-100 {
  background: #CCE2F1; }

.background-primary-200 {
  background: #99C4E3; }

.background-primary-300 {
  background: #66A7D5; }

.background-primary-400 {
  background: #3389C7; }

.background-primary-500 {
  background: #006CB9; }

.background-primary-600 {
  background: #00518B; }

.background-primary-700 {
  background: #00365D; }

.background-primary-800 {
  background: #001B2E; }

.background-primary-900 {
  background: #000B13; }

.background-gray-50 {
  background: #EBEBEB; }

.background-gray-100 {
  background: #D6D6D6; }

.background-gray-200 {
  background: #ADADAD; }

.background-gray-300 {
  background: #858585; }

.background-gray-400 {
  background: #5C5C5C; }

.background-gray-500 {
  background: #333333; }

.background-gray-600 {
  background: #262626; }

.background-gray-700 {
  background: #1A1A1A; }

.background-gray-800 {
  background: #0D0D0D; }

.background-gray-900 {
  background: #050505; }

.background-white {
  background: #fff; }

.box-shadow-1, .dealer-detail-address, .manufacture-standard-slider .item {
  box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.1); }

/* Escape to Parent -------------------------*/
/* Context -------------------------*/
/* Abstract Extend -------------------------*/
/* Forces GPU acceleration of the item, speeding up performance */
/* Cover Background */
/* Flex Center Item */
/* Block Center Item */
/* Reset default style */
/* Text color -------------------------*/
/* Text color opacity -------------------------*/
/* Background color -------------------------*/
/* Background gradient -------------------------*/
/* Background color opacity -------------------------*/
/* Background color -------------------------*/
/* Breakpoint -------------------------*/
/* Pseudo -------------------------*/
/* Center Position -------------------------*/
/* Font size, line height rhythm -------------------------*/
/* Child element fill parent size -------------------------*/
/* Has Divider -------------------------*/
/* Content margins -------------------------*/
/* Border Radius -------------------------*/
/* TRBL -------------------------*/
/* Border -------------------------*/
/* Line Clamp -------------------------*/
/* Responsive Ratio -------------------------*/
/* Image Cover -------------------------*/
/* Image Contain -------------------------*/
/* Box size -------------------------*/
/* Input placeholder -------------------------*/
/* Select background -------------------------*/
/* Circle -------------------------*/
/* Scroll Bar -------------------------*/
/* Hide -------------------------*/
/* Remove -------------------------*/
/* Disable hover on mobile -------------------------*/
/* Calc -------------------------*/
/* Create Flex Row -------------------------*/
/* Override Row -------------------------*/
/* Flex width -------------------------*/
/* Create Flex Gap -------------------------*/
.pages, .modulepager {
  margin-top: 2.08333rem; }
  .pages .pagination, .modulepager .pagination {
    display: flex;
    align-items: center;
    justify-content: center; }
    .pages .pagination li, .modulepager .pagination li {
      list-style: none;
      display: inline-block;
      text-align: center;
      margin-left: 0.41667rem; }
      .pages .pagination li:first-child, .modulepager .pagination li:first-child {
        margin-left: 0; }
      .pages .pagination li a, .pages .pagination li span, .modulepager .pagination li a, .modulepager .pagination li span {
        justify-content: center;
        align-items: center;
        display: flex;
        text-decoration: none;
        width: 2.08333rem;
        height: 2.08333rem;
        font-size: 0.9375rem;
        background-color: #D6D6D6;
        border-radius: 50%;
        background-clip: padding-box;
        transition: all 0.3s ease-in-out; }
      .pages .pagination li.active a, .pages .pagination li.active span, .pages .pagination li:hover a, .pages .pagination li:hover span, .modulepager .pagination li.active a, .modulepager .pagination li.active span, .modulepager .pagination li:hover a, .modulepager .pagination li:hover span {
        background-color: #00365D;
        color: #fff; }

.no-pagination .pages {
  display: none; }

.radius-0 {
  border-radius: 0rem; }

.radius-4 {
  border-radius: 0.20833rem; }

.radius-8 {
  border-radius: 0.41667rem; }

.radius-12 {
  border-radius: 0.625rem; }

.radius-16 {
  border-radius: 0.83333rem; }

.radius-20 {
  border-radius: 1.04167rem; }

.radius-24 {
  border-radius: 1.25rem; }

.radius-28 {
  border-radius: 1.45833rem; }

.radius-32 {
  border-radius: 1.66667rem; }

.radius-36 {
  border-radius: 1.875rem; }

.radius-40 {
  border-radius: 2.08333rem; }

.radius-44 {
  border-radius: 2.29167rem; }

.radius-48 {
  border-radius: 2.5rem; }

@media (min-width: 577px) {
  .radius-sm-0 {
    border-radius: 0rem; }
  .radius-sm-4 {
    border-radius: 0.20833rem; }
  .radius-sm-8 {
    border-radius: 0.41667rem; }
  .radius-sm-12 {
    border-radius: 0.625rem; }
  .radius-sm-16 {
    border-radius: 0.83333rem; }
  .radius-sm-20 {
    border-radius: 1.04167rem; }
  .radius-sm-24 {
    border-radius: 1.25rem; }
  .radius-sm-28 {
    border-radius: 1.45833rem; }
  .radius-sm-32 {
    border-radius: 1.66667rem; }
  .radius-sm-36 {
    border-radius: 1.875rem; }
  .radius-sm-40 {
    border-radius: 2.08333rem; }
  .radius-sm-44 {
    border-radius: 2.29167rem; }
  .radius-sm-48 {
    border-radius: 2.5rem; } }

@media (min-width: 769px) {
  .radius-md-0 {
    border-radius: 0rem; }
  .radius-md-4 {
    border-radius: 0.20833rem; }
  .radius-md-8 {
    border-radius: 0.41667rem; }
  .radius-md-12 {
    border-radius: 0.625rem; }
  .radius-md-16 {
    border-radius: 0.83333rem; }
  .radius-md-20 {
    border-radius: 1.04167rem; }
  .radius-md-24 {
    border-radius: 1.25rem; }
  .radius-md-28 {
    border-radius: 1.45833rem; }
  .radius-md-32 {
    border-radius: 1.66667rem; }
  .radius-md-36 {
    border-radius: 1.875rem; }
  .radius-md-40 {
    border-radius: 2.08333rem; }
  .radius-md-44 {
    border-radius: 2.29167rem; }
  .radius-md-48 {
    border-radius: 2.5rem; } }

@media (min-width: 1025px) {
  .radius-lg-0 {
    border-radius: 0rem; }
  .radius-lg-4 {
    border-radius: 0.20833rem; }
  .radius-lg-8 {
    border-radius: 0.41667rem; }
  .radius-lg-12 {
    border-radius: 0.625rem; }
  .radius-lg-16 {
    border-radius: 0.83333rem; }
  .radius-lg-20 {
    border-radius: 1.04167rem; }
  .radius-lg-24 {
    border-radius: 1.25rem; }
  .radius-lg-28 {
    border-radius: 1.45833rem; }
  .radius-lg-32 {
    border-radius: 1.66667rem; }
  .radius-lg-36 {
    border-radius: 1.875rem; }
  .radius-lg-40 {
    border-radius: 2.08333rem; }
  .radius-lg-44 {
    border-radius: 2.29167rem; }
  .radius-lg-48 {
    border-radius: 2.5rem; } }

@media (min-width: 1281px) {
  .radius-xl-0 {
    border-radius: 0rem; }
  .radius-xl-4 {
    border-radius: 0.20833rem; }
  .radius-xl-8 {
    border-radius: 0.41667rem; }
  .radius-xl-12 {
    border-radius: 0.625rem; }
  .radius-xl-16 {
    border-radius: 0.83333rem; }
  .radius-xl-20 {
    border-radius: 1.04167rem; }
  .radius-xl-24 {
    border-radius: 1.25rem; }
  .radius-xl-28 {
    border-radius: 1.45833rem; }
  .radius-xl-32 {
    border-radius: 1.66667rem; }
  .radius-xl-36 {
    border-radius: 1.875rem; }
  .radius-xl-40 {
    border-radius: 2.08333rem; }
  .radius-xl-44 {
    border-radius: 2.29167rem; }
  .radius-xl-48 {
    border-radius: 2.5rem; } }

@media (min-width: 1441px) {
  .radius-2xl-0 {
    border-radius: 0rem; }
  .radius-2xl-4 {
    border-radius: 0.20833rem; }
  .radius-2xl-8 {
    border-radius: 0.41667rem; }
  .radius-2xl-12 {
    border-radius: 0.625rem; }
  .radius-2xl-16 {
    border-radius: 0.83333rem; }
  .radius-2xl-20 {
    border-radius: 1.04167rem; }
  .radius-2xl-24 {
    border-radius: 1.25rem; }
  .radius-2xl-28 {
    border-radius: 1.45833rem; }
  .radius-2xl-32 {
    border-radius: 1.66667rem; }
  .radius-2xl-36 {
    border-radius: 1.875rem; }
  .radius-2xl-40 {
    border-radius: 2.08333rem; }
  .radius-2xl-44 {
    border-radius: 2.29167rem; }
  .radius-2xl-48 {
    border-radius: 2.5rem; } }

.section-xlarge {
  padding: 5.20833rem 0; }
  @media (max-width: 1024px) {
    .section-xlarge {
      padding: 2.08333rem 0; } }

.section-large {
  padding: 4.16667rem 0; }
  @media (max-width: 1024px) {
    .section-large {
      padding: 2.08333rem 0; } }

.section-medium {
  padding: 3.75rem 0; }
  @media (max-width: 1024px) {
    .section-medium {
      padding: 2.08333rem 0; } }

.section {
  padding: 3.125rem 0; }
  @media (max-width: 1024px) {
    .section {
      padding: 2.08333rem 0; } }

.section-small {
  padding: 2.5rem 0; }
  @media (max-width: 1024px) {
    .section-small {
      padding: 2.08333rem 0; } }

@media (min-width: 1281px) {
  .section-footer-overlay {
    min-height: 45.20833rem;
    margin-bottom: -6.66667rem;
    padding-bottom: 6.66667rem !important; } }

.mt-0 {
  margin-top: 0rem;
  : 0rem; }

.mt-1 {
  margin-top: 0.20833rem;
  : 0.20833rem; }

.mt-2 {
  margin-top: 0.41667rem;
  : 0.41667rem; }

.mt-3 {
  margin-top: 0.625rem;
  : 0.625rem; }

.mt-4 {
  margin-top: 0.83333rem;
  : 0.83333rem; }

.mt-5 {
  margin-top: 1.04167rem;
  : 1.04167rem; }

.mt-6 {
  margin-top: 1.25rem;
  : 1.25rem; }

.mt-7 {
  margin-top: 1.45833rem;
  : 1.45833rem; }

.mt-8 {
  margin-top: 1.66667rem;
  : 1.66667rem; }

.mt-9 {
  margin-top: 1.875rem;
  : 1.875rem; }

.mt-10 {
  margin-top: 2.08333rem;
  : 2.08333rem; }

.mt-11 {
  margin-top: 2.29167rem;
  : 2.29167rem; }

.mt-12 {
  margin-top: 2.5rem;
  : 2.5rem; }

.mt-13 {
  margin-top: 2.70833rem;
  : 2.70833rem; }

.mt-14 {
  margin-top: 2.91667rem;
  : 2.91667rem; }

.mt-15 {
  margin-top: 3.125rem;
  : 3.125rem; }

.mt-16 {
  margin-top: 3.33333rem;
  : 3.33333rem; }

.mt-17 {
  margin-top: 3.54167rem;
  : 3.54167rem; }

.mt-18 {
  margin-top: 3.75rem;
  : 3.75rem; }

.mt-19 {
  margin-top: 3.95833rem;
  : 3.95833rem; }

.mt-20 {
  margin-top: 4.16667rem;
  : 4.16667rem; }

.mr-0 {
  margin-right: 0rem;
  : 0rem; }

.mr-1 {
  margin-right: 0.20833rem;
  : 0.20833rem; }

.mr-2 {
  margin-right: 0.41667rem;
  : 0.41667rem; }

.mr-3 {
  margin-right: 0.625rem;
  : 0.625rem; }

.mr-4 {
  margin-right: 0.83333rem;
  : 0.83333rem; }

.mr-5 {
  margin-right: 1.04167rem;
  : 1.04167rem; }

.mr-6 {
  margin-right: 1.25rem;
  : 1.25rem; }

.mr-7 {
  margin-right: 1.45833rem;
  : 1.45833rem; }

.mr-8 {
  margin-right: 1.66667rem;
  : 1.66667rem; }

.mr-9 {
  margin-right: 1.875rem;
  : 1.875rem; }

.mr-10 {
  margin-right: 2.08333rem;
  : 2.08333rem; }

.mr-11 {
  margin-right: 2.29167rem;
  : 2.29167rem; }

.mr-12 {
  margin-right: 2.5rem;
  : 2.5rem; }

.mr-13 {
  margin-right: 2.70833rem;
  : 2.70833rem; }

.mr-14 {
  margin-right: 2.91667rem;
  : 2.91667rem; }

.mr-15 {
  margin-right: 3.125rem;
  : 3.125rem; }

.mr-16 {
  margin-right: 3.33333rem;
  : 3.33333rem; }

.mr-17 {
  margin-right: 3.54167rem;
  : 3.54167rem; }

.mr-18 {
  margin-right: 3.75rem;
  : 3.75rem; }

.mr-19 {
  margin-right: 3.95833rem;
  : 3.95833rem; }

.mr-20 {
  margin-right: 4.16667rem;
  : 4.16667rem; }

.mb-0 {
  margin-bottom: 0rem;
  : 0rem; }

.mb-1 {
  margin-bottom: 0.20833rem;
  : 0.20833rem; }

.mb-2 {
  margin-bottom: 0.41667rem;
  : 0.41667rem; }

.mb-3 {
  margin-bottom: 0.625rem;
  : 0.625rem; }

.mb-4 {
  margin-bottom: 0.83333rem;
  : 0.83333rem; }

.mb-5 {
  margin-bottom: 1.04167rem;
  : 1.04167rem; }

.mb-6 {
  margin-bottom: 1.25rem;
  : 1.25rem; }

.mb-7 {
  margin-bottom: 1.45833rem;
  : 1.45833rem; }

.mb-8 {
  margin-bottom: 1.66667rem;
  : 1.66667rem; }

.mb-9 {
  margin-bottom: 1.875rem;
  : 1.875rem; }

.mb-10 {
  margin-bottom: 2.08333rem;
  : 2.08333rem; }

.mb-11 {
  margin-bottom: 2.29167rem;
  : 2.29167rem; }

.mb-12 {
  margin-bottom: 2.5rem;
  : 2.5rem; }

.mb-13 {
  margin-bottom: 2.70833rem;
  : 2.70833rem; }

.mb-14 {
  margin-bottom: 2.91667rem;
  : 2.91667rem; }

.mb-15 {
  margin-bottom: 3.125rem;
  : 3.125rem; }

.mb-16 {
  margin-bottom: 3.33333rem;
  : 3.33333rem; }

.mb-17 {
  margin-bottom: 3.54167rem;
  : 3.54167rem; }

.mb-18 {
  margin-bottom: 3.75rem;
  : 3.75rem; }

.mb-19 {
  margin-bottom: 3.95833rem;
  : 3.95833rem; }

.mb-20 {
  margin-bottom: 4.16667rem;
  : 4.16667rem; }

.ml-0 {
  margin-left: 0rem;
  : 0rem; }

.ml-1 {
  margin-left: 0.20833rem;
  : 0.20833rem; }

.ml-2 {
  margin-left: 0.41667rem;
  : 0.41667rem; }

.ml-3 {
  margin-left: 0.625rem;
  : 0.625rem; }

.ml-4 {
  margin-left: 0.83333rem;
  : 0.83333rem; }

.ml-5 {
  margin-left: 1.04167rem;
  : 1.04167rem; }

.ml-6 {
  margin-left: 1.25rem;
  : 1.25rem; }

.ml-7 {
  margin-left: 1.45833rem;
  : 1.45833rem; }

.ml-8 {
  margin-left: 1.66667rem;
  : 1.66667rem; }

.ml-9 {
  margin-left: 1.875rem;
  : 1.875rem; }

.ml-10 {
  margin-left: 2.08333rem;
  : 2.08333rem; }

.ml-11 {
  margin-left: 2.29167rem;
  : 2.29167rem; }

.ml-12 {
  margin-left: 2.5rem;
  : 2.5rem; }

.ml-13 {
  margin-left: 2.70833rem;
  : 2.70833rem; }

.ml-14 {
  margin-left: 2.91667rem;
  : 2.91667rem; }

.ml-15 {
  margin-left: 3.125rem;
  : 3.125rem; }

.ml-16 {
  margin-left: 3.33333rem;
  : 3.33333rem; }

.ml-17 {
  margin-left: 3.54167rem;
  : 3.54167rem; }

.ml-18 {
  margin-left: 3.75rem;
  : 3.75rem; }

.ml-19 {
  margin-left: 3.95833rem;
  : 3.95833rem; }

.ml-20 {
  margin-left: 4.16667rem;
  : 4.16667rem; }

.mx-0 {
  margin-left: 0rem;
  margin-right: 0rem; }

.mx-1 {
  margin-left: 0.20833rem;
  margin-right: 0.20833rem; }

.mx-2 {
  margin-left: 0.41667rem;
  margin-right: 0.41667rem; }

.mx-3 {
  margin-left: 0.625rem;
  margin-right: 0.625rem; }

.mx-4 {
  margin-left: 0.83333rem;
  margin-right: 0.83333rem; }

.mx-5 {
  margin-left: 1.04167rem;
  margin-right: 1.04167rem; }

.mx-6 {
  margin-left: 1.25rem;
  margin-right: 1.25rem; }

.mx-7 {
  margin-left: 1.45833rem;
  margin-right: 1.45833rem; }

.mx-8 {
  margin-left: 1.66667rem;
  margin-right: 1.66667rem; }

.mx-9 {
  margin-left: 1.875rem;
  margin-right: 1.875rem; }

.mx-10 {
  margin-left: 2.08333rem;
  margin-right: 2.08333rem; }

.mx-11 {
  margin-left: 2.29167rem;
  margin-right: 2.29167rem; }

.mx-12 {
  margin-left: 2.5rem;
  margin-right: 2.5rem; }

.mx-13 {
  margin-left: 2.70833rem;
  margin-right: 2.70833rem; }

.mx-14 {
  margin-left: 2.91667rem;
  margin-right: 2.91667rem; }

.mx-15 {
  margin-left: 3.125rem;
  margin-right: 3.125rem; }

.mx-16 {
  margin-left: 3.33333rem;
  margin-right: 3.33333rem; }

.mx-17 {
  margin-left: 3.54167rem;
  margin-right: 3.54167rem; }

.mx-18 {
  margin-left: 3.75rem;
  margin-right: 3.75rem; }

.mx-19 {
  margin-left: 3.95833rem;
  margin-right: 3.95833rem; }

.mx-20 {
  margin-left: 4.16667rem;
  margin-right: 4.16667rem; }

.my-0 {
  margin-top: 0rem;
  margin-bottom: 0rem; }

.my-1 {
  margin-top: 0.20833rem;
  margin-bottom: 0.20833rem; }

.my-2 {
  margin-top: 0.41667rem;
  margin-bottom: 0.41667rem; }

.my-3 {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem; }

.my-4 {
  margin-top: 0.83333rem;
  margin-bottom: 0.83333rem; }

.my-5 {
  margin-top: 1.04167rem;
  margin-bottom: 1.04167rem; }

.my-6 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; }

.my-7 {
  margin-top: 1.45833rem;
  margin-bottom: 1.45833rem; }

.my-8 {
  margin-top: 1.66667rem;
  margin-bottom: 1.66667rem; }

.my-9 {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem; }

.my-10 {
  margin-top: 2.08333rem;
  margin-bottom: 2.08333rem; }

.my-11 {
  margin-top: 2.29167rem;
  margin-bottom: 2.29167rem; }

.my-12 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

.my-13 {
  margin-top: 2.70833rem;
  margin-bottom: 2.70833rem; }

.my-14 {
  margin-top: 2.91667rem;
  margin-bottom: 2.91667rem; }

.my-15 {
  margin-top: 3.125rem;
  margin-bottom: 3.125rem; }

.my-16 {
  margin-top: 3.33333rem;
  margin-bottom: 3.33333rem; }

.my-17 {
  margin-top: 3.54167rem;
  margin-bottom: 3.54167rem; }

.my-18 {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem; }

.my-19 {
  margin-top: 3.95833rem;
  margin-bottom: 3.95833rem; }

.my-20 {
  margin-top: 4.16667rem;
  margin-bottom: 4.16667rem; }

.m-0 {
  margin: 0rem;
  : 0rem; }

.m-1 {
  margin: 0.20833rem;
  : 0.20833rem; }

.m-2 {
  margin: 0.41667rem;
  : 0.41667rem; }

.m-3 {
  margin: 0.625rem;
  : 0.625rem; }

.m-4 {
  margin: 0.83333rem;
  : 0.83333rem; }

.m-5 {
  margin: 1.04167rem;
  : 1.04167rem; }

.m-6 {
  margin: 1.25rem;
  : 1.25rem; }

.m-7 {
  margin: 1.45833rem;
  : 1.45833rem; }

.m-8 {
  margin: 1.66667rem;
  : 1.66667rem; }

.m-9 {
  margin: 1.875rem;
  : 1.875rem; }

.m-10 {
  margin: 2.08333rem;
  : 2.08333rem; }

.m-11 {
  margin: 2.29167rem;
  : 2.29167rem; }

.m-12 {
  margin: 2.5rem;
  : 2.5rem; }

.m-13 {
  margin: 2.70833rem;
  : 2.70833rem; }

.m-14 {
  margin: 2.91667rem;
  : 2.91667rem; }

.m-15 {
  margin: 3.125rem;
  : 3.125rem; }

.m-16 {
  margin: 3.33333rem;
  : 3.33333rem; }

.m-17 {
  margin: 3.54167rem;
  : 3.54167rem; }

.m-18 {
  margin: 3.75rem;
  : 3.75rem; }

.m-19 {
  margin: 3.95833rem;
  : 3.95833rem; }

.m-20 {
  margin: 4.16667rem;
  : 4.16667rem; }

.pt-0 {
  padding-top: 0rem;
  : 0rem; }

.pt-1 {
  padding-top: 0.20833rem;
  : 0.20833rem; }

.pt-2 {
  padding-top: 0.41667rem;
  : 0.41667rem; }

.pt-3 {
  padding-top: 0.625rem;
  : 0.625rem; }

.pt-4 {
  padding-top: 0.83333rem;
  : 0.83333rem; }

.pt-5 {
  padding-top: 1.04167rem;
  : 1.04167rem; }

.pt-6 {
  padding-top: 1.25rem;
  : 1.25rem; }

.pt-7 {
  padding-top: 1.45833rem;
  : 1.45833rem; }

.pt-8 {
  padding-top: 1.66667rem;
  : 1.66667rem; }

.pt-9 {
  padding-top: 1.875rem;
  : 1.875rem; }

.pt-10 {
  padding-top: 2.08333rem;
  : 2.08333rem; }

.pt-11 {
  padding-top: 2.29167rem;
  : 2.29167rem; }

.pt-12 {
  padding-top: 2.5rem;
  : 2.5rem; }

.pt-13 {
  padding-top: 2.70833rem;
  : 2.70833rem; }

.pt-14 {
  padding-top: 2.91667rem;
  : 2.91667rem; }

.pt-15 {
  padding-top: 3.125rem;
  : 3.125rem; }

.pt-16 {
  padding-top: 3.33333rem;
  : 3.33333rem; }

.pt-17 {
  padding-top: 3.54167rem;
  : 3.54167rem; }

.pt-18 {
  padding-top: 3.75rem;
  : 3.75rem; }

.pt-19 {
  padding-top: 3.95833rem;
  : 3.95833rem; }

.pt-20 {
  padding-top: 4.16667rem;
  : 4.16667rem; }

.pr-0 {
  padding-right: 0rem;
  : 0rem; }

.pr-1 {
  padding-right: 0.20833rem;
  : 0.20833rem; }

.pr-2 {
  padding-right: 0.41667rem;
  : 0.41667rem; }

.pr-3 {
  padding-right: 0.625rem;
  : 0.625rem; }

.pr-4 {
  padding-right: 0.83333rem;
  : 0.83333rem; }

.pr-5 {
  padding-right: 1.04167rem;
  : 1.04167rem; }

.pr-6 {
  padding-right: 1.25rem;
  : 1.25rem; }

.pr-7 {
  padding-right: 1.45833rem;
  : 1.45833rem; }

.pr-8 {
  padding-right: 1.66667rem;
  : 1.66667rem; }

.pr-9 {
  padding-right: 1.875rem;
  : 1.875rem; }

.pr-10 {
  padding-right: 2.08333rem;
  : 2.08333rem; }

.pr-11 {
  padding-right: 2.29167rem;
  : 2.29167rem; }

.pr-12 {
  padding-right: 2.5rem;
  : 2.5rem; }

.pr-13 {
  padding-right: 2.70833rem;
  : 2.70833rem; }

.pr-14 {
  padding-right: 2.91667rem;
  : 2.91667rem; }

.pr-15 {
  padding-right: 3.125rem;
  : 3.125rem; }

.pr-16 {
  padding-right: 3.33333rem;
  : 3.33333rem; }

.pr-17 {
  padding-right: 3.54167rem;
  : 3.54167rem; }

.pr-18 {
  padding-right: 3.75rem;
  : 3.75rem; }

.pr-19 {
  padding-right: 3.95833rem;
  : 3.95833rem; }

.pr-20 {
  padding-right: 4.16667rem;
  : 4.16667rem; }

.pb-0 {
  padding-bottom: 0rem;
  : 0rem; }

.pb-1 {
  padding-bottom: 0.20833rem;
  : 0.20833rem; }

.pb-2 {
  padding-bottom: 0.41667rem;
  : 0.41667rem; }

.pb-3 {
  padding-bottom: 0.625rem;
  : 0.625rem; }

.pb-4 {
  padding-bottom: 0.83333rem;
  : 0.83333rem; }

.pb-5 {
  padding-bottom: 1.04167rem;
  : 1.04167rem; }

.pb-6 {
  padding-bottom: 1.25rem;
  : 1.25rem; }

.pb-7 {
  padding-bottom: 1.45833rem;
  : 1.45833rem; }

.pb-8 {
  padding-bottom: 1.66667rem;
  : 1.66667rem; }

.pb-9 {
  padding-bottom: 1.875rem;
  : 1.875rem; }

.pb-10 {
  padding-bottom: 2.08333rem;
  : 2.08333rem; }

.pb-11 {
  padding-bottom: 2.29167rem;
  : 2.29167rem; }

.pb-12 {
  padding-bottom: 2.5rem;
  : 2.5rem; }

.pb-13 {
  padding-bottom: 2.70833rem;
  : 2.70833rem; }

.pb-14 {
  padding-bottom: 2.91667rem;
  : 2.91667rem; }

.pb-15 {
  padding-bottom: 3.125rem;
  : 3.125rem; }

.pb-16 {
  padding-bottom: 3.33333rem;
  : 3.33333rem; }

.pb-17 {
  padding-bottom: 3.54167rem;
  : 3.54167rem; }

.pb-18 {
  padding-bottom: 3.75rem;
  : 3.75rem; }

.pb-19 {
  padding-bottom: 3.95833rem;
  : 3.95833rem; }

.pb-20 {
  padding-bottom: 4.16667rem;
  : 4.16667rem; }

.pl-0 {
  padding-left: 0rem;
  : 0rem; }

.pl-1 {
  padding-left: 0.20833rem;
  : 0.20833rem; }

.pl-2 {
  padding-left: 0.41667rem;
  : 0.41667rem; }

.pl-3 {
  padding-left: 0.625rem;
  : 0.625rem; }

.pl-4 {
  padding-left: 0.83333rem;
  : 0.83333rem; }

.pl-5 {
  padding-left: 1.04167rem;
  : 1.04167rem; }

.pl-6 {
  padding-left: 1.25rem;
  : 1.25rem; }

.pl-7 {
  padding-left: 1.45833rem;
  : 1.45833rem; }

.pl-8 {
  padding-left: 1.66667rem;
  : 1.66667rem; }

.pl-9 {
  padding-left: 1.875rem;
  : 1.875rem; }

.pl-10 {
  padding-left: 2.08333rem;
  : 2.08333rem; }

.pl-11 {
  padding-left: 2.29167rem;
  : 2.29167rem; }

.pl-12 {
  padding-left: 2.5rem;
  : 2.5rem; }

.pl-13 {
  padding-left: 2.70833rem;
  : 2.70833rem; }

.pl-14 {
  padding-left: 2.91667rem;
  : 2.91667rem; }

.pl-15 {
  padding-left: 3.125rem;
  : 3.125rem; }

.pl-16 {
  padding-left: 3.33333rem;
  : 3.33333rem; }

.pl-17 {
  padding-left: 3.54167rem;
  : 3.54167rem; }

.pl-18 {
  padding-left: 3.75rem;
  : 3.75rem; }

.pl-19 {
  padding-left: 3.95833rem;
  : 3.95833rem; }

.pl-20 {
  padding-left: 4.16667rem;
  : 4.16667rem; }

.px-0 {
  padding-left: 0rem;
  padding-right: 0rem; }

.px-1 {
  padding-left: 0.20833rem;
  padding-right: 0.20833rem; }

.px-2 {
  padding-left: 0.41667rem;
  padding-right: 0.41667rem; }

.px-3 {
  padding-left: 0.625rem;
  padding-right: 0.625rem; }

.px-4 {
  padding-left: 0.83333rem;
  padding-right: 0.83333rem; }

.px-5 {
  padding-left: 1.04167rem;
  padding-right: 1.04167rem; }

.px-6 {
  padding-left: 1.25rem;
  padding-right: 1.25rem; }

.px-7 {
  padding-left: 1.45833rem;
  padding-right: 1.45833rem; }

.px-8 {
  padding-left: 1.66667rem;
  padding-right: 1.66667rem; }

.px-9 {
  padding-left: 1.875rem;
  padding-right: 1.875rem; }

.px-10 {
  padding-left: 2.08333rem;
  padding-right: 2.08333rem; }

.px-11 {
  padding-left: 2.29167rem;
  padding-right: 2.29167rem; }

.px-12 {
  padding-left: 2.5rem;
  padding-right: 2.5rem; }

.px-13 {
  padding-left: 2.70833rem;
  padding-right: 2.70833rem; }

.px-14 {
  padding-left: 2.91667rem;
  padding-right: 2.91667rem; }

.px-15 {
  padding-left: 3.125rem;
  padding-right: 3.125rem; }

.px-16 {
  padding-left: 3.33333rem;
  padding-right: 3.33333rem; }

.px-17 {
  padding-left: 3.54167rem;
  padding-right: 3.54167rem; }

.px-18 {
  padding-left: 3.75rem;
  padding-right: 3.75rem; }

.px-19 {
  padding-left: 3.95833rem;
  padding-right: 3.95833rem; }

.px-20 {
  padding-left: 4.16667rem;
  padding-right: 4.16667rem; }

.py-0 {
  padding-top: 0rem;
  padding-bottom: 0rem; }

.py-1 {
  padding-top: 0.20833rem;
  padding-bottom: 0.20833rem; }

.py-2 {
  padding-top: 0.41667rem;
  padding-bottom: 0.41667rem; }

.py-3 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem; }

.py-4 {
  padding-top: 0.83333rem;
  padding-bottom: 0.83333rem; }

.py-5 {
  padding-top: 1.04167rem;
  padding-bottom: 1.04167rem; }

.py-6 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem; }

.py-7 {
  padding-top: 1.45833rem;
  padding-bottom: 1.45833rem; }

.py-8 {
  padding-top: 1.66667rem;
  padding-bottom: 1.66667rem; }

.py-9 {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem; }

.py-10 {
  padding-top: 2.08333rem;
  padding-bottom: 2.08333rem; }

.py-11 {
  padding-top: 2.29167rem;
  padding-bottom: 2.29167rem; }

.py-12 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }

.py-13 {
  padding-top: 2.70833rem;
  padding-bottom: 2.70833rem; }

.py-14 {
  padding-top: 2.91667rem;
  padding-bottom: 2.91667rem; }

.py-15 {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem; }

.py-16 {
  padding-top: 3.33333rem;
  padding-bottom: 3.33333rem; }

.py-17 {
  padding-top: 3.54167rem;
  padding-bottom: 3.54167rem; }

.py-18 {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem; }

.py-19 {
  padding-top: 3.95833rem;
  padding-bottom: 3.95833rem; }

.py-20 {
  padding-top: 4.16667rem;
  padding-bottom: 4.16667rem; }

.p-0 {
  padding: 0rem;
  : 0rem; }

.p-1 {
  padding: 0.20833rem;
  : 0.20833rem; }

.p-2 {
  padding: 0.41667rem;
  : 0.41667rem; }

.p-3 {
  padding: 0.625rem;
  : 0.625rem; }

.p-4 {
  padding: 0.83333rem;
  : 0.83333rem; }

.p-5 {
  padding: 1.04167rem;
  : 1.04167rem; }

.p-6 {
  padding: 1.25rem;
  : 1.25rem; }

.p-7 {
  padding: 1.45833rem;
  : 1.45833rem; }

.p-8 {
  padding: 1.66667rem;
  : 1.66667rem; }

.p-9 {
  padding: 1.875rem;
  : 1.875rem; }

.p-10 {
  padding: 2.08333rem;
  : 2.08333rem; }

.p-11 {
  padding: 2.29167rem;
  : 2.29167rem; }

.p-12 {
  padding: 2.5rem;
  : 2.5rem; }

.p-13 {
  padding: 2.70833rem;
  : 2.70833rem; }

.p-14 {
  padding: 2.91667rem;
  : 2.91667rem; }

.p-15 {
  padding: 3.125rem;
  : 3.125rem; }

.p-16 {
  padding: 3.33333rem;
  : 3.33333rem; }

.p-17 {
  padding: 3.54167rem;
  : 3.54167rem; }

.p-18 {
  padding: 3.75rem;
  : 3.75rem; }

.p-19 {
  padding: 3.95833rem;
  : 3.95833rem; }

.p-20 {
  padding: 4.16667rem;
  : 4.16667rem; }

@media (min-width: 577px) {
  .pt-sm-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-sm-1 {
    padding-top: 0.20833rem;
    : 0.20833rem; }
  .pt-sm-2 {
    padding-top: 0.41667rem;
    : 0.41667rem; }
  .pt-sm-3 {
    padding-top: 0.625rem;
    : 0.625rem; }
  .pt-sm-4 {
    padding-top: 0.83333rem;
    : 0.83333rem; }
  .pt-sm-5 {
    padding-top: 1.04167rem;
    : 1.04167rem; }
  .pt-sm-6 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-sm-7 {
    padding-top: 1.45833rem;
    : 1.45833rem; }
  .pt-sm-8 {
    padding-top: 1.66667rem;
    : 1.66667rem; }
  .pt-sm-9 {
    padding-top: 1.875rem;
    : 1.875rem; }
  .pt-sm-10 {
    padding-top: 2.08333rem;
    : 2.08333rem; }
  .pt-sm-11 {
    padding-top: 2.29167rem;
    : 2.29167rem; }
  .pt-sm-12 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-sm-13 {
    padding-top: 2.70833rem;
    : 2.70833rem; }
  .pt-sm-14 {
    padding-top: 2.91667rem;
    : 2.91667rem; }
  .pt-sm-15 {
    padding-top: 3.125rem;
    : 3.125rem; }
  .pt-sm-16 {
    padding-top: 3.33333rem;
    : 3.33333rem; }
  .pt-sm-17 {
    padding-top: 3.54167rem;
    : 3.54167rem; }
  .pt-sm-18 {
    padding-top: 3.75rem;
    : 3.75rem; }
  .pt-sm-19 {
    padding-top: 3.95833rem;
    : 3.95833rem; }
  .pt-sm-20 {
    padding-top: 4.16667rem;
    : 4.16667rem; }
  .pr-sm-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-sm-1 {
    padding-right: 0.20833rem;
    : 0.20833rem; }
  .pr-sm-2 {
    padding-right: 0.41667rem;
    : 0.41667rem; }
  .pr-sm-3 {
    padding-right: 0.625rem;
    : 0.625rem; }
  .pr-sm-4 {
    padding-right: 0.83333rem;
    : 0.83333rem; }
  .pr-sm-5 {
    padding-right: 1.04167rem;
    : 1.04167rem; }
  .pr-sm-6 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-sm-7 {
    padding-right: 1.45833rem;
    : 1.45833rem; }
  .pr-sm-8 {
    padding-right: 1.66667rem;
    : 1.66667rem; }
  .pr-sm-9 {
    padding-right: 1.875rem;
    : 1.875rem; }
  .pr-sm-10 {
    padding-right: 2.08333rem;
    : 2.08333rem; }
  .pr-sm-11 {
    padding-right: 2.29167rem;
    : 2.29167rem; }
  .pr-sm-12 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-sm-13 {
    padding-right: 2.70833rem;
    : 2.70833rem; }
  .pr-sm-14 {
    padding-right: 2.91667rem;
    : 2.91667rem; }
  .pr-sm-15 {
    padding-right: 3.125rem;
    : 3.125rem; }
  .pr-sm-16 {
    padding-right: 3.33333rem;
    : 3.33333rem; }
  .pr-sm-17 {
    padding-right: 3.54167rem;
    : 3.54167rem; }
  .pr-sm-18 {
    padding-right: 3.75rem;
    : 3.75rem; }
  .pr-sm-19 {
    padding-right: 3.95833rem;
    : 3.95833rem; }
  .pr-sm-20 {
    padding-right: 4.16667rem;
    : 4.16667rem; }
  .pb-sm-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-sm-1 {
    padding-bottom: 0.20833rem;
    : 0.20833rem; }
  .pb-sm-2 {
    padding-bottom: 0.41667rem;
    : 0.41667rem; }
  .pb-sm-3 {
    padding-bottom: 0.625rem;
    : 0.625rem; }
  .pb-sm-4 {
    padding-bottom: 0.83333rem;
    : 0.83333rem; }
  .pb-sm-5 {
    padding-bottom: 1.04167rem;
    : 1.04167rem; }
  .pb-sm-6 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-sm-7 {
    padding-bottom: 1.45833rem;
    : 1.45833rem; }
  .pb-sm-8 {
    padding-bottom: 1.66667rem;
    : 1.66667rem; }
  .pb-sm-9 {
    padding-bottom: 1.875rem;
    : 1.875rem; }
  .pb-sm-10 {
    padding-bottom: 2.08333rem;
    : 2.08333rem; }
  .pb-sm-11 {
    padding-bottom: 2.29167rem;
    : 2.29167rem; }
  .pb-sm-12 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-sm-13 {
    padding-bottom: 2.70833rem;
    : 2.70833rem; }
  .pb-sm-14 {
    padding-bottom: 2.91667rem;
    : 2.91667rem; }
  .pb-sm-15 {
    padding-bottom: 3.125rem;
    : 3.125rem; }
  .pb-sm-16 {
    padding-bottom: 3.33333rem;
    : 3.33333rem; }
  .pb-sm-17 {
    padding-bottom: 3.54167rem;
    : 3.54167rem; }
  .pb-sm-18 {
    padding-bottom: 3.75rem;
    : 3.75rem; }
  .pb-sm-19 {
    padding-bottom: 3.95833rem;
    : 3.95833rem; }
  .pb-sm-20 {
    padding-bottom: 4.16667rem;
    : 4.16667rem; }
  .pl-sm-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-sm-1 {
    padding-left: 0.20833rem;
    : 0.20833rem; }
  .pl-sm-2 {
    padding-left: 0.41667rem;
    : 0.41667rem; }
  .pl-sm-3 {
    padding-left: 0.625rem;
    : 0.625rem; }
  .pl-sm-4 {
    padding-left: 0.83333rem;
    : 0.83333rem; }
  .pl-sm-5 {
    padding-left: 1.04167rem;
    : 1.04167rem; }
  .pl-sm-6 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-sm-7 {
    padding-left: 1.45833rem;
    : 1.45833rem; }
  .pl-sm-8 {
    padding-left: 1.66667rem;
    : 1.66667rem; }
  .pl-sm-9 {
    padding-left: 1.875rem;
    : 1.875rem; }
  .pl-sm-10 {
    padding-left: 2.08333rem;
    : 2.08333rem; }
  .pl-sm-11 {
    padding-left: 2.29167rem;
    : 2.29167rem; }
  .pl-sm-12 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-sm-13 {
    padding-left: 2.70833rem;
    : 2.70833rem; }
  .pl-sm-14 {
    padding-left: 2.91667rem;
    : 2.91667rem; }
  .pl-sm-15 {
    padding-left: 3.125rem;
    : 3.125rem; }
  .pl-sm-16 {
    padding-left: 3.33333rem;
    : 3.33333rem; }
  .pl-sm-17 {
    padding-left: 3.54167rem;
    : 3.54167rem; }
  .pl-sm-18 {
    padding-left: 3.75rem;
    : 3.75rem; }
  .pl-sm-19 {
    padding-left: 3.95833rem;
    : 3.95833rem; }
  .pl-sm-20 {
    padding-left: 4.16667rem;
    : 4.16667rem; }
  .px-sm-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-sm-1 {
    padding-left: 0.20833rem;
    padding-right: 0.20833rem; }
  .px-sm-2 {
    padding-left: 0.41667rem;
    padding-right: 0.41667rem; }
  .px-sm-3 {
    padding-left: 0.625rem;
    padding-right: 0.625rem; }
  .px-sm-4 {
    padding-left: 0.83333rem;
    padding-right: 0.83333rem; }
  .px-sm-5 {
    padding-left: 1.04167rem;
    padding-right: 1.04167rem; }
  .px-sm-6 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-sm-7 {
    padding-left: 1.45833rem;
    padding-right: 1.45833rem; }
  .px-sm-8 {
    padding-left: 1.66667rem;
    padding-right: 1.66667rem; }
  .px-sm-9 {
    padding-left: 1.875rem;
    padding-right: 1.875rem; }
  .px-sm-10 {
    padding-left: 2.08333rem;
    padding-right: 2.08333rem; }
  .px-sm-11 {
    padding-left: 2.29167rem;
    padding-right: 2.29167rem; }
  .px-sm-12 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-sm-13 {
    padding-left: 2.70833rem;
    padding-right: 2.70833rem; }
  .px-sm-14 {
    padding-left: 2.91667rem;
    padding-right: 2.91667rem; }
  .px-sm-15 {
    padding-left: 3.125rem;
    padding-right: 3.125rem; }
  .px-sm-16 {
    padding-left: 3.33333rem;
    padding-right: 3.33333rem; }
  .px-sm-17 {
    padding-left: 3.54167rem;
    padding-right: 3.54167rem; }
  .px-sm-18 {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .px-sm-19 {
    padding-left: 3.95833rem;
    padding-right: 3.95833rem; }
  .px-sm-20 {
    padding-left: 4.16667rem;
    padding-right: 4.16667rem; }
  .py-sm-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-sm-1 {
    padding-top: 0.20833rem;
    padding-bottom: 0.20833rem; }
  .py-sm-2 {
    padding-top: 0.41667rem;
    padding-bottom: 0.41667rem; }
  .py-sm-3 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem; }
  .py-sm-4 {
    padding-top: 0.83333rem;
    padding-bottom: 0.83333rem; }
  .py-sm-5 {
    padding-top: 1.04167rem;
    padding-bottom: 1.04167rem; }
  .py-sm-6 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-sm-7 {
    padding-top: 1.45833rem;
    padding-bottom: 1.45833rem; }
  .py-sm-8 {
    padding-top: 1.66667rem;
    padding-bottom: 1.66667rem; }
  .py-sm-9 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem; }
  .py-sm-10 {
    padding-top: 2.08333rem;
    padding-bottom: 2.08333rem; }
  .py-sm-11 {
    padding-top: 2.29167rem;
    padding-bottom: 2.29167rem; }
  .py-sm-12 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-sm-13 {
    padding-top: 2.70833rem;
    padding-bottom: 2.70833rem; }
  .py-sm-14 {
    padding-top: 2.91667rem;
    padding-bottom: 2.91667rem; }
  .py-sm-15 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem; }
  .py-sm-16 {
    padding-top: 3.33333rem;
    padding-bottom: 3.33333rem; }
  .py-sm-17 {
    padding-top: 3.54167rem;
    padding-bottom: 3.54167rem; }
  .py-sm-18 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .py-sm-19 {
    padding-top: 3.95833rem;
    padding-bottom: 3.95833rem; }
  .py-sm-20 {
    padding-top: 4.16667rem;
    padding-bottom: 4.16667rem; }
  .p-sm-0 {
    padding: 0rem;
    : 0rem; }
  .p-sm-1 {
    padding: 0.20833rem;
    : 0.20833rem; }
  .p-sm-2 {
    padding: 0.41667rem;
    : 0.41667rem; }
  .p-sm-3 {
    padding: 0.625rem;
    : 0.625rem; }
  .p-sm-4 {
    padding: 0.83333rem;
    : 0.83333rem; }
  .p-sm-5 {
    padding: 1.04167rem;
    : 1.04167rem; }
  .p-sm-6 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-sm-7 {
    padding: 1.45833rem;
    : 1.45833rem; }
  .p-sm-8 {
    padding: 1.66667rem;
    : 1.66667rem; }
  .p-sm-9 {
    padding: 1.875rem;
    : 1.875rem; }
  .p-sm-10 {
    padding: 2.08333rem;
    : 2.08333rem; }
  .p-sm-11 {
    padding: 2.29167rem;
    : 2.29167rem; }
  .p-sm-12 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-sm-13 {
    padding: 2.70833rem;
    : 2.70833rem; }
  .p-sm-14 {
    padding: 2.91667rem;
    : 2.91667rem; }
  .p-sm-15 {
    padding: 3.125rem;
    : 3.125rem; }
  .p-sm-16 {
    padding: 3.33333rem;
    : 3.33333rem; }
  .p-sm-17 {
    padding: 3.54167rem;
    : 3.54167rem; }
  .p-sm-18 {
    padding: 3.75rem;
    : 3.75rem; }
  .p-sm-19 {
    padding: 3.95833rem;
    : 3.95833rem; }
  .p-sm-20 {
    padding: 4.16667rem;
    : 4.16667rem; } }

@media (min-width: 577px) {
  .mt-sm-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-sm-1 {
    margin-top: 0.20833rem;
    : 0.20833rem; }
  .mt-sm-2 {
    margin-top: 0.41667rem;
    : 0.41667rem; }
  .mt-sm-3 {
    margin-top: 0.625rem;
    : 0.625rem; }
  .mt-sm-4 {
    margin-top: 0.83333rem;
    : 0.83333rem; }
  .mt-sm-5 {
    margin-top: 1.04167rem;
    : 1.04167rem; }
  .mt-sm-6 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-sm-7 {
    margin-top: 1.45833rem;
    : 1.45833rem; }
  .mt-sm-8 {
    margin-top: 1.66667rem;
    : 1.66667rem; }
  .mt-sm-9 {
    margin-top: 1.875rem;
    : 1.875rem; }
  .mt-sm-10 {
    margin-top: 2.08333rem;
    : 2.08333rem; }
  .mt-sm-11 {
    margin-top: 2.29167rem;
    : 2.29167rem; }
  .mt-sm-12 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-sm-13 {
    margin-top: 2.70833rem;
    : 2.70833rem; }
  .mt-sm-14 {
    margin-top: 2.91667rem;
    : 2.91667rem; }
  .mt-sm-15 {
    margin-top: 3.125rem;
    : 3.125rem; }
  .mt-sm-16 {
    margin-top: 3.33333rem;
    : 3.33333rem; }
  .mt-sm-17 {
    margin-top: 3.54167rem;
    : 3.54167rem; }
  .mt-sm-18 {
    margin-top: 3.75rem;
    : 3.75rem; }
  .mt-sm-19 {
    margin-top: 3.95833rem;
    : 3.95833rem; }
  .mt-sm-20 {
    margin-top: 4.16667rem;
    : 4.16667rem; }
  .mr-sm-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-sm-1 {
    margin-right: 0.20833rem;
    : 0.20833rem; }
  .mr-sm-2 {
    margin-right: 0.41667rem;
    : 0.41667rem; }
  .mr-sm-3 {
    margin-right: 0.625rem;
    : 0.625rem; }
  .mr-sm-4 {
    margin-right: 0.83333rem;
    : 0.83333rem; }
  .mr-sm-5 {
    margin-right: 1.04167rem;
    : 1.04167rem; }
  .mr-sm-6 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-sm-7 {
    margin-right: 1.45833rem;
    : 1.45833rem; }
  .mr-sm-8 {
    margin-right: 1.66667rem;
    : 1.66667rem; }
  .mr-sm-9 {
    margin-right: 1.875rem;
    : 1.875rem; }
  .mr-sm-10 {
    margin-right: 2.08333rem;
    : 2.08333rem; }
  .mr-sm-11 {
    margin-right: 2.29167rem;
    : 2.29167rem; }
  .mr-sm-12 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-sm-13 {
    margin-right: 2.70833rem;
    : 2.70833rem; }
  .mr-sm-14 {
    margin-right: 2.91667rem;
    : 2.91667rem; }
  .mr-sm-15 {
    margin-right: 3.125rem;
    : 3.125rem; }
  .mr-sm-16 {
    margin-right: 3.33333rem;
    : 3.33333rem; }
  .mr-sm-17 {
    margin-right: 3.54167rem;
    : 3.54167rem; }
  .mr-sm-18 {
    margin-right: 3.75rem;
    : 3.75rem; }
  .mr-sm-19 {
    margin-right: 3.95833rem;
    : 3.95833rem; }
  .mr-sm-20 {
    margin-right: 4.16667rem;
    : 4.16667rem; }
  .mb-sm-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-sm-1 {
    margin-bottom: 0.20833rem;
    : 0.20833rem; }
  .mb-sm-2 {
    margin-bottom: 0.41667rem;
    : 0.41667rem; }
  .mb-sm-3 {
    margin-bottom: 0.625rem;
    : 0.625rem; }
  .mb-sm-4 {
    margin-bottom: 0.83333rem;
    : 0.83333rem; }
  .mb-sm-5 {
    margin-bottom: 1.04167rem;
    : 1.04167rem; }
  .mb-sm-6 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-sm-7 {
    margin-bottom: 1.45833rem;
    : 1.45833rem; }
  .mb-sm-8 {
    margin-bottom: 1.66667rem;
    : 1.66667rem; }
  .mb-sm-9 {
    margin-bottom: 1.875rem;
    : 1.875rem; }
  .mb-sm-10 {
    margin-bottom: 2.08333rem;
    : 2.08333rem; }
  .mb-sm-11 {
    margin-bottom: 2.29167rem;
    : 2.29167rem; }
  .mb-sm-12 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-sm-13 {
    margin-bottom: 2.70833rem;
    : 2.70833rem; }
  .mb-sm-14 {
    margin-bottom: 2.91667rem;
    : 2.91667rem; }
  .mb-sm-15 {
    margin-bottom: 3.125rem;
    : 3.125rem; }
  .mb-sm-16 {
    margin-bottom: 3.33333rem;
    : 3.33333rem; }
  .mb-sm-17 {
    margin-bottom: 3.54167rem;
    : 3.54167rem; }
  .mb-sm-18 {
    margin-bottom: 3.75rem;
    : 3.75rem; }
  .mb-sm-19 {
    margin-bottom: 3.95833rem;
    : 3.95833rem; }
  .mb-sm-20 {
    margin-bottom: 4.16667rem;
    : 4.16667rem; }
  .ml-sm-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-sm-1 {
    margin-left: 0.20833rem;
    : 0.20833rem; }
  .ml-sm-2 {
    margin-left: 0.41667rem;
    : 0.41667rem; }
  .ml-sm-3 {
    margin-left: 0.625rem;
    : 0.625rem; }
  .ml-sm-4 {
    margin-left: 0.83333rem;
    : 0.83333rem; }
  .ml-sm-5 {
    margin-left: 1.04167rem;
    : 1.04167rem; }
  .ml-sm-6 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-sm-7 {
    margin-left: 1.45833rem;
    : 1.45833rem; }
  .ml-sm-8 {
    margin-left: 1.66667rem;
    : 1.66667rem; }
  .ml-sm-9 {
    margin-left: 1.875rem;
    : 1.875rem; }
  .ml-sm-10 {
    margin-left: 2.08333rem;
    : 2.08333rem; }
  .ml-sm-11 {
    margin-left: 2.29167rem;
    : 2.29167rem; }
  .ml-sm-12 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-sm-13 {
    margin-left: 2.70833rem;
    : 2.70833rem; }
  .ml-sm-14 {
    margin-left: 2.91667rem;
    : 2.91667rem; }
  .ml-sm-15 {
    margin-left: 3.125rem;
    : 3.125rem; }
  .ml-sm-16 {
    margin-left: 3.33333rem;
    : 3.33333rem; }
  .ml-sm-17 {
    margin-left: 3.54167rem;
    : 3.54167rem; }
  .ml-sm-18 {
    margin-left: 3.75rem;
    : 3.75rem; }
  .ml-sm-19 {
    margin-left: 3.95833rem;
    : 3.95833rem; }
  .ml-sm-20 {
    margin-left: 4.16667rem;
    : 4.16667rem; }
  .mx-sm-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-sm-1 {
    margin-left: 0.20833rem;
    margin-right: 0.20833rem; }
  .mx-sm-2 {
    margin-left: 0.41667rem;
    margin-right: 0.41667rem; }
  .mx-sm-3 {
    margin-left: 0.625rem;
    margin-right: 0.625rem; }
  .mx-sm-4 {
    margin-left: 0.83333rem;
    margin-right: 0.83333rem; }
  .mx-sm-5 {
    margin-left: 1.04167rem;
    margin-right: 1.04167rem; }
  .mx-sm-6 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-sm-7 {
    margin-left: 1.45833rem;
    margin-right: 1.45833rem; }
  .mx-sm-8 {
    margin-left: 1.66667rem;
    margin-right: 1.66667rem; }
  .mx-sm-9 {
    margin-left: 1.875rem;
    margin-right: 1.875rem; }
  .mx-sm-10 {
    margin-left: 2.08333rem;
    margin-right: 2.08333rem; }
  .mx-sm-11 {
    margin-left: 2.29167rem;
    margin-right: 2.29167rem; }
  .mx-sm-12 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-sm-13 {
    margin-left: 2.70833rem;
    margin-right: 2.70833rem; }
  .mx-sm-14 {
    margin-left: 2.91667rem;
    margin-right: 2.91667rem; }
  .mx-sm-15 {
    margin-left: 3.125rem;
    margin-right: 3.125rem; }
  .mx-sm-16 {
    margin-left: 3.33333rem;
    margin-right: 3.33333rem; }
  .mx-sm-17 {
    margin-left: 3.54167rem;
    margin-right: 3.54167rem; }
  .mx-sm-18 {
    margin-left: 3.75rem;
    margin-right: 3.75rem; }
  .mx-sm-19 {
    margin-left: 3.95833rem;
    margin-right: 3.95833rem; }
  .mx-sm-20 {
    margin-left: 4.16667rem;
    margin-right: 4.16667rem; }
  .my-sm-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-sm-1 {
    margin-top: 0.20833rem;
    margin-bottom: 0.20833rem; }
  .my-sm-2 {
    margin-top: 0.41667rem;
    margin-bottom: 0.41667rem; }
  .my-sm-3 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  .my-sm-4 {
    margin-top: 0.83333rem;
    margin-bottom: 0.83333rem; }
  .my-sm-5 {
    margin-top: 1.04167rem;
    margin-bottom: 1.04167rem; }
  .my-sm-6 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-sm-7 {
    margin-top: 1.45833rem;
    margin-bottom: 1.45833rem; }
  .my-sm-8 {
    margin-top: 1.66667rem;
    margin-bottom: 1.66667rem; }
  .my-sm-9 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem; }
  .my-sm-10 {
    margin-top: 2.08333rem;
    margin-bottom: 2.08333rem; }
  .my-sm-11 {
    margin-top: 2.29167rem;
    margin-bottom: 2.29167rem; }
  .my-sm-12 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-sm-13 {
    margin-top: 2.70833rem;
    margin-bottom: 2.70833rem; }
  .my-sm-14 {
    margin-top: 2.91667rem;
    margin-bottom: 2.91667rem; }
  .my-sm-15 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem; }
  .my-sm-16 {
    margin-top: 3.33333rem;
    margin-bottom: 3.33333rem; }
  .my-sm-17 {
    margin-top: 3.54167rem;
    margin-bottom: 3.54167rem; }
  .my-sm-18 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .my-sm-19 {
    margin-top: 3.95833rem;
    margin-bottom: 3.95833rem; }
  .my-sm-20 {
    margin-top: 4.16667rem;
    margin-bottom: 4.16667rem; }
  .m-sm-0 {
    margin: 0rem;
    : 0rem; }
  .m-sm-1 {
    margin: 0.20833rem;
    : 0.20833rem; }
  .m-sm-2 {
    margin: 0.41667rem;
    : 0.41667rem; }
  .m-sm-3 {
    margin: 0.625rem;
    : 0.625rem; }
  .m-sm-4 {
    margin: 0.83333rem;
    : 0.83333rem; }
  .m-sm-5 {
    margin: 1.04167rem;
    : 1.04167rem; }
  .m-sm-6 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-sm-7 {
    margin: 1.45833rem;
    : 1.45833rem; }
  .m-sm-8 {
    margin: 1.66667rem;
    : 1.66667rem; }
  .m-sm-9 {
    margin: 1.875rem;
    : 1.875rem; }
  .m-sm-10 {
    margin: 2.08333rem;
    : 2.08333rem; }
  .m-sm-11 {
    margin: 2.29167rem;
    : 2.29167rem; }
  .m-sm-12 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-sm-13 {
    margin: 2.70833rem;
    : 2.70833rem; }
  .m-sm-14 {
    margin: 2.91667rem;
    : 2.91667rem; }
  .m-sm-15 {
    margin: 3.125rem;
    : 3.125rem; }
  .m-sm-16 {
    margin: 3.33333rem;
    : 3.33333rem; }
  .m-sm-17 {
    margin: 3.54167rem;
    : 3.54167rem; }
  .m-sm-18 {
    margin: 3.75rem;
    : 3.75rem; }
  .m-sm-19 {
    margin: 3.95833rem;
    : 3.95833rem; }
  .m-sm-20 {
    margin: 4.16667rem;
    : 4.16667rem; } }

@media (min-width: 769px) {
  .pt-md-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-md-1 {
    padding-top: 0.20833rem;
    : 0.20833rem; }
  .pt-md-2 {
    padding-top: 0.41667rem;
    : 0.41667rem; }
  .pt-md-3 {
    padding-top: 0.625rem;
    : 0.625rem; }
  .pt-md-4 {
    padding-top: 0.83333rem;
    : 0.83333rem; }
  .pt-md-5 {
    padding-top: 1.04167rem;
    : 1.04167rem; }
  .pt-md-6 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-md-7 {
    padding-top: 1.45833rem;
    : 1.45833rem; }
  .pt-md-8 {
    padding-top: 1.66667rem;
    : 1.66667rem; }
  .pt-md-9 {
    padding-top: 1.875rem;
    : 1.875rem; }
  .pt-md-10 {
    padding-top: 2.08333rem;
    : 2.08333rem; }
  .pt-md-11 {
    padding-top: 2.29167rem;
    : 2.29167rem; }
  .pt-md-12 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-md-13 {
    padding-top: 2.70833rem;
    : 2.70833rem; }
  .pt-md-14 {
    padding-top: 2.91667rem;
    : 2.91667rem; }
  .pt-md-15 {
    padding-top: 3.125rem;
    : 3.125rem; }
  .pt-md-16 {
    padding-top: 3.33333rem;
    : 3.33333rem; }
  .pt-md-17 {
    padding-top: 3.54167rem;
    : 3.54167rem; }
  .pt-md-18 {
    padding-top: 3.75rem;
    : 3.75rem; }
  .pt-md-19 {
    padding-top: 3.95833rem;
    : 3.95833rem; }
  .pt-md-20 {
    padding-top: 4.16667rem;
    : 4.16667rem; }
  .pr-md-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-md-1 {
    padding-right: 0.20833rem;
    : 0.20833rem; }
  .pr-md-2 {
    padding-right: 0.41667rem;
    : 0.41667rem; }
  .pr-md-3 {
    padding-right: 0.625rem;
    : 0.625rem; }
  .pr-md-4 {
    padding-right: 0.83333rem;
    : 0.83333rem; }
  .pr-md-5 {
    padding-right: 1.04167rem;
    : 1.04167rem; }
  .pr-md-6 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-md-7 {
    padding-right: 1.45833rem;
    : 1.45833rem; }
  .pr-md-8 {
    padding-right: 1.66667rem;
    : 1.66667rem; }
  .pr-md-9 {
    padding-right: 1.875rem;
    : 1.875rem; }
  .pr-md-10 {
    padding-right: 2.08333rem;
    : 2.08333rem; }
  .pr-md-11 {
    padding-right: 2.29167rem;
    : 2.29167rem; }
  .pr-md-12 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-md-13 {
    padding-right: 2.70833rem;
    : 2.70833rem; }
  .pr-md-14 {
    padding-right: 2.91667rem;
    : 2.91667rem; }
  .pr-md-15 {
    padding-right: 3.125rem;
    : 3.125rem; }
  .pr-md-16 {
    padding-right: 3.33333rem;
    : 3.33333rem; }
  .pr-md-17 {
    padding-right: 3.54167rem;
    : 3.54167rem; }
  .pr-md-18 {
    padding-right: 3.75rem;
    : 3.75rem; }
  .pr-md-19 {
    padding-right: 3.95833rem;
    : 3.95833rem; }
  .pr-md-20 {
    padding-right: 4.16667rem;
    : 4.16667rem; }
  .pb-md-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-md-1 {
    padding-bottom: 0.20833rem;
    : 0.20833rem; }
  .pb-md-2 {
    padding-bottom: 0.41667rem;
    : 0.41667rem; }
  .pb-md-3 {
    padding-bottom: 0.625rem;
    : 0.625rem; }
  .pb-md-4 {
    padding-bottom: 0.83333rem;
    : 0.83333rem; }
  .pb-md-5 {
    padding-bottom: 1.04167rem;
    : 1.04167rem; }
  .pb-md-6 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-md-7 {
    padding-bottom: 1.45833rem;
    : 1.45833rem; }
  .pb-md-8 {
    padding-bottom: 1.66667rem;
    : 1.66667rem; }
  .pb-md-9 {
    padding-bottom: 1.875rem;
    : 1.875rem; }
  .pb-md-10 {
    padding-bottom: 2.08333rem;
    : 2.08333rem; }
  .pb-md-11 {
    padding-bottom: 2.29167rem;
    : 2.29167rem; }
  .pb-md-12 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-md-13 {
    padding-bottom: 2.70833rem;
    : 2.70833rem; }
  .pb-md-14 {
    padding-bottom: 2.91667rem;
    : 2.91667rem; }
  .pb-md-15 {
    padding-bottom: 3.125rem;
    : 3.125rem; }
  .pb-md-16 {
    padding-bottom: 3.33333rem;
    : 3.33333rem; }
  .pb-md-17 {
    padding-bottom: 3.54167rem;
    : 3.54167rem; }
  .pb-md-18 {
    padding-bottom: 3.75rem;
    : 3.75rem; }
  .pb-md-19 {
    padding-bottom: 3.95833rem;
    : 3.95833rem; }
  .pb-md-20 {
    padding-bottom: 4.16667rem;
    : 4.16667rem; }
  .pl-md-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-md-1 {
    padding-left: 0.20833rem;
    : 0.20833rem; }
  .pl-md-2 {
    padding-left: 0.41667rem;
    : 0.41667rem; }
  .pl-md-3 {
    padding-left: 0.625rem;
    : 0.625rem; }
  .pl-md-4 {
    padding-left: 0.83333rem;
    : 0.83333rem; }
  .pl-md-5 {
    padding-left: 1.04167rem;
    : 1.04167rem; }
  .pl-md-6 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-md-7 {
    padding-left: 1.45833rem;
    : 1.45833rem; }
  .pl-md-8 {
    padding-left: 1.66667rem;
    : 1.66667rem; }
  .pl-md-9 {
    padding-left: 1.875rem;
    : 1.875rem; }
  .pl-md-10 {
    padding-left: 2.08333rem;
    : 2.08333rem; }
  .pl-md-11 {
    padding-left: 2.29167rem;
    : 2.29167rem; }
  .pl-md-12 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-md-13 {
    padding-left: 2.70833rem;
    : 2.70833rem; }
  .pl-md-14 {
    padding-left: 2.91667rem;
    : 2.91667rem; }
  .pl-md-15 {
    padding-left: 3.125rem;
    : 3.125rem; }
  .pl-md-16 {
    padding-left: 3.33333rem;
    : 3.33333rem; }
  .pl-md-17 {
    padding-left: 3.54167rem;
    : 3.54167rem; }
  .pl-md-18 {
    padding-left: 3.75rem;
    : 3.75rem; }
  .pl-md-19 {
    padding-left: 3.95833rem;
    : 3.95833rem; }
  .pl-md-20 {
    padding-left: 4.16667rem;
    : 4.16667rem; }
  .px-md-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-md-1 {
    padding-left: 0.20833rem;
    padding-right: 0.20833rem; }
  .px-md-2 {
    padding-left: 0.41667rem;
    padding-right: 0.41667rem; }
  .px-md-3 {
    padding-left: 0.625rem;
    padding-right: 0.625rem; }
  .px-md-4 {
    padding-left: 0.83333rem;
    padding-right: 0.83333rem; }
  .px-md-5 {
    padding-left: 1.04167rem;
    padding-right: 1.04167rem; }
  .px-md-6 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-md-7 {
    padding-left: 1.45833rem;
    padding-right: 1.45833rem; }
  .px-md-8 {
    padding-left: 1.66667rem;
    padding-right: 1.66667rem; }
  .px-md-9 {
    padding-left: 1.875rem;
    padding-right: 1.875rem; }
  .px-md-10 {
    padding-left: 2.08333rem;
    padding-right: 2.08333rem; }
  .px-md-11 {
    padding-left: 2.29167rem;
    padding-right: 2.29167rem; }
  .px-md-12 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-md-13 {
    padding-left: 2.70833rem;
    padding-right: 2.70833rem; }
  .px-md-14 {
    padding-left: 2.91667rem;
    padding-right: 2.91667rem; }
  .px-md-15 {
    padding-left: 3.125rem;
    padding-right: 3.125rem; }
  .px-md-16 {
    padding-left: 3.33333rem;
    padding-right: 3.33333rem; }
  .px-md-17 {
    padding-left: 3.54167rem;
    padding-right: 3.54167rem; }
  .px-md-18 {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .px-md-19 {
    padding-left: 3.95833rem;
    padding-right: 3.95833rem; }
  .px-md-20 {
    padding-left: 4.16667rem;
    padding-right: 4.16667rem; }
  .py-md-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-md-1 {
    padding-top: 0.20833rem;
    padding-bottom: 0.20833rem; }
  .py-md-2 {
    padding-top: 0.41667rem;
    padding-bottom: 0.41667rem; }
  .py-md-3 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem; }
  .py-md-4 {
    padding-top: 0.83333rem;
    padding-bottom: 0.83333rem; }
  .py-md-5 {
    padding-top: 1.04167rem;
    padding-bottom: 1.04167rem; }
  .py-md-6 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-md-7 {
    padding-top: 1.45833rem;
    padding-bottom: 1.45833rem; }
  .py-md-8 {
    padding-top: 1.66667rem;
    padding-bottom: 1.66667rem; }
  .py-md-9 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem; }
  .py-md-10 {
    padding-top: 2.08333rem;
    padding-bottom: 2.08333rem; }
  .py-md-11 {
    padding-top: 2.29167rem;
    padding-bottom: 2.29167rem; }
  .py-md-12 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-md-13 {
    padding-top: 2.70833rem;
    padding-bottom: 2.70833rem; }
  .py-md-14 {
    padding-top: 2.91667rem;
    padding-bottom: 2.91667rem; }
  .py-md-15 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem; }
  .py-md-16 {
    padding-top: 3.33333rem;
    padding-bottom: 3.33333rem; }
  .py-md-17 {
    padding-top: 3.54167rem;
    padding-bottom: 3.54167rem; }
  .py-md-18 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .py-md-19 {
    padding-top: 3.95833rem;
    padding-bottom: 3.95833rem; }
  .py-md-20 {
    padding-top: 4.16667rem;
    padding-bottom: 4.16667rem; }
  .p-md-0 {
    padding: 0rem;
    : 0rem; }
  .p-md-1 {
    padding: 0.20833rem;
    : 0.20833rem; }
  .p-md-2 {
    padding: 0.41667rem;
    : 0.41667rem; }
  .p-md-3 {
    padding: 0.625rem;
    : 0.625rem; }
  .p-md-4 {
    padding: 0.83333rem;
    : 0.83333rem; }
  .p-md-5 {
    padding: 1.04167rem;
    : 1.04167rem; }
  .p-md-6 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-md-7 {
    padding: 1.45833rem;
    : 1.45833rem; }
  .p-md-8 {
    padding: 1.66667rem;
    : 1.66667rem; }
  .p-md-9 {
    padding: 1.875rem;
    : 1.875rem; }
  .p-md-10 {
    padding: 2.08333rem;
    : 2.08333rem; }
  .p-md-11 {
    padding: 2.29167rem;
    : 2.29167rem; }
  .p-md-12 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-md-13 {
    padding: 2.70833rem;
    : 2.70833rem; }
  .p-md-14 {
    padding: 2.91667rem;
    : 2.91667rem; }
  .p-md-15 {
    padding: 3.125rem;
    : 3.125rem; }
  .p-md-16 {
    padding: 3.33333rem;
    : 3.33333rem; }
  .p-md-17 {
    padding: 3.54167rem;
    : 3.54167rem; }
  .p-md-18 {
    padding: 3.75rem;
    : 3.75rem; }
  .p-md-19 {
    padding: 3.95833rem;
    : 3.95833rem; }
  .p-md-20 {
    padding: 4.16667rem;
    : 4.16667rem; } }

@media (min-width: 769px) {
  .mt-md-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-md-1 {
    margin-top: 0.20833rem;
    : 0.20833rem; }
  .mt-md-2 {
    margin-top: 0.41667rem;
    : 0.41667rem; }
  .mt-md-3 {
    margin-top: 0.625rem;
    : 0.625rem; }
  .mt-md-4 {
    margin-top: 0.83333rem;
    : 0.83333rem; }
  .mt-md-5 {
    margin-top: 1.04167rem;
    : 1.04167rem; }
  .mt-md-6 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-md-7 {
    margin-top: 1.45833rem;
    : 1.45833rem; }
  .mt-md-8 {
    margin-top: 1.66667rem;
    : 1.66667rem; }
  .mt-md-9 {
    margin-top: 1.875rem;
    : 1.875rem; }
  .mt-md-10 {
    margin-top: 2.08333rem;
    : 2.08333rem; }
  .mt-md-11 {
    margin-top: 2.29167rem;
    : 2.29167rem; }
  .mt-md-12 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-md-13 {
    margin-top: 2.70833rem;
    : 2.70833rem; }
  .mt-md-14 {
    margin-top: 2.91667rem;
    : 2.91667rem; }
  .mt-md-15 {
    margin-top: 3.125rem;
    : 3.125rem; }
  .mt-md-16 {
    margin-top: 3.33333rem;
    : 3.33333rem; }
  .mt-md-17 {
    margin-top: 3.54167rem;
    : 3.54167rem; }
  .mt-md-18 {
    margin-top: 3.75rem;
    : 3.75rem; }
  .mt-md-19 {
    margin-top: 3.95833rem;
    : 3.95833rem; }
  .mt-md-20 {
    margin-top: 4.16667rem;
    : 4.16667rem; }
  .mr-md-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-md-1 {
    margin-right: 0.20833rem;
    : 0.20833rem; }
  .mr-md-2 {
    margin-right: 0.41667rem;
    : 0.41667rem; }
  .mr-md-3 {
    margin-right: 0.625rem;
    : 0.625rem; }
  .mr-md-4 {
    margin-right: 0.83333rem;
    : 0.83333rem; }
  .mr-md-5 {
    margin-right: 1.04167rem;
    : 1.04167rem; }
  .mr-md-6 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-md-7 {
    margin-right: 1.45833rem;
    : 1.45833rem; }
  .mr-md-8 {
    margin-right: 1.66667rem;
    : 1.66667rem; }
  .mr-md-9 {
    margin-right: 1.875rem;
    : 1.875rem; }
  .mr-md-10 {
    margin-right: 2.08333rem;
    : 2.08333rem; }
  .mr-md-11 {
    margin-right: 2.29167rem;
    : 2.29167rem; }
  .mr-md-12 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-md-13 {
    margin-right: 2.70833rem;
    : 2.70833rem; }
  .mr-md-14 {
    margin-right: 2.91667rem;
    : 2.91667rem; }
  .mr-md-15 {
    margin-right: 3.125rem;
    : 3.125rem; }
  .mr-md-16 {
    margin-right: 3.33333rem;
    : 3.33333rem; }
  .mr-md-17 {
    margin-right: 3.54167rem;
    : 3.54167rem; }
  .mr-md-18 {
    margin-right: 3.75rem;
    : 3.75rem; }
  .mr-md-19 {
    margin-right: 3.95833rem;
    : 3.95833rem; }
  .mr-md-20 {
    margin-right: 4.16667rem;
    : 4.16667rem; }
  .mb-md-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-md-1 {
    margin-bottom: 0.20833rem;
    : 0.20833rem; }
  .mb-md-2 {
    margin-bottom: 0.41667rem;
    : 0.41667rem; }
  .mb-md-3 {
    margin-bottom: 0.625rem;
    : 0.625rem; }
  .mb-md-4 {
    margin-bottom: 0.83333rem;
    : 0.83333rem; }
  .mb-md-5 {
    margin-bottom: 1.04167rem;
    : 1.04167rem; }
  .mb-md-6 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-md-7 {
    margin-bottom: 1.45833rem;
    : 1.45833rem; }
  .mb-md-8 {
    margin-bottom: 1.66667rem;
    : 1.66667rem; }
  .mb-md-9 {
    margin-bottom: 1.875rem;
    : 1.875rem; }
  .mb-md-10 {
    margin-bottom: 2.08333rem;
    : 2.08333rem; }
  .mb-md-11 {
    margin-bottom: 2.29167rem;
    : 2.29167rem; }
  .mb-md-12 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-md-13 {
    margin-bottom: 2.70833rem;
    : 2.70833rem; }
  .mb-md-14 {
    margin-bottom: 2.91667rem;
    : 2.91667rem; }
  .mb-md-15 {
    margin-bottom: 3.125rem;
    : 3.125rem; }
  .mb-md-16 {
    margin-bottom: 3.33333rem;
    : 3.33333rem; }
  .mb-md-17 {
    margin-bottom: 3.54167rem;
    : 3.54167rem; }
  .mb-md-18 {
    margin-bottom: 3.75rem;
    : 3.75rem; }
  .mb-md-19 {
    margin-bottom: 3.95833rem;
    : 3.95833rem; }
  .mb-md-20 {
    margin-bottom: 4.16667rem;
    : 4.16667rem; }
  .ml-md-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-md-1 {
    margin-left: 0.20833rem;
    : 0.20833rem; }
  .ml-md-2 {
    margin-left: 0.41667rem;
    : 0.41667rem; }
  .ml-md-3 {
    margin-left: 0.625rem;
    : 0.625rem; }
  .ml-md-4 {
    margin-left: 0.83333rem;
    : 0.83333rem; }
  .ml-md-5 {
    margin-left: 1.04167rem;
    : 1.04167rem; }
  .ml-md-6 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-md-7 {
    margin-left: 1.45833rem;
    : 1.45833rem; }
  .ml-md-8 {
    margin-left: 1.66667rem;
    : 1.66667rem; }
  .ml-md-9 {
    margin-left: 1.875rem;
    : 1.875rem; }
  .ml-md-10 {
    margin-left: 2.08333rem;
    : 2.08333rem; }
  .ml-md-11 {
    margin-left: 2.29167rem;
    : 2.29167rem; }
  .ml-md-12 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-md-13 {
    margin-left: 2.70833rem;
    : 2.70833rem; }
  .ml-md-14 {
    margin-left: 2.91667rem;
    : 2.91667rem; }
  .ml-md-15 {
    margin-left: 3.125rem;
    : 3.125rem; }
  .ml-md-16 {
    margin-left: 3.33333rem;
    : 3.33333rem; }
  .ml-md-17 {
    margin-left: 3.54167rem;
    : 3.54167rem; }
  .ml-md-18 {
    margin-left: 3.75rem;
    : 3.75rem; }
  .ml-md-19 {
    margin-left: 3.95833rem;
    : 3.95833rem; }
  .ml-md-20 {
    margin-left: 4.16667rem;
    : 4.16667rem; }
  .mx-md-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-md-1 {
    margin-left: 0.20833rem;
    margin-right: 0.20833rem; }
  .mx-md-2 {
    margin-left: 0.41667rem;
    margin-right: 0.41667rem; }
  .mx-md-3 {
    margin-left: 0.625rem;
    margin-right: 0.625rem; }
  .mx-md-4 {
    margin-left: 0.83333rem;
    margin-right: 0.83333rem; }
  .mx-md-5 {
    margin-left: 1.04167rem;
    margin-right: 1.04167rem; }
  .mx-md-6 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-md-7 {
    margin-left: 1.45833rem;
    margin-right: 1.45833rem; }
  .mx-md-8 {
    margin-left: 1.66667rem;
    margin-right: 1.66667rem; }
  .mx-md-9 {
    margin-left: 1.875rem;
    margin-right: 1.875rem; }
  .mx-md-10 {
    margin-left: 2.08333rem;
    margin-right: 2.08333rem; }
  .mx-md-11 {
    margin-left: 2.29167rem;
    margin-right: 2.29167rem; }
  .mx-md-12 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-md-13 {
    margin-left: 2.70833rem;
    margin-right: 2.70833rem; }
  .mx-md-14 {
    margin-left: 2.91667rem;
    margin-right: 2.91667rem; }
  .mx-md-15 {
    margin-left: 3.125rem;
    margin-right: 3.125rem; }
  .mx-md-16 {
    margin-left: 3.33333rem;
    margin-right: 3.33333rem; }
  .mx-md-17 {
    margin-left: 3.54167rem;
    margin-right: 3.54167rem; }
  .mx-md-18 {
    margin-left: 3.75rem;
    margin-right: 3.75rem; }
  .mx-md-19 {
    margin-left: 3.95833rem;
    margin-right: 3.95833rem; }
  .mx-md-20 {
    margin-left: 4.16667rem;
    margin-right: 4.16667rem; }
  .my-md-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-md-1 {
    margin-top: 0.20833rem;
    margin-bottom: 0.20833rem; }
  .my-md-2 {
    margin-top: 0.41667rem;
    margin-bottom: 0.41667rem; }
  .my-md-3 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  .my-md-4 {
    margin-top: 0.83333rem;
    margin-bottom: 0.83333rem; }
  .my-md-5 {
    margin-top: 1.04167rem;
    margin-bottom: 1.04167rem; }
  .my-md-6 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-md-7 {
    margin-top: 1.45833rem;
    margin-bottom: 1.45833rem; }
  .my-md-8 {
    margin-top: 1.66667rem;
    margin-bottom: 1.66667rem; }
  .my-md-9 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem; }
  .my-md-10 {
    margin-top: 2.08333rem;
    margin-bottom: 2.08333rem; }
  .my-md-11 {
    margin-top: 2.29167rem;
    margin-bottom: 2.29167rem; }
  .my-md-12 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-md-13 {
    margin-top: 2.70833rem;
    margin-bottom: 2.70833rem; }
  .my-md-14 {
    margin-top: 2.91667rem;
    margin-bottom: 2.91667rem; }
  .my-md-15 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem; }
  .my-md-16 {
    margin-top: 3.33333rem;
    margin-bottom: 3.33333rem; }
  .my-md-17 {
    margin-top: 3.54167rem;
    margin-bottom: 3.54167rem; }
  .my-md-18 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .my-md-19 {
    margin-top: 3.95833rem;
    margin-bottom: 3.95833rem; }
  .my-md-20 {
    margin-top: 4.16667rem;
    margin-bottom: 4.16667rem; }
  .m-md-0 {
    margin: 0rem;
    : 0rem; }
  .m-md-1 {
    margin: 0.20833rem;
    : 0.20833rem; }
  .m-md-2 {
    margin: 0.41667rem;
    : 0.41667rem; }
  .m-md-3 {
    margin: 0.625rem;
    : 0.625rem; }
  .m-md-4 {
    margin: 0.83333rem;
    : 0.83333rem; }
  .m-md-5 {
    margin: 1.04167rem;
    : 1.04167rem; }
  .m-md-6 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-md-7 {
    margin: 1.45833rem;
    : 1.45833rem; }
  .m-md-8 {
    margin: 1.66667rem;
    : 1.66667rem; }
  .m-md-9 {
    margin: 1.875rem;
    : 1.875rem; }
  .m-md-10 {
    margin: 2.08333rem;
    : 2.08333rem; }
  .m-md-11 {
    margin: 2.29167rem;
    : 2.29167rem; }
  .m-md-12 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-md-13 {
    margin: 2.70833rem;
    : 2.70833rem; }
  .m-md-14 {
    margin: 2.91667rem;
    : 2.91667rem; }
  .m-md-15 {
    margin: 3.125rem;
    : 3.125rem; }
  .m-md-16 {
    margin: 3.33333rem;
    : 3.33333rem; }
  .m-md-17 {
    margin: 3.54167rem;
    : 3.54167rem; }
  .m-md-18 {
    margin: 3.75rem;
    : 3.75rem; }
  .m-md-19 {
    margin: 3.95833rem;
    : 3.95833rem; }
  .m-md-20 {
    margin: 4.16667rem;
    : 4.16667rem; } }

@media (min-width: 1025px) {
  .pt-lg-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-lg-1 {
    padding-top: 0.20833rem;
    : 0.20833rem; }
  .pt-lg-2 {
    padding-top: 0.41667rem;
    : 0.41667rem; }
  .pt-lg-3 {
    padding-top: 0.625rem;
    : 0.625rem; }
  .pt-lg-4 {
    padding-top: 0.83333rem;
    : 0.83333rem; }
  .pt-lg-5 {
    padding-top: 1.04167rem;
    : 1.04167rem; }
  .pt-lg-6 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-lg-7 {
    padding-top: 1.45833rem;
    : 1.45833rem; }
  .pt-lg-8 {
    padding-top: 1.66667rem;
    : 1.66667rem; }
  .pt-lg-9 {
    padding-top: 1.875rem;
    : 1.875rem; }
  .pt-lg-10 {
    padding-top: 2.08333rem;
    : 2.08333rem; }
  .pt-lg-11 {
    padding-top: 2.29167rem;
    : 2.29167rem; }
  .pt-lg-12 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-lg-13 {
    padding-top: 2.70833rem;
    : 2.70833rem; }
  .pt-lg-14 {
    padding-top: 2.91667rem;
    : 2.91667rem; }
  .pt-lg-15 {
    padding-top: 3.125rem;
    : 3.125rem; }
  .pt-lg-16 {
    padding-top: 3.33333rem;
    : 3.33333rem; }
  .pt-lg-17 {
    padding-top: 3.54167rem;
    : 3.54167rem; }
  .pt-lg-18 {
    padding-top: 3.75rem;
    : 3.75rem; }
  .pt-lg-19 {
    padding-top: 3.95833rem;
    : 3.95833rem; }
  .pt-lg-20 {
    padding-top: 4.16667rem;
    : 4.16667rem; }
  .pr-lg-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-lg-1 {
    padding-right: 0.20833rem;
    : 0.20833rem; }
  .pr-lg-2 {
    padding-right: 0.41667rem;
    : 0.41667rem; }
  .pr-lg-3 {
    padding-right: 0.625rem;
    : 0.625rem; }
  .pr-lg-4 {
    padding-right: 0.83333rem;
    : 0.83333rem; }
  .pr-lg-5 {
    padding-right: 1.04167rem;
    : 1.04167rem; }
  .pr-lg-6 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-lg-7 {
    padding-right: 1.45833rem;
    : 1.45833rem; }
  .pr-lg-8 {
    padding-right: 1.66667rem;
    : 1.66667rem; }
  .pr-lg-9 {
    padding-right: 1.875rem;
    : 1.875rem; }
  .pr-lg-10 {
    padding-right: 2.08333rem;
    : 2.08333rem; }
  .pr-lg-11 {
    padding-right: 2.29167rem;
    : 2.29167rem; }
  .pr-lg-12 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-lg-13 {
    padding-right: 2.70833rem;
    : 2.70833rem; }
  .pr-lg-14 {
    padding-right: 2.91667rem;
    : 2.91667rem; }
  .pr-lg-15 {
    padding-right: 3.125rem;
    : 3.125rem; }
  .pr-lg-16 {
    padding-right: 3.33333rem;
    : 3.33333rem; }
  .pr-lg-17 {
    padding-right: 3.54167rem;
    : 3.54167rem; }
  .pr-lg-18 {
    padding-right: 3.75rem;
    : 3.75rem; }
  .pr-lg-19 {
    padding-right: 3.95833rem;
    : 3.95833rem; }
  .pr-lg-20 {
    padding-right: 4.16667rem;
    : 4.16667rem; }
  .pb-lg-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-lg-1 {
    padding-bottom: 0.20833rem;
    : 0.20833rem; }
  .pb-lg-2 {
    padding-bottom: 0.41667rem;
    : 0.41667rem; }
  .pb-lg-3 {
    padding-bottom: 0.625rem;
    : 0.625rem; }
  .pb-lg-4 {
    padding-bottom: 0.83333rem;
    : 0.83333rem; }
  .pb-lg-5 {
    padding-bottom: 1.04167rem;
    : 1.04167rem; }
  .pb-lg-6 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-lg-7 {
    padding-bottom: 1.45833rem;
    : 1.45833rem; }
  .pb-lg-8 {
    padding-bottom: 1.66667rem;
    : 1.66667rem; }
  .pb-lg-9 {
    padding-bottom: 1.875rem;
    : 1.875rem; }
  .pb-lg-10 {
    padding-bottom: 2.08333rem;
    : 2.08333rem; }
  .pb-lg-11 {
    padding-bottom: 2.29167rem;
    : 2.29167rem; }
  .pb-lg-12 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-lg-13 {
    padding-bottom: 2.70833rem;
    : 2.70833rem; }
  .pb-lg-14 {
    padding-bottom: 2.91667rem;
    : 2.91667rem; }
  .pb-lg-15 {
    padding-bottom: 3.125rem;
    : 3.125rem; }
  .pb-lg-16 {
    padding-bottom: 3.33333rem;
    : 3.33333rem; }
  .pb-lg-17 {
    padding-bottom: 3.54167rem;
    : 3.54167rem; }
  .pb-lg-18 {
    padding-bottom: 3.75rem;
    : 3.75rem; }
  .pb-lg-19 {
    padding-bottom: 3.95833rem;
    : 3.95833rem; }
  .pb-lg-20 {
    padding-bottom: 4.16667rem;
    : 4.16667rem; }
  .pl-lg-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-lg-1 {
    padding-left: 0.20833rem;
    : 0.20833rem; }
  .pl-lg-2 {
    padding-left: 0.41667rem;
    : 0.41667rem; }
  .pl-lg-3 {
    padding-left: 0.625rem;
    : 0.625rem; }
  .pl-lg-4 {
    padding-left: 0.83333rem;
    : 0.83333rem; }
  .pl-lg-5 {
    padding-left: 1.04167rem;
    : 1.04167rem; }
  .pl-lg-6 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-lg-7 {
    padding-left: 1.45833rem;
    : 1.45833rem; }
  .pl-lg-8 {
    padding-left: 1.66667rem;
    : 1.66667rem; }
  .pl-lg-9 {
    padding-left: 1.875rem;
    : 1.875rem; }
  .pl-lg-10 {
    padding-left: 2.08333rem;
    : 2.08333rem; }
  .pl-lg-11 {
    padding-left: 2.29167rem;
    : 2.29167rem; }
  .pl-lg-12 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-lg-13 {
    padding-left: 2.70833rem;
    : 2.70833rem; }
  .pl-lg-14 {
    padding-left: 2.91667rem;
    : 2.91667rem; }
  .pl-lg-15 {
    padding-left: 3.125rem;
    : 3.125rem; }
  .pl-lg-16 {
    padding-left: 3.33333rem;
    : 3.33333rem; }
  .pl-lg-17 {
    padding-left: 3.54167rem;
    : 3.54167rem; }
  .pl-lg-18 {
    padding-left: 3.75rem;
    : 3.75rem; }
  .pl-lg-19 {
    padding-left: 3.95833rem;
    : 3.95833rem; }
  .pl-lg-20 {
    padding-left: 4.16667rem;
    : 4.16667rem; }
  .px-lg-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-lg-1 {
    padding-left: 0.20833rem;
    padding-right: 0.20833rem; }
  .px-lg-2 {
    padding-left: 0.41667rem;
    padding-right: 0.41667rem; }
  .px-lg-3 {
    padding-left: 0.625rem;
    padding-right: 0.625rem; }
  .px-lg-4 {
    padding-left: 0.83333rem;
    padding-right: 0.83333rem; }
  .px-lg-5 {
    padding-left: 1.04167rem;
    padding-right: 1.04167rem; }
  .px-lg-6 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-lg-7 {
    padding-left: 1.45833rem;
    padding-right: 1.45833rem; }
  .px-lg-8 {
    padding-left: 1.66667rem;
    padding-right: 1.66667rem; }
  .px-lg-9 {
    padding-left: 1.875rem;
    padding-right: 1.875rem; }
  .px-lg-10 {
    padding-left: 2.08333rem;
    padding-right: 2.08333rem; }
  .px-lg-11 {
    padding-left: 2.29167rem;
    padding-right: 2.29167rem; }
  .px-lg-12 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-lg-13 {
    padding-left: 2.70833rem;
    padding-right: 2.70833rem; }
  .px-lg-14 {
    padding-left: 2.91667rem;
    padding-right: 2.91667rem; }
  .px-lg-15 {
    padding-left: 3.125rem;
    padding-right: 3.125rem; }
  .px-lg-16 {
    padding-left: 3.33333rem;
    padding-right: 3.33333rem; }
  .px-lg-17 {
    padding-left: 3.54167rem;
    padding-right: 3.54167rem; }
  .px-lg-18 {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .px-lg-19 {
    padding-left: 3.95833rem;
    padding-right: 3.95833rem; }
  .px-lg-20 {
    padding-left: 4.16667rem;
    padding-right: 4.16667rem; }
  .py-lg-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-lg-1 {
    padding-top: 0.20833rem;
    padding-bottom: 0.20833rem; }
  .py-lg-2 {
    padding-top: 0.41667rem;
    padding-bottom: 0.41667rem; }
  .py-lg-3 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem; }
  .py-lg-4 {
    padding-top: 0.83333rem;
    padding-bottom: 0.83333rem; }
  .py-lg-5 {
    padding-top: 1.04167rem;
    padding-bottom: 1.04167rem; }
  .py-lg-6 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-lg-7 {
    padding-top: 1.45833rem;
    padding-bottom: 1.45833rem; }
  .py-lg-8 {
    padding-top: 1.66667rem;
    padding-bottom: 1.66667rem; }
  .py-lg-9 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem; }
  .py-lg-10 {
    padding-top: 2.08333rem;
    padding-bottom: 2.08333rem; }
  .py-lg-11 {
    padding-top: 2.29167rem;
    padding-bottom: 2.29167rem; }
  .py-lg-12 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-lg-13 {
    padding-top: 2.70833rem;
    padding-bottom: 2.70833rem; }
  .py-lg-14 {
    padding-top: 2.91667rem;
    padding-bottom: 2.91667rem; }
  .py-lg-15 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem; }
  .py-lg-16 {
    padding-top: 3.33333rem;
    padding-bottom: 3.33333rem; }
  .py-lg-17 {
    padding-top: 3.54167rem;
    padding-bottom: 3.54167rem; }
  .py-lg-18 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .py-lg-19 {
    padding-top: 3.95833rem;
    padding-bottom: 3.95833rem; }
  .py-lg-20 {
    padding-top: 4.16667rem;
    padding-bottom: 4.16667rem; }
  .p-lg-0 {
    padding: 0rem;
    : 0rem; }
  .p-lg-1 {
    padding: 0.20833rem;
    : 0.20833rem; }
  .p-lg-2 {
    padding: 0.41667rem;
    : 0.41667rem; }
  .p-lg-3 {
    padding: 0.625rem;
    : 0.625rem; }
  .p-lg-4 {
    padding: 0.83333rem;
    : 0.83333rem; }
  .p-lg-5 {
    padding: 1.04167rem;
    : 1.04167rem; }
  .p-lg-6 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-lg-7 {
    padding: 1.45833rem;
    : 1.45833rem; }
  .p-lg-8 {
    padding: 1.66667rem;
    : 1.66667rem; }
  .p-lg-9 {
    padding: 1.875rem;
    : 1.875rem; }
  .p-lg-10 {
    padding: 2.08333rem;
    : 2.08333rem; }
  .p-lg-11 {
    padding: 2.29167rem;
    : 2.29167rem; }
  .p-lg-12 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-lg-13 {
    padding: 2.70833rem;
    : 2.70833rem; }
  .p-lg-14 {
    padding: 2.91667rem;
    : 2.91667rem; }
  .p-lg-15 {
    padding: 3.125rem;
    : 3.125rem; }
  .p-lg-16 {
    padding: 3.33333rem;
    : 3.33333rem; }
  .p-lg-17 {
    padding: 3.54167rem;
    : 3.54167rem; }
  .p-lg-18 {
    padding: 3.75rem;
    : 3.75rem; }
  .p-lg-19 {
    padding: 3.95833rem;
    : 3.95833rem; }
  .p-lg-20 {
    padding: 4.16667rem;
    : 4.16667rem; } }

@media (min-width: 1025px) {
  .mt-lg-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-lg-1 {
    margin-top: 0.20833rem;
    : 0.20833rem; }
  .mt-lg-2 {
    margin-top: 0.41667rem;
    : 0.41667rem; }
  .mt-lg-3 {
    margin-top: 0.625rem;
    : 0.625rem; }
  .mt-lg-4 {
    margin-top: 0.83333rem;
    : 0.83333rem; }
  .mt-lg-5 {
    margin-top: 1.04167rem;
    : 1.04167rem; }
  .mt-lg-6 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-lg-7 {
    margin-top: 1.45833rem;
    : 1.45833rem; }
  .mt-lg-8 {
    margin-top: 1.66667rem;
    : 1.66667rem; }
  .mt-lg-9 {
    margin-top: 1.875rem;
    : 1.875rem; }
  .mt-lg-10 {
    margin-top: 2.08333rem;
    : 2.08333rem; }
  .mt-lg-11 {
    margin-top: 2.29167rem;
    : 2.29167rem; }
  .mt-lg-12 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-lg-13 {
    margin-top: 2.70833rem;
    : 2.70833rem; }
  .mt-lg-14 {
    margin-top: 2.91667rem;
    : 2.91667rem; }
  .mt-lg-15 {
    margin-top: 3.125rem;
    : 3.125rem; }
  .mt-lg-16 {
    margin-top: 3.33333rem;
    : 3.33333rem; }
  .mt-lg-17 {
    margin-top: 3.54167rem;
    : 3.54167rem; }
  .mt-lg-18 {
    margin-top: 3.75rem;
    : 3.75rem; }
  .mt-lg-19 {
    margin-top: 3.95833rem;
    : 3.95833rem; }
  .mt-lg-20 {
    margin-top: 4.16667rem;
    : 4.16667rem; }
  .mr-lg-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-lg-1 {
    margin-right: 0.20833rem;
    : 0.20833rem; }
  .mr-lg-2 {
    margin-right: 0.41667rem;
    : 0.41667rem; }
  .mr-lg-3 {
    margin-right: 0.625rem;
    : 0.625rem; }
  .mr-lg-4 {
    margin-right: 0.83333rem;
    : 0.83333rem; }
  .mr-lg-5 {
    margin-right: 1.04167rem;
    : 1.04167rem; }
  .mr-lg-6 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-lg-7 {
    margin-right: 1.45833rem;
    : 1.45833rem; }
  .mr-lg-8 {
    margin-right: 1.66667rem;
    : 1.66667rem; }
  .mr-lg-9 {
    margin-right: 1.875rem;
    : 1.875rem; }
  .mr-lg-10 {
    margin-right: 2.08333rem;
    : 2.08333rem; }
  .mr-lg-11 {
    margin-right: 2.29167rem;
    : 2.29167rem; }
  .mr-lg-12 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-lg-13 {
    margin-right: 2.70833rem;
    : 2.70833rem; }
  .mr-lg-14 {
    margin-right: 2.91667rem;
    : 2.91667rem; }
  .mr-lg-15 {
    margin-right: 3.125rem;
    : 3.125rem; }
  .mr-lg-16 {
    margin-right: 3.33333rem;
    : 3.33333rem; }
  .mr-lg-17 {
    margin-right: 3.54167rem;
    : 3.54167rem; }
  .mr-lg-18 {
    margin-right: 3.75rem;
    : 3.75rem; }
  .mr-lg-19 {
    margin-right: 3.95833rem;
    : 3.95833rem; }
  .mr-lg-20 {
    margin-right: 4.16667rem;
    : 4.16667rem; }
  .mb-lg-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-lg-1 {
    margin-bottom: 0.20833rem;
    : 0.20833rem; }
  .mb-lg-2 {
    margin-bottom: 0.41667rem;
    : 0.41667rem; }
  .mb-lg-3 {
    margin-bottom: 0.625rem;
    : 0.625rem; }
  .mb-lg-4 {
    margin-bottom: 0.83333rem;
    : 0.83333rem; }
  .mb-lg-5 {
    margin-bottom: 1.04167rem;
    : 1.04167rem; }
  .mb-lg-6 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-lg-7 {
    margin-bottom: 1.45833rem;
    : 1.45833rem; }
  .mb-lg-8 {
    margin-bottom: 1.66667rem;
    : 1.66667rem; }
  .mb-lg-9 {
    margin-bottom: 1.875rem;
    : 1.875rem; }
  .mb-lg-10 {
    margin-bottom: 2.08333rem;
    : 2.08333rem; }
  .mb-lg-11 {
    margin-bottom: 2.29167rem;
    : 2.29167rem; }
  .mb-lg-12 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-lg-13 {
    margin-bottom: 2.70833rem;
    : 2.70833rem; }
  .mb-lg-14 {
    margin-bottom: 2.91667rem;
    : 2.91667rem; }
  .mb-lg-15 {
    margin-bottom: 3.125rem;
    : 3.125rem; }
  .mb-lg-16 {
    margin-bottom: 3.33333rem;
    : 3.33333rem; }
  .mb-lg-17 {
    margin-bottom: 3.54167rem;
    : 3.54167rem; }
  .mb-lg-18 {
    margin-bottom: 3.75rem;
    : 3.75rem; }
  .mb-lg-19 {
    margin-bottom: 3.95833rem;
    : 3.95833rem; }
  .mb-lg-20 {
    margin-bottom: 4.16667rem;
    : 4.16667rem; }
  .ml-lg-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-lg-1 {
    margin-left: 0.20833rem;
    : 0.20833rem; }
  .ml-lg-2 {
    margin-left: 0.41667rem;
    : 0.41667rem; }
  .ml-lg-3 {
    margin-left: 0.625rem;
    : 0.625rem; }
  .ml-lg-4 {
    margin-left: 0.83333rem;
    : 0.83333rem; }
  .ml-lg-5 {
    margin-left: 1.04167rem;
    : 1.04167rem; }
  .ml-lg-6 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-lg-7 {
    margin-left: 1.45833rem;
    : 1.45833rem; }
  .ml-lg-8 {
    margin-left: 1.66667rem;
    : 1.66667rem; }
  .ml-lg-9 {
    margin-left: 1.875rem;
    : 1.875rem; }
  .ml-lg-10 {
    margin-left: 2.08333rem;
    : 2.08333rem; }
  .ml-lg-11 {
    margin-left: 2.29167rem;
    : 2.29167rem; }
  .ml-lg-12 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-lg-13 {
    margin-left: 2.70833rem;
    : 2.70833rem; }
  .ml-lg-14 {
    margin-left: 2.91667rem;
    : 2.91667rem; }
  .ml-lg-15 {
    margin-left: 3.125rem;
    : 3.125rem; }
  .ml-lg-16 {
    margin-left: 3.33333rem;
    : 3.33333rem; }
  .ml-lg-17 {
    margin-left: 3.54167rem;
    : 3.54167rem; }
  .ml-lg-18 {
    margin-left: 3.75rem;
    : 3.75rem; }
  .ml-lg-19 {
    margin-left: 3.95833rem;
    : 3.95833rem; }
  .ml-lg-20 {
    margin-left: 4.16667rem;
    : 4.16667rem; }
  .mx-lg-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-lg-1 {
    margin-left: 0.20833rem;
    margin-right: 0.20833rem; }
  .mx-lg-2 {
    margin-left: 0.41667rem;
    margin-right: 0.41667rem; }
  .mx-lg-3 {
    margin-left: 0.625rem;
    margin-right: 0.625rem; }
  .mx-lg-4 {
    margin-left: 0.83333rem;
    margin-right: 0.83333rem; }
  .mx-lg-5 {
    margin-left: 1.04167rem;
    margin-right: 1.04167rem; }
  .mx-lg-6 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-lg-7 {
    margin-left: 1.45833rem;
    margin-right: 1.45833rem; }
  .mx-lg-8 {
    margin-left: 1.66667rem;
    margin-right: 1.66667rem; }
  .mx-lg-9 {
    margin-left: 1.875rem;
    margin-right: 1.875rem; }
  .mx-lg-10 {
    margin-left: 2.08333rem;
    margin-right: 2.08333rem; }
  .mx-lg-11 {
    margin-left: 2.29167rem;
    margin-right: 2.29167rem; }
  .mx-lg-12 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-lg-13 {
    margin-left: 2.70833rem;
    margin-right: 2.70833rem; }
  .mx-lg-14 {
    margin-left: 2.91667rem;
    margin-right: 2.91667rem; }
  .mx-lg-15 {
    margin-left: 3.125rem;
    margin-right: 3.125rem; }
  .mx-lg-16 {
    margin-left: 3.33333rem;
    margin-right: 3.33333rem; }
  .mx-lg-17 {
    margin-left: 3.54167rem;
    margin-right: 3.54167rem; }
  .mx-lg-18 {
    margin-left: 3.75rem;
    margin-right: 3.75rem; }
  .mx-lg-19 {
    margin-left: 3.95833rem;
    margin-right: 3.95833rem; }
  .mx-lg-20 {
    margin-left: 4.16667rem;
    margin-right: 4.16667rem; }
  .my-lg-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-lg-1 {
    margin-top: 0.20833rem;
    margin-bottom: 0.20833rem; }
  .my-lg-2 {
    margin-top: 0.41667rem;
    margin-bottom: 0.41667rem; }
  .my-lg-3 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  .my-lg-4 {
    margin-top: 0.83333rem;
    margin-bottom: 0.83333rem; }
  .my-lg-5 {
    margin-top: 1.04167rem;
    margin-bottom: 1.04167rem; }
  .my-lg-6 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-lg-7 {
    margin-top: 1.45833rem;
    margin-bottom: 1.45833rem; }
  .my-lg-8 {
    margin-top: 1.66667rem;
    margin-bottom: 1.66667rem; }
  .my-lg-9 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem; }
  .my-lg-10 {
    margin-top: 2.08333rem;
    margin-bottom: 2.08333rem; }
  .my-lg-11 {
    margin-top: 2.29167rem;
    margin-bottom: 2.29167rem; }
  .my-lg-12 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-lg-13 {
    margin-top: 2.70833rem;
    margin-bottom: 2.70833rem; }
  .my-lg-14 {
    margin-top: 2.91667rem;
    margin-bottom: 2.91667rem; }
  .my-lg-15 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem; }
  .my-lg-16 {
    margin-top: 3.33333rem;
    margin-bottom: 3.33333rem; }
  .my-lg-17 {
    margin-top: 3.54167rem;
    margin-bottom: 3.54167rem; }
  .my-lg-18 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .my-lg-19 {
    margin-top: 3.95833rem;
    margin-bottom: 3.95833rem; }
  .my-lg-20 {
    margin-top: 4.16667rem;
    margin-bottom: 4.16667rem; }
  .m-lg-0 {
    margin: 0rem;
    : 0rem; }
  .m-lg-1 {
    margin: 0.20833rem;
    : 0.20833rem; }
  .m-lg-2 {
    margin: 0.41667rem;
    : 0.41667rem; }
  .m-lg-3 {
    margin: 0.625rem;
    : 0.625rem; }
  .m-lg-4 {
    margin: 0.83333rem;
    : 0.83333rem; }
  .m-lg-5 {
    margin: 1.04167rem;
    : 1.04167rem; }
  .m-lg-6 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-lg-7 {
    margin: 1.45833rem;
    : 1.45833rem; }
  .m-lg-8 {
    margin: 1.66667rem;
    : 1.66667rem; }
  .m-lg-9 {
    margin: 1.875rem;
    : 1.875rem; }
  .m-lg-10 {
    margin: 2.08333rem;
    : 2.08333rem; }
  .m-lg-11 {
    margin: 2.29167rem;
    : 2.29167rem; }
  .m-lg-12 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-lg-13 {
    margin: 2.70833rem;
    : 2.70833rem; }
  .m-lg-14 {
    margin: 2.91667rem;
    : 2.91667rem; }
  .m-lg-15 {
    margin: 3.125rem;
    : 3.125rem; }
  .m-lg-16 {
    margin: 3.33333rem;
    : 3.33333rem; }
  .m-lg-17 {
    margin: 3.54167rem;
    : 3.54167rem; }
  .m-lg-18 {
    margin: 3.75rem;
    : 3.75rem; }
  .m-lg-19 {
    margin: 3.95833rem;
    : 3.95833rem; }
  .m-lg-20 {
    margin: 4.16667rem;
    : 4.16667rem; } }

@media (min-width: 1281px) {
  .pt-xl-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-xl-1 {
    padding-top: 0.20833rem;
    : 0.20833rem; }
  .pt-xl-2 {
    padding-top: 0.41667rem;
    : 0.41667rem; }
  .pt-xl-3 {
    padding-top: 0.625rem;
    : 0.625rem; }
  .pt-xl-4 {
    padding-top: 0.83333rem;
    : 0.83333rem; }
  .pt-xl-5 {
    padding-top: 1.04167rem;
    : 1.04167rem; }
  .pt-xl-6 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-xl-7 {
    padding-top: 1.45833rem;
    : 1.45833rem; }
  .pt-xl-8 {
    padding-top: 1.66667rem;
    : 1.66667rem; }
  .pt-xl-9 {
    padding-top: 1.875rem;
    : 1.875rem; }
  .pt-xl-10 {
    padding-top: 2.08333rem;
    : 2.08333rem; }
  .pt-xl-11 {
    padding-top: 2.29167rem;
    : 2.29167rem; }
  .pt-xl-12 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-xl-13 {
    padding-top: 2.70833rem;
    : 2.70833rem; }
  .pt-xl-14 {
    padding-top: 2.91667rem;
    : 2.91667rem; }
  .pt-xl-15 {
    padding-top: 3.125rem;
    : 3.125rem; }
  .pt-xl-16 {
    padding-top: 3.33333rem;
    : 3.33333rem; }
  .pt-xl-17 {
    padding-top: 3.54167rem;
    : 3.54167rem; }
  .pt-xl-18 {
    padding-top: 3.75rem;
    : 3.75rem; }
  .pt-xl-19 {
    padding-top: 3.95833rem;
    : 3.95833rem; }
  .pt-xl-20 {
    padding-top: 4.16667rem;
    : 4.16667rem; }
  .pr-xl-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-xl-1 {
    padding-right: 0.20833rem;
    : 0.20833rem; }
  .pr-xl-2 {
    padding-right: 0.41667rem;
    : 0.41667rem; }
  .pr-xl-3 {
    padding-right: 0.625rem;
    : 0.625rem; }
  .pr-xl-4 {
    padding-right: 0.83333rem;
    : 0.83333rem; }
  .pr-xl-5 {
    padding-right: 1.04167rem;
    : 1.04167rem; }
  .pr-xl-6 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-xl-7 {
    padding-right: 1.45833rem;
    : 1.45833rem; }
  .pr-xl-8 {
    padding-right: 1.66667rem;
    : 1.66667rem; }
  .pr-xl-9 {
    padding-right: 1.875rem;
    : 1.875rem; }
  .pr-xl-10 {
    padding-right: 2.08333rem;
    : 2.08333rem; }
  .pr-xl-11 {
    padding-right: 2.29167rem;
    : 2.29167rem; }
  .pr-xl-12 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-xl-13 {
    padding-right: 2.70833rem;
    : 2.70833rem; }
  .pr-xl-14 {
    padding-right: 2.91667rem;
    : 2.91667rem; }
  .pr-xl-15 {
    padding-right: 3.125rem;
    : 3.125rem; }
  .pr-xl-16 {
    padding-right: 3.33333rem;
    : 3.33333rem; }
  .pr-xl-17 {
    padding-right: 3.54167rem;
    : 3.54167rem; }
  .pr-xl-18 {
    padding-right: 3.75rem;
    : 3.75rem; }
  .pr-xl-19 {
    padding-right: 3.95833rem;
    : 3.95833rem; }
  .pr-xl-20 {
    padding-right: 4.16667rem;
    : 4.16667rem; }
  .pb-xl-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-xl-1 {
    padding-bottom: 0.20833rem;
    : 0.20833rem; }
  .pb-xl-2 {
    padding-bottom: 0.41667rem;
    : 0.41667rem; }
  .pb-xl-3 {
    padding-bottom: 0.625rem;
    : 0.625rem; }
  .pb-xl-4 {
    padding-bottom: 0.83333rem;
    : 0.83333rem; }
  .pb-xl-5 {
    padding-bottom: 1.04167rem;
    : 1.04167rem; }
  .pb-xl-6 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-xl-7 {
    padding-bottom: 1.45833rem;
    : 1.45833rem; }
  .pb-xl-8 {
    padding-bottom: 1.66667rem;
    : 1.66667rem; }
  .pb-xl-9 {
    padding-bottom: 1.875rem;
    : 1.875rem; }
  .pb-xl-10 {
    padding-bottom: 2.08333rem;
    : 2.08333rem; }
  .pb-xl-11 {
    padding-bottom: 2.29167rem;
    : 2.29167rem; }
  .pb-xl-12 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-xl-13 {
    padding-bottom: 2.70833rem;
    : 2.70833rem; }
  .pb-xl-14 {
    padding-bottom: 2.91667rem;
    : 2.91667rem; }
  .pb-xl-15 {
    padding-bottom: 3.125rem;
    : 3.125rem; }
  .pb-xl-16 {
    padding-bottom: 3.33333rem;
    : 3.33333rem; }
  .pb-xl-17 {
    padding-bottom: 3.54167rem;
    : 3.54167rem; }
  .pb-xl-18 {
    padding-bottom: 3.75rem;
    : 3.75rem; }
  .pb-xl-19 {
    padding-bottom: 3.95833rem;
    : 3.95833rem; }
  .pb-xl-20 {
    padding-bottom: 4.16667rem;
    : 4.16667rem; }
  .pl-xl-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-xl-1 {
    padding-left: 0.20833rem;
    : 0.20833rem; }
  .pl-xl-2 {
    padding-left: 0.41667rem;
    : 0.41667rem; }
  .pl-xl-3 {
    padding-left: 0.625rem;
    : 0.625rem; }
  .pl-xl-4 {
    padding-left: 0.83333rem;
    : 0.83333rem; }
  .pl-xl-5 {
    padding-left: 1.04167rem;
    : 1.04167rem; }
  .pl-xl-6 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-xl-7 {
    padding-left: 1.45833rem;
    : 1.45833rem; }
  .pl-xl-8 {
    padding-left: 1.66667rem;
    : 1.66667rem; }
  .pl-xl-9 {
    padding-left: 1.875rem;
    : 1.875rem; }
  .pl-xl-10 {
    padding-left: 2.08333rem;
    : 2.08333rem; }
  .pl-xl-11 {
    padding-left: 2.29167rem;
    : 2.29167rem; }
  .pl-xl-12 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-xl-13 {
    padding-left: 2.70833rem;
    : 2.70833rem; }
  .pl-xl-14 {
    padding-left: 2.91667rem;
    : 2.91667rem; }
  .pl-xl-15 {
    padding-left: 3.125rem;
    : 3.125rem; }
  .pl-xl-16 {
    padding-left: 3.33333rem;
    : 3.33333rem; }
  .pl-xl-17 {
    padding-left: 3.54167rem;
    : 3.54167rem; }
  .pl-xl-18 {
    padding-left: 3.75rem;
    : 3.75rem; }
  .pl-xl-19 {
    padding-left: 3.95833rem;
    : 3.95833rem; }
  .pl-xl-20 {
    padding-left: 4.16667rem;
    : 4.16667rem; }
  .px-xl-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-xl-1 {
    padding-left: 0.20833rem;
    padding-right: 0.20833rem; }
  .px-xl-2 {
    padding-left: 0.41667rem;
    padding-right: 0.41667rem; }
  .px-xl-3 {
    padding-left: 0.625rem;
    padding-right: 0.625rem; }
  .px-xl-4 {
    padding-left: 0.83333rem;
    padding-right: 0.83333rem; }
  .px-xl-5 {
    padding-left: 1.04167rem;
    padding-right: 1.04167rem; }
  .px-xl-6 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-xl-7 {
    padding-left: 1.45833rem;
    padding-right: 1.45833rem; }
  .px-xl-8 {
    padding-left: 1.66667rem;
    padding-right: 1.66667rem; }
  .px-xl-9 {
    padding-left: 1.875rem;
    padding-right: 1.875rem; }
  .px-xl-10 {
    padding-left: 2.08333rem;
    padding-right: 2.08333rem; }
  .px-xl-11 {
    padding-left: 2.29167rem;
    padding-right: 2.29167rem; }
  .px-xl-12 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-xl-13 {
    padding-left: 2.70833rem;
    padding-right: 2.70833rem; }
  .px-xl-14 {
    padding-left: 2.91667rem;
    padding-right: 2.91667rem; }
  .px-xl-15 {
    padding-left: 3.125rem;
    padding-right: 3.125rem; }
  .px-xl-16 {
    padding-left: 3.33333rem;
    padding-right: 3.33333rem; }
  .px-xl-17 {
    padding-left: 3.54167rem;
    padding-right: 3.54167rem; }
  .px-xl-18 {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .px-xl-19 {
    padding-left: 3.95833rem;
    padding-right: 3.95833rem; }
  .px-xl-20 {
    padding-left: 4.16667rem;
    padding-right: 4.16667rem; }
  .py-xl-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-xl-1 {
    padding-top: 0.20833rem;
    padding-bottom: 0.20833rem; }
  .py-xl-2 {
    padding-top: 0.41667rem;
    padding-bottom: 0.41667rem; }
  .py-xl-3 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem; }
  .py-xl-4 {
    padding-top: 0.83333rem;
    padding-bottom: 0.83333rem; }
  .py-xl-5 {
    padding-top: 1.04167rem;
    padding-bottom: 1.04167rem; }
  .py-xl-6 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-xl-7 {
    padding-top: 1.45833rem;
    padding-bottom: 1.45833rem; }
  .py-xl-8 {
    padding-top: 1.66667rem;
    padding-bottom: 1.66667rem; }
  .py-xl-9 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem; }
  .py-xl-10 {
    padding-top: 2.08333rem;
    padding-bottom: 2.08333rem; }
  .py-xl-11 {
    padding-top: 2.29167rem;
    padding-bottom: 2.29167rem; }
  .py-xl-12 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-xl-13 {
    padding-top: 2.70833rem;
    padding-bottom: 2.70833rem; }
  .py-xl-14 {
    padding-top: 2.91667rem;
    padding-bottom: 2.91667rem; }
  .py-xl-15 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem; }
  .py-xl-16 {
    padding-top: 3.33333rem;
    padding-bottom: 3.33333rem; }
  .py-xl-17 {
    padding-top: 3.54167rem;
    padding-bottom: 3.54167rem; }
  .py-xl-18 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .py-xl-19 {
    padding-top: 3.95833rem;
    padding-bottom: 3.95833rem; }
  .py-xl-20 {
    padding-top: 4.16667rem;
    padding-bottom: 4.16667rem; }
  .p-xl-0 {
    padding: 0rem;
    : 0rem; }
  .p-xl-1 {
    padding: 0.20833rem;
    : 0.20833rem; }
  .p-xl-2 {
    padding: 0.41667rem;
    : 0.41667rem; }
  .p-xl-3 {
    padding: 0.625rem;
    : 0.625rem; }
  .p-xl-4 {
    padding: 0.83333rem;
    : 0.83333rem; }
  .p-xl-5 {
    padding: 1.04167rem;
    : 1.04167rem; }
  .p-xl-6 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-xl-7 {
    padding: 1.45833rem;
    : 1.45833rem; }
  .p-xl-8 {
    padding: 1.66667rem;
    : 1.66667rem; }
  .p-xl-9 {
    padding: 1.875rem;
    : 1.875rem; }
  .p-xl-10 {
    padding: 2.08333rem;
    : 2.08333rem; }
  .p-xl-11 {
    padding: 2.29167rem;
    : 2.29167rem; }
  .p-xl-12 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-xl-13 {
    padding: 2.70833rem;
    : 2.70833rem; }
  .p-xl-14 {
    padding: 2.91667rem;
    : 2.91667rem; }
  .p-xl-15 {
    padding: 3.125rem;
    : 3.125rem; }
  .p-xl-16 {
    padding: 3.33333rem;
    : 3.33333rem; }
  .p-xl-17 {
    padding: 3.54167rem;
    : 3.54167rem; }
  .p-xl-18 {
    padding: 3.75rem;
    : 3.75rem; }
  .p-xl-19 {
    padding: 3.95833rem;
    : 3.95833rem; }
  .p-xl-20 {
    padding: 4.16667rem;
    : 4.16667rem; } }

@media (min-width: 1281px) {
  .mt-xl-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-xl-1 {
    margin-top: 0.20833rem;
    : 0.20833rem; }
  .mt-xl-2 {
    margin-top: 0.41667rem;
    : 0.41667rem; }
  .mt-xl-3 {
    margin-top: 0.625rem;
    : 0.625rem; }
  .mt-xl-4 {
    margin-top: 0.83333rem;
    : 0.83333rem; }
  .mt-xl-5 {
    margin-top: 1.04167rem;
    : 1.04167rem; }
  .mt-xl-6 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-xl-7 {
    margin-top: 1.45833rem;
    : 1.45833rem; }
  .mt-xl-8 {
    margin-top: 1.66667rem;
    : 1.66667rem; }
  .mt-xl-9 {
    margin-top: 1.875rem;
    : 1.875rem; }
  .mt-xl-10 {
    margin-top: 2.08333rem;
    : 2.08333rem; }
  .mt-xl-11 {
    margin-top: 2.29167rem;
    : 2.29167rem; }
  .mt-xl-12 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-xl-13 {
    margin-top: 2.70833rem;
    : 2.70833rem; }
  .mt-xl-14 {
    margin-top: 2.91667rem;
    : 2.91667rem; }
  .mt-xl-15 {
    margin-top: 3.125rem;
    : 3.125rem; }
  .mt-xl-16 {
    margin-top: 3.33333rem;
    : 3.33333rem; }
  .mt-xl-17 {
    margin-top: 3.54167rem;
    : 3.54167rem; }
  .mt-xl-18 {
    margin-top: 3.75rem;
    : 3.75rem; }
  .mt-xl-19 {
    margin-top: 3.95833rem;
    : 3.95833rem; }
  .mt-xl-20 {
    margin-top: 4.16667rem;
    : 4.16667rem; }
  .mr-xl-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-xl-1 {
    margin-right: 0.20833rem;
    : 0.20833rem; }
  .mr-xl-2 {
    margin-right: 0.41667rem;
    : 0.41667rem; }
  .mr-xl-3 {
    margin-right: 0.625rem;
    : 0.625rem; }
  .mr-xl-4 {
    margin-right: 0.83333rem;
    : 0.83333rem; }
  .mr-xl-5 {
    margin-right: 1.04167rem;
    : 1.04167rem; }
  .mr-xl-6 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-xl-7 {
    margin-right: 1.45833rem;
    : 1.45833rem; }
  .mr-xl-8 {
    margin-right: 1.66667rem;
    : 1.66667rem; }
  .mr-xl-9 {
    margin-right: 1.875rem;
    : 1.875rem; }
  .mr-xl-10 {
    margin-right: 2.08333rem;
    : 2.08333rem; }
  .mr-xl-11 {
    margin-right: 2.29167rem;
    : 2.29167rem; }
  .mr-xl-12 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-xl-13 {
    margin-right: 2.70833rem;
    : 2.70833rem; }
  .mr-xl-14 {
    margin-right: 2.91667rem;
    : 2.91667rem; }
  .mr-xl-15 {
    margin-right: 3.125rem;
    : 3.125rem; }
  .mr-xl-16 {
    margin-right: 3.33333rem;
    : 3.33333rem; }
  .mr-xl-17 {
    margin-right: 3.54167rem;
    : 3.54167rem; }
  .mr-xl-18 {
    margin-right: 3.75rem;
    : 3.75rem; }
  .mr-xl-19 {
    margin-right: 3.95833rem;
    : 3.95833rem; }
  .mr-xl-20 {
    margin-right: 4.16667rem;
    : 4.16667rem; }
  .mb-xl-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-xl-1 {
    margin-bottom: 0.20833rem;
    : 0.20833rem; }
  .mb-xl-2 {
    margin-bottom: 0.41667rem;
    : 0.41667rem; }
  .mb-xl-3 {
    margin-bottom: 0.625rem;
    : 0.625rem; }
  .mb-xl-4 {
    margin-bottom: 0.83333rem;
    : 0.83333rem; }
  .mb-xl-5 {
    margin-bottom: 1.04167rem;
    : 1.04167rem; }
  .mb-xl-6 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-xl-7 {
    margin-bottom: 1.45833rem;
    : 1.45833rem; }
  .mb-xl-8 {
    margin-bottom: 1.66667rem;
    : 1.66667rem; }
  .mb-xl-9 {
    margin-bottom: 1.875rem;
    : 1.875rem; }
  .mb-xl-10 {
    margin-bottom: 2.08333rem;
    : 2.08333rem; }
  .mb-xl-11 {
    margin-bottom: 2.29167rem;
    : 2.29167rem; }
  .mb-xl-12 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-xl-13 {
    margin-bottom: 2.70833rem;
    : 2.70833rem; }
  .mb-xl-14 {
    margin-bottom: 2.91667rem;
    : 2.91667rem; }
  .mb-xl-15 {
    margin-bottom: 3.125rem;
    : 3.125rem; }
  .mb-xl-16 {
    margin-bottom: 3.33333rem;
    : 3.33333rem; }
  .mb-xl-17 {
    margin-bottom: 3.54167rem;
    : 3.54167rem; }
  .mb-xl-18 {
    margin-bottom: 3.75rem;
    : 3.75rem; }
  .mb-xl-19 {
    margin-bottom: 3.95833rem;
    : 3.95833rem; }
  .mb-xl-20 {
    margin-bottom: 4.16667rem;
    : 4.16667rem; }
  .ml-xl-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-xl-1 {
    margin-left: 0.20833rem;
    : 0.20833rem; }
  .ml-xl-2 {
    margin-left: 0.41667rem;
    : 0.41667rem; }
  .ml-xl-3 {
    margin-left: 0.625rem;
    : 0.625rem; }
  .ml-xl-4 {
    margin-left: 0.83333rem;
    : 0.83333rem; }
  .ml-xl-5 {
    margin-left: 1.04167rem;
    : 1.04167rem; }
  .ml-xl-6 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-xl-7 {
    margin-left: 1.45833rem;
    : 1.45833rem; }
  .ml-xl-8 {
    margin-left: 1.66667rem;
    : 1.66667rem; }
  .ml-xl-9 {
    margin-left: 1.875rem;
    : 1.875rem; }
  .ml-xl-10 {
    margin-left: 2.08333rem;
    : 2.08333rem; }
  .ml-xl-11 {
    margin-left: 2.29167rem;
    : 2.29167rem; }
  .ml-xl-12 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-xl-13 {
    margin-left: 2.70833rem;
    : 2.70833rem; }
  .ml-xl-14 {
    margin-left: 2.91667rem;
    : 2.91667rem; }
  .ml-xl-15 {
    margin-left: 3.125rem;
    : 3.125rem; }
  .ml-xl-16 {
    margin-left: 3.33333rem;
    : 3.33333rem; }
  .ml-xl-17 {
    margin-left: 3.54167rem;
    : 3.54167rem; }
  .ml-xl-18 {
    margin-left: 3.75rem;
    : 3.75rem; }
  .ml-xl-19 {
    margin-left: 3.95833rem;
    : 3.95833rem; }
  .ml-xl-20 {
    margin-left: 4.16667rem;
    : 4.16667rem; }
  .mx-xl-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-xl-1 {
    margin-left: 0.20833rem;
    margin-right: 0.20833rem; }
  .mx-xl-2 {
    margin-left: 0.41667rem;
    margin-right: 0.41667rem; }
  .mx-xl-3 {
    margin-left: 0.625rem;
    margin-right: 0.625rem; }
  .mx-xl-4 {
    margin-left: 0.83333rem;
    margin-right: 0.83333rem; }
  .mx-xl-5 {
    margin-left: 1.04167rem;
    margin-right: 1.04167rem; }
  .mx-xl-6 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-xl-7 {
    margin-left: 1.45833rem;
    margin-right: 1.45833rem; }
  .mx-xl-8 {
    margin-left: 1.66667rem;
    margin-right: 1.66667rem; }
  .mx-xl-9 {
    margin-left: 1.875rem;
    margin-right: 1.875rem; }
  .mx-xl-10 {
    margin-left: 2.08333rem;
    margin-right: 2.08333rem; }
  .mx-xl-11 {
    margin-left: 2.29167rem;
    margin-right: 2.29167rem; }
  .mx-xl-12 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-xl-13 {
    margin-left: 2.70833rem;
    margin-right: 2.70833rem; }
  .mx-xl-14 {
    margin-left: 2.91667rem;
    margin-right: 2.91667rem; }
  .mx-xl-15 {
    margin-left: 3.125rem;
    margin-right: 3.125rem; }
  .mx-xl-16 {
    margin-left: 3.33333rem;
    margin-right: 3.33333rem; }
  .mx-xl-17 {
    margin-left: 3.54167rem;
    margin-right: 3.54167rem; }
  .mx-xl-18 {
    margin-left: 3.75rem;
    margin-right: 3.75rem; }
  .mx-xl-19 {
    margin-left: 3.95833rem;
    margin-right: 3.95833rem; }
  .mx-xl-20 {
    margin-left: 4.16667rem;
    margin-right: 4.16667rem; }
  .my-xl-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-xl-1 {
    margin-top: 0.20833rem;
    margin-bottom: 0.20833rem; }
  .my-xl-2 {
    margin-top: 0.41667rem;
    margin-bottom: 0.41667rem; }
  .my-xl-3 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  .my-xl-4 {
    margin-top: 0.83333rem;
    margin-bottom: 0.83333rem; }
  .my-xl-5 {
    margin-top: 1.04167rem;
    margin-bottom: 1.04167rem; }
  .my-xl-6 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-xl-7 {
    margin-top: 1.45833rem;
    margin-bottom: 1.45833rem; }
  .my-xl-8 {
    margin-top: 1.66667rem;
    margin-bottom: 1.66667rem; }
  .my-xl-9 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem; }
  .my-xl-10 {
    margin-top: 2.08333rem;
    margin-bottom: 2.08333rem; }
  .my-xl-11 {
    margin-top: 2.29167rem;
    margin-bottom: 2.29167rem; }
  .my-xl-12 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-xl-13 {
    margin-top: 2.70833rem;
    margin-bottom: 2.70833rem; }
  .my-xl-14 {
    margin-top: 2.91667rem;
    margin-bottom: 2.91667rem; }
  .my-xl-15 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem; }
  .my-xl-16 {
    margin-top: 3.33333rem;
    margin-bottom: 3.33333rem; }
  .my-xl-17 {
    margin-top: 3.54167rem;
    margin-bottom: 3.54167rem; }
  .my-xl-18 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .my-xl-19 {
    margin-top: 3.95833rem;
    margin-bottom: 3.95833rem; }
  .my-xl-20 {
    margin-top: 4.16667rem;
    margin-bottom: 4.16667rem; }
  .m-xl-0 {
    margin: 0rem;
    : 0rem; }
  .m-xl-1 {
    margin: 0.20833rem;
    : 0.20833rem; }
  .m-xl-2 {
    margin: 0.41667rem;
    : 0.41667rem; }
  .m-xl-3 {
    margin: 0.625rem;
    : 0.625rem; }
  .m-xl-4 {
    margin: 0.83333rem;
    : 0.83333rem; }
  .m-xl-5 {
    margin: 1.04167rem;
    : 1.04167rem; }
  .m-xl-6 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-xl-7 {
    margin: 1.45833rem;
    : 1.45833rem; }
  .m-xl-8 {
    margin: 1.66667rem;
    : 1.66667rem; }
  .m-xl-9 {
    margin: 1.875rem;
    : 1.875rem; }
  .m-xl-10 {
    margin: 2.08333rem;
    : 2.08333rem; }
  .m-xl-11 {
    margin: 2.29167rem;
    : 2.29167rem; }
  .m-xl-12 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-xl-13 {
    margin: 2.70833rem;
    : 2.70833rem; }
  .m-xl-14 {
    margin: 2.91667rem;
    : 2.91667rem; }
  .m-xl-15 {
    margin: 3.125rem;
    : 3.125rem; }
  .m-xl-16 {
    margin: 3.33333rem;
    : 3.33333rem; }
  .m-xl-17 {
    margin: 3.54167rem;
    : 3.54167rem; }
  .m-xl-18 {
    margin: 3.75rem;
    : 3.75rem; }
  .m-xl-19 {
    margin: 3.95833rem;
    : 3.95833rem; }
  .m-xl-20 {
    margin: 4.16667rem;
    : 4.16667rem; } }

@media (min-width: 1441px) {
  .pt-2xl-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-2xl-1 {
    padding-top: 0.20833rem;
    : 0.20833rem; }
  .pt-2xl-2 {
    padding-top: 0.41667rem;
    : 0.41667rem; }
  .pt-2xl-3 {
    padding-top: 0.625rem;
    : 0.625rem; }
  .pt-2xl-4 {
    padding-top: 0.83333rem;
    : 0.83333rem; }
  .pt-2xl-5 {
    padding-top: 1.04167rem;
    : 1.04167rem; }
  .pt-2xl-6 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-2xl-7 {
    padding-top: 1.45833rem;
    : 1.45833rem; }
  .pt-2xl-8 {
    padding-top: 1.66667rem;
    : 1.66667rem; }
  .pt-2xl-9 {
    padding-top: 1.875rem;
    : 1.875rem; }
  .pt-2xl-10 {
    padding-top: 2.08333rem;
    : 2.08333rem; }
  .pt-2xl-11 {
    padding-top: 2.29167rem;
    : 2.29167rem; }
  .pt-2xl-12 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-2xl-13 {
    padding-top: 2.70833rem;
    : 2.70833rem; }
  .pt-2xl-14 {
    padding-top: 2.91667rem;
    : 2.91667rem; }
  .pt-2xl-15 {
    padding-top: 3.125rem;
    : 3.125rem; }
  .pt-2xl-16 {
    padding-top: 3.33333rem;
    : 3.33333rem; }
  .pt-2xl-17 {
    padding-top: 3.54167rem;
    : 3.54167rem; }
  .pt-2xl-18 {
    padding-top: 3.75rem;
    : 3.75rem; }
  .pt-2xl-19 {
    padding-top: 3.95833rem;
    : 3.95833rem; }
  .pt-2xl-20 {
    padding-top: 4.16667rem;
    : 4.16667rem; }
  .pr-2xl-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-2xl-1 {
    padding-right: 0.20833rem;
    : 0.20833rem; }
  .pr-2xl-2 {
    padding-right: 0.41667rem;
    : 0.41667rem; }
  .pr-2xl-3 {
    padding-right: 0.625rem;
    : 0.625rem; }
  .pr-2xl-4 {
    padding-right: 0.83333rem;
    : 0.83333rem; }
  .pr-2xl-5 {
    padding-right: 1.04167rem;
    : 1.04167rem; }
  .pr-2xl-6 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-2xl-7 {
    padding-right: 1.45833rem;
    : 1.45833rem; }
  .pr-2xl-8 {
    padding-right: 1.66667rem;
    : 1.66667rem; }
  .pr-2xl-9 {
    padding-right: 1.875rem;
    : 1.875rem; }
  .pr-2xl-10 {
    padding-right: 2.08333rem;
    : 2.08333rem; }
  .pr-2xl-11 {
    padding-right: 2.29167rem;
    : 2.29167rem; }
  .pr-2xl-12 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-2xl-13 {
    padding-right: 2.70833rem;
    : 2.70833rem; }
  .pr-2xl-14 {
    padding-right: 2.91667rem;
    : 2.91667rem; }
  .pr-2xl-15 {
    padding-right: 3.125rem;
    : 3.125rem; }
  .pr-2xl-16 {
    padding-right: 3.33333rem;
    : 3.33333rem; }
  .pr-2xl-17 {
    padding-right: 3.54167rem;
    : 3.54167rem; }
  .pr-2xl-18 {
    padding-right: 3.75rem;
    : 3.75rem; }
  .pr-2xl-19 {
    padding-right: 3.95833rem;
    : 3.95833rem; }
  .pr-2xl-20 {
    padding-right: 4.16667rem;
    : 4.16667rem; }
  .pb-2xl-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-2xl-1 {
    padding-bottom: 0.20833rem;
    : 0.20833rem; }
  .pb-2xl-2 {
    padding-bottom: 0.41667rem;
    : 0.41667rem; }
  .pb-2xl-3 {
    padding-bottom: 0.625rem;
    : 0.625rem; }
  .pb-2xl-4 {
    padding-bottom: 0.83333rem;
    : 0.83333rem; }
  .pb-2xl-5 {
    padding-bottom: 1.04167rem;
    : 1.04167rem; }
  .pb-2xl-6 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-2xl-7 {
    padding-bottom: 1.45833rem;
    : 1.45833rem; }
  .pb-2xl-8 {
    padding-bottom: 1.66667rem;
    : 1.66667rem; }
  .pb-2xl-9 {
    padding-bottom: 1.875rem;
    : 1.875rem; }
  .pb-2xl-10 {
    padding-bottom: 2.08333rem;
    : 2.08333rem; }
  .pb-2xl-11 {
    padding-bottom: 2.29167rem;
    : 2.29167rem; }
  .pb-2xl-12 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-2xl-13 {
    padding-bottom: 2.70833rem;
    : 2.70833rem; }
  .pb-2xl-14 {
    padding-bottom: 2.91667rem;
    : 2.91667rem; }
  .pb-2xl-15 {
    padding-bottom: 3.125rem;
    : 3.125rem; }
  .pb-2xl-16 {
    padding-bottom: 3.33333rem;
    : 3.33333rem; }
  .pb-2xl-17 {
    padding-bottom: 3.54167rem;
    : 3.54167rem; }
  .pb-2xl-18 {
    padding-bottom: 3.75rem;
    : 3.75rem; }
  .pb-2xl-19 {
    padding-bottom: 3.95833rem;
    : 3.95833rem; }
  .pb-2xl-20 {
    padding-bottom: 4.16667rem;
    : 4.16667rem; }
  .pl-2xl-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-2xl-1 {
    padding-left: 0.20833rem;
    : 0.20833rem; }
  .pl-2xl-2 {
    padding-left: 0.41667rem;
    : 0.41667rem; }
  .pl-2xl-3 {
    padding-left: 0.625rem;
    : 0.625rem; }
  .pl-2xl-4 {
    padding-left: 0.83333rem;
    : 0.83333rem; }
  .pl-2xl-5 {
    padding-left: 1.04167rem;
    : 1.04167rem; }
  .pl-2xl-6 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-2xl-7 {
    padding-left: 1.45833rem;
    : 1.45833rem; }
  .pl-2xl-8 {
    padding-left: 1.66667rem;
    : 1.66667rem; }
  .pl-2xl-9 {
    padding-left: 1.875rem;
    : 1.875rem; }
  .pl-2xl-10 {
    padding-left: 2.08333rem;
    : 2.08333rem; }
  .pl-2xl-11 {
    padding-left: 2.29167rem;
    : 2.29167rem; }
  .pl-2xl-12 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-2xl-13 {
    padding-left: 2.70833rem;
    : 2.70833rem; }
  .pl-2xl-14 {
    padding-left: 2.91667rem;
    : 2.91667rem; }
  .pl-2xl-15 {
    padding-left: 3.125rem;
    : 3.125rem; }
  .pl-2xl-16 {
    padding-left: 3.33333rem;
    : 3.33333rem; }
  .pl-2xl-17 {
    padding-left: 3.54167rem;
    : 3.54167rem; }
  .pl-2xl-18 {
    padding-left: 3.75rem;
    : 3.75rem; }
  .pl-2xl-19 {
    padding-left: 3.95833rem;
    : 3.95833rem; }
  .pl-2xl-20 {
    padding-left: 4.16667rem;
    : 4.16667rem; }
  .px-2xl-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-2xl-1 {
    padding-left: 0.20833rem;
    padding-right: 0.20833rem; }
  .px-2xl-2 {
    padding-left: 0.41667rem;
    padding-right: 0.41667rem; }
  .px-2xl-3 {
    padding-left: 0.625rem;
    padding-right: 0.625rem; }
  .px-2xl-4 {
    padding-left: 0.83333rem;
    padding-right: 0.83333rem; }
  .px-2xl-5 {
    padding-left: 1.04167rem;
    padding-right: 1.04167rem; }
  .px-2xl-6 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-2xl-7 {
    padding-left: 1.45833rem;
    padding-right: 1.45833rem; }
  .px-2xl-8 {
    padding-left: 1.66667rem;
    padding-right: 1.66667rem; }
  .px-2xl-9 {
    padding-left: 1.875rem;
    padding-right: 1.875rem; }
  .px-2xl-10 {
    padding-left: 2.08333rem;
    padding-right: 2.08333rem; }
  .px-2xl-11 {
    padding-left: 2.29167rem;
    padding-right: 2.29167rem; }
  .px-2xl-12 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-2xl-13 {
    padding-left: 2.70833rem;
    padding-right: 2.70833rem; }
  .px-2xl-14 {
    padding-left: 2.91667rem;
    padding-right: 2.91667rem; }
  .px-2xl-15 {
    padding-left: 3.125rem;
    padding-right: 3.125rem; }
  .px-2xl-16 {
    padding-left: 3.33333rem;
    padding-right: 3.33333rem; }
  .px-2xl-17 {
    padding-left: 3.54167rem;
    padding-right: 3.54167rem; }
  .px-2xl-18 {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .px-2xl-19 {
    padding-left: 3.95833rem;
    padding-right: 3.95833rem; }
  .px-2xl-20 {
    padding-left: 4.16667rem;
    padding-right: 4.16667rem; }
  .py-2xl-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-2xl-1 {
    padding-top: 0.20833rem;
    padding-bottom: 0.20833rem; }
  .py-2xl-2 {
    padding-top: 0.41667rem;
    padding-bottom: 0.41667rem; }
  .py-2xl-3 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem; }
  .py-2xl-4 {
    padding-top: 0.83333rem;
    padding-bottom: 0.83333rem; }
  .py-2xl-5 {
    padding-top: 1.04167rem;
    padding-bottom: 1.04167rem; }
  .py-2xl-6 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-2xl-7 {
    padding-top: 1.45833rem;
    padding-bottom: 1.45833rem; }
  .py-2xl-8 {
    padding-top: 1.66667rem;
    padding-bottom: 1.66667rem; }
  .py-2xl-9 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem; }
  .py-2xl-10 {
    padding-top: 2.08333rem;
    padding-bottom: 2.08333rem; }
  .py-2xl-11 {
    padding-top: 2.29167rem;
    padding-bottom: 2.29167rem; }
  .py-2xl-12 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-2xl-13 {
    padding-top: 2.70833rem;
    padding-bottom: 2.70833rem; }
  .py-2xl-14 {
    padding-top: 2.91667rem;
    padding-bottom: 2.91667rem; }
  .py-2xl-15 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem; }
  .py-2xl-16 {
    padding-top: 3.33333rem;
    padding-bottom: 3.33333rem; }
  .py-2xl-17 {
    padding-top: 3.54167rem;
    padding-bottom: 3.54167rem; }
  .py-2xl-18 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .py-2xl-19 {
    padding-top: 3.95833rem;
    padding-bottom: 3.95833rem; }
  .py-2xl-20 {
    padding-top: 4.16667rem;
    padding-bottom: 4.16667rem; }
  .p-2xl-0 {
    padding: 0rem;
    : 0rem; }
  .p-2xl-1 {
    padding: 0.20833rem;
    : 0.20833rem; }
  .p-2xl-2 {
    padding: 0.41667rem;
    : 0.41667rem; }
  .p-2xl-3 {
    padding: 0.625rem;
    : 0.625rem; }
  .p-2xl-4 {
    padding: 0.83333rem;
    : 0.83333rem; }
  .p-2xl-5 {
    padding: 1.04167rem;
    : 1.04167rem; }
  .p-2xl-6 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-2xl-7 {
    padding: 1.45833rem;
    : 1.45833rem; }
  .p-2xl-8 {
    padding: 1.66667rem;
    : 1.66667rem; }
  .p-2xl-9 {
    padding: 1.875rem;
    : 1.875rem; }
  .p-2xl-10 {
    padding: 2.08333rem;
    : 2.08333rem; }
  .p-2xl-11 {
    padding: 2.29167rem;
    : 2.29167rem; }
  .p-2xl-12 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-2xl-13 {
    padding: 2.70833rem;
    : 2.70833rem; }
  .p-2xl-14 {
    padding: 2.91667rem;
    : 2.91667rem; }
  .p-2xl-15 {
    padding: 3.125rem;
    : 3.125rem; }
  .p-2xl-16 {
    padding: 3.33333rem;
    : 3.33333rem; }
  .p-2xl-17 {
    padding: 3.54167rem;
    : 3.54167rem; }
  .p-2xl-18 {
    padding: 3.75rem;
    : 3.75rem; }
  .p-2xl-19 {
    padding: 3.95833rem;
    : 3.95833rem; }
  .p-2xl-20 {
    padding: 4.16667rem;
    : 4.16667rem; } }

@media (min-width: 1441px) {
  .mt-2xl-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-2xl-1 {
    margin-top: 0.20833rem;
    : 0.20833rem; }
  .mt-2xl-2 {
    margin-top: 0.41667rem;
    : 0.41667rem; }
  .mt-2xl-3 {
    margin-top: 0.625rem;
    : 0.625rem; }
  .mt-2xl-4 {
    margin-top: 0.83333rem;
    : 0.83333rem; }
  .mt-2xl-5 {
    margin-top: 1.04167rem;
    : 1.04167rem; }
  .mt-2xl-6 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-2xl-7 {
    margin-top: 1.45833rem;
    : 1.45833rem; }
  .mt-2xl-8 {
    margin-top: 1.66667rem;
    : 1.66667rem; }
  .mt-2xl-9 {
    margin-top: 1.875rem;
    : 1.875rem; }
  .mt-2xl-10 {
    margin-top: 2.08333rem;
    : 2.08333rem; }
  .mt-2xl-11 {
    margin-top: 2.29167rem;
    : 2.29167rem; }
  .mt-2xl-12 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-2xl-13 {
    margin-top: 2.70833rem;
    : 2.70833rem; }
  .mt-2xl-14 {
    margin-top: 2.91667rem;
    : 2.91667rem; }
  .mt-2xl-15 {
    margin-top: 3.125rem;
    : 3.125rem; }
  .mt-2xl-16 {
    margin-top: 3.33333rem;
    : 3.33333rem; }
  .mt-2xl-17 {
    margin-top: 3.54167rem;
    : 3.54167rem; }
  .mt-2xl-18 {
    margin-top: 3.75rem;
    : 3.75rem; }
  .mt-2xl-19 {
    margin-top: 3.95833rem;
    : 3.95833rem; }
  .mt-2xl-20 {
    margin-top: 4.16667rem;
    : 4.16667rem; }
  .mr-2xl-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-2xl-1 {
    margin-right: 0.20833rem;
    : 0.20833rem; }
  .mr-2xl-2 {
    margin-right: 0.41667rem;
    : 0.41667rem; }
  .mr-2xl-3 {
    margin-right: 0.625rem;
    : 0.625rem; }
  .mr-2xl-4 {
    margin-right: 0.83333rem;
    : 0.83333rem; }
  .mr-2xl-5 {
    margin-right: 1.04167rem;
    : 1.04167rem; }
  .mr-2xl-6 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-2xl-7 {
    margin-right: 1.45833rem;
    : 1.45833rem; }
  .mr-2xl-8 {
    margin-right: 1.66667rem;
    : 1.66667rem; }
  .mr-2xl-9 {
    margin-right: 1.875rem;
    : 1.875rem; }
  .mr-2xl-10 {
    margin-right: 2.08333rem;
    : 2.08333rem; }
  .mr-2xl-11 {
    margin-right: 2.29167rem;
    : 2.29167rem; }
  .mr-2xl-12 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-2xl-13 {
    margin-right: 2.70833rem;
    : 2.70833rem; }
  .mr-2xl-14 {
    margin-right: 2.91667rem;
    : 2.91667rem; }
  .mr-2xl-15 {
    margin-right: 3.125rem;
    : 3.125rem; }
  .mr-2xl-16 {
    margin-right: 3.33333rem;
    : 3.33333rem; }
  .mr-2xl-17 {
    margin-right: 3.54167rem;
    : 3.54167rem; }
  .mr-2xl-18 {
    margin-right: 3.75rem;
    : 3.75rem; }
  .mr-2xl-19 {
    margin-right: 3.95833rem;
    : 3.95833rem; }
  .mr-2xl-20 {
    margin-right: 4.16667rem;
    : 4.16667rem; }
  .mb-2xl-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-2xl-1 {
    margin-bottom: 0.20833rem;
    : 0.20833rem; }
  .mb-2xl-2 {
    margin-bottom: 0.41667rem;
    : 0.41667rem; }
  .mb-2xl-3 {
    margin-bottom: 0.625rem;
    : 0.625rem; }
  .mb-2xl-4 {
    margin-bottom: 0.83333rem;
    : 0.83333rem; }
  .mb-2xl-5 {
    margin-bottom: 1.04167rem;
    : 1.04167rem; }
  .mb-2xl-6 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-2xl-7 {
    margin-bottom: 1.45833rem;
    : 1.45833rem; }
  .mb-2xl-8 {
    margin-bottom: 1.66667rem;
    : 1.66667rem; }
  .mb-2xl-9 {
    margin-bottom: 1.875rem;
    : 1.875rem; }
  .mb-2xl-10 {
    margin-bottom: 2.08333rem;
    : 2.08333rem; }
  .mb-2xl-11 {
    margin-bottom: 2.29167rem;
    : 2.29167rem; }
  .mb-2xl-12 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-2xl-13 {
    margin-bottom: 2.70833rem;
    : 2.70833rem; }
  .mb-2xl-14 {
    margin-bottom: 2.91667rem;
    : 2.91667rem; }
  .mb-2xl-15 {
    margin-bottom: 3.125rem;
    : 3.125rem; }
  .mb-2xl-16 {
    margin-bottom: 3.33333rem;
    : 3.33333rem; }
  .mb-2xl-17 {
    margin-bottom: 3.54167rem;
    : 3.54167rem; }
  .mb-2xl-18 {
    margin-bottom: 3.75rem;
    : 3.75rem; }
  .mb-2xl-19 {
    margin-bottom: 3.95833rem;
    : 3.95833rem; }
  .mb-2xl-20 {
    margin-bottom: 4.16667rem;
    : 4.16667rem; }
  .ml-2xl-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-2xl-1 {
    margin-left: 0.20833rem;
    : 0.20833rem; }
  .ml-2xl-2 {
    margin-left: 0.41667rem;
    : 0.41667rem; }
  .ml-2xl-3 {
    margin-left: 0.625rem;
    : 0.625rem; }
  .ml-2xl-4 {
    margin-left: 0.83333rem;
    : 0.83333rem; }
  .ml-2xl-5 {
    margin-left: 1.04167rem;
    : 1.04167rem; }
  .ml-2xl-6 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-2xl-7 {
    margin-left: 1.45833rem;
    : 1.45833rem; }
  .ml-2xl-8 {
    margin-left: 1.66667rem;
    : 1.66667rem; }
  .ml-2xl-9 {
    margin-left: 1.875rem;
    : 1.875rem; }
  .ml-2xl-10 {
    margin-left: 2.08333rem;
    : 2.08333rem; }
  .ml-2xl-11 {
    margin-left: 2.29167rem;
    : 2.29167rem; }
  .ml-2xl-12 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-2xl-13 {
    margin-left: 2.70833rem;
    : 2.70833rem; }
  .ml-2xl-14 {
    margin-left: 2.91667rem;
    : 2.91667rem; }
  .ml-2xl-15 {
    margin-left: 3.125rem;
    : 3.125rem; }
  .ml-2xl-16 {
    margin-left: 3.33333rem;
    : 3.33333rem; }
  .ml-2xl-17 {
    margin-left: 3.54167rem;
    : 3.54167rem; }
  .ml-2xl-18 {
    margin-left: 3.75rem;
    : 3.75rem; }
  .ml-2xl-19 {
    margin-left: 3.95833rem;
    : 3.95833rem; }
  .ml-2xl-20 {
    margin-left: 4.16667rem;
    : 4.16667rem; }
  .mx-2xl-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-2xl-1 {
    margin-left: 0.20833rem;
    margin-right: 0.20833rem; }
  .mx-2xl-2 {
    margin-left: 0.41667rem;
    margin-right: 0.41667rem; }
  .mx-2xl-3 {
    margin-left: 0.625rem;
    margin-right: 0.625rem; }
  .mx-2xl-4 {
    margin-left: 0.83333rem;
    margin-right: 0.83333rem; }
  .mx-2xl-5 {
    margin-left: 1.04167rem;
    margin-right: 1.04167rem; }
  .mx-2xl-6 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-2xl-7 {
    margin-left: 1.45833rem;
    margin-right: 1.45833rem; }
  .mx-2xl-8 {
    margin-left: 1.66667rem;
    margin-right: 1.66667rem; }
  .mx-2xl-9 {
    margin-left: 1.875rem;
    margin-right: 1.875rem; }
  .mx-2xl-10 {
    margin-left: 2.08333rem;
    margin-right: 2.08333rem; }
  .mx-2xl-11 {
    margin-left: 2.29167rem;
    margin-right: 2.29167rem; }
  .mx-2xl-12 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-2xl-13 {
    margin-left: 2.70833rem;
    margin-right: 2.70833rem; }
  .mx-2xl-14 {
    margin-left: 2.91667rem;
    margin-right: 2.91667rem; }
  .mx-2xl-15 {
    margin-left: 3.125rem;
    margin-right: 3.125rem; }
  .mx-2xl-16 {
    margin-left: 3.33333rem;
    margin-right: 3.33333rem; }
  .mx-2xl-17 {
    margin-left: 3.54167rem;
    margin-right: 3.54167rem; }
  .mx-2xl-18 {
    margin-left: 3.75rem;
    margin-right: 3.75rem; }
  .mx-2xl-19 {
    margin-left: 3.95833rem;
    margin-right: 3.95833rem; }
  .mx-2xl-20 {
    margin-left: 4.16667rem;
    margin-right: 4.16667rem; }
  .my-2xl-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-2xl-1 {
    margin-top: 0.20833rem;
    margin-bottom: 0.20833rem; }
  .my-2xl-2 {
    margin-top: 0.41667rem;
    margin-bottom: 0.41667rem; }
  .my-2xl-3 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  .my-2xl-4 {
    margin-top: 0.83333rem;
    margin-bottom: 0.83333rem; }
  .my-2xl-5 {
    margin-top: 1.04167rem;
    margin-bottom: 1.04167rem; }
  .my-2xl-6 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-2xl-7 {
    margin-top: 1.45833rem;
    margin-bottom: 1.45833rem; }
  .my-2xl-8 {
    margin-top: 1.66667rem;
    margin-bottom: 1.66667rem; }
  .my-2xl-9 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem; }
  .my-2xl-10 {
    margin-top: 2.08333rem;
    margin-bottom: 2.08333rem; }
  .my-2xl-11 {
    margin-top: 2.29167rem;
    margin-bottom: 2.29167rem; }
  .my-2xl-12 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-2xl-13 {
    margin-top: 2.70833rem;
    margin-bottom: 2.70833rem; }
  .my-2xl-14 {
    margin-top: 2.91667rem;
    margin-bottom: 2.91667rem; }
  .my-2xl-15 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem; }
  .my-2xl-16 {
    margin-top: 3.33333rem;
    margin-bottom: 3.33333rem; }
  .my-2xl-17 {
    margin-top: 3.54167rem;
    margin-bottom: 3.54167rem; }
  .my-2xl-18 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .my-2xl-19 {
    margin-top: 3.95833rem;
    margin-bottom: 3.95833rem; }
  .my-2xl-20 {
    margin-top: 4.16667rem;
    margin-bottom: 4.16667rem; }
  .m-2xl-0 {
    margin: 0rem;
    : 0rem; }
  .m-2xl-1 {
    margin: 0.20833rem;
    : 0.20833rem; }
  .m-2xl-2 {
    margin: 0.41667rem;
    : 0.41667rem; }
  .m-2xl-3 {
    margin: 0.625rem;
    : 0.625rem; }
  .m-2xl-4 {
    margin: 0.83333rem;
    : 0.83333rem; }
  .m-2xl-5 {
    margin: 1.04167rem;
    : 1.04167rem; }
  .m-2xl-6 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-2xl-7 {
    margin: 1.45833rem;
    : 1.45833rem; }
  .m-2xl-8 {
    margin: 1.66667rem;
    : 1.66667rem; }
  .m-2xl-9 {
    margin: 1.875rem;
    : 1.875rem; }
  .m-2xl-10 {
    margin: 2.08333rem;
    : 2.08333rem; }
  .m-2xl-11 {
    margin: 2.29167rem;
    : 2.29167rem; }
  .m-2xl-12 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-2xl-13 {
    margin: 2.70833rem;
    : 2.70833rem; }
  .m-2xl-14 {
    margin: 2.91667rem;
    : 2.91667rem; }
  .m-2xl-15 {
    margin: 3.125rem;
    : 3.125rem; }
  .m-2xl-16 {
    margin: 3.33333rem;
    : 3.33333rem; }
  .m-2xl-17 {
    margin: 3.54167rem;
    : 3.54167rem; }
  .m-2xl-18 {
    margin: 3.75rem;
    : 3.75rem; }
  .m-2xl-19 {
    margin: 3.95833rem;
    : 3.95833rem; }
  .m-2xl-20 {
    margin: 4.16667rem;
    : 4.16667rem; } }

.heading-1 {
  line-height: 1.33333;
  font-size: clamp(12px, 2.5rem, 2.70833rem);
  font-family: 'Nunito Sans'; }
  @media (max-width: 768px) {
    .heading-1 {
      font-size: clamp(13px, 2.5rem, 2.70833rem); } }
  @media (max-width: 1024px) {
    .heading-1 {
      font-size: clamp(12px, 2.08333rem, 2.29167rem); } }
  @media (max-width: 1024px) and (max-width: 768px) {
    .heading-1 {
      font-size: clamp(13px, 2.08333rem, 2.29167rem); } }

.heading-2 {
  line-height: 1.2;
  font-size: clamp(12px, 2.08333rem, 2.29167rem);
  font-family: 'Nunito Sans'; }
  @media (max-width: 768px) {
    .heading-2 {
      font-size: clamp(13px, 2.08333rem, 2.29167rem); } }
  @media (max-width: 1024px) {
    .heading-2 {
      font-size: clamp(12px, 1.66667rem, 1.875rem); } }
  @media (max-width: 1024px) and (max-width: 768px) {
    .heading-2 {
      font-size: clamp(13px, 1.66667rem, 1.875rem); } }

.heading-3 {
  line-height: 1.22222;
  font-size: clamp(12px, 1.875rem, 2.08333rem);
  font-family: 'Nunito Sans'; }
  @media (max-width: 768px) {
    .heading-3 {
      font-size: clamp(13px, 1.875rem, 2.08333rem); } }
  @media (max-width: 1024px) {
    .heading-3 {
      font-size: clamp(12px, 1.45833rem, 1.66667rem); } }
  @media (max-width: 1024px) and (max-width: 768px) {
    .heading-3 {
      font-size: clamp(13px, 1.45833rem, 1.66667rem); } }

.heading-4, .product-item-title, .history-item-year, .cooperation-item-title, .images-slider .img .caption {
  line-height: 1.25;
  font-size: clamp(12px, 1.66667rem, 1.875rem);
  font-family: 'Nunito Sans'; }
  @media (max-width: 768px) {
    .heading-4, .product-item-title, .history-item-year, .cooperation-item-title, .images-slider .img .caption {
      font-size: clamp(13px, 1.66667rem, 1.875rem); } }
  @media (max-width: 1024px) {
    .heading-4, .product-item-title, .history-item-year, .cooperation-item-title, .images-slider .img .caption {
      font-size: clamp(12px, 1.25rem, 1.45833rem); } }
  @media (max-width: 1024px) and (max-width: 768px) {
    .heading-4, .product-item-title, .history-item-year, .cooperation-item-title, .images-slider .img .caption {
      font-size: clamp(13px, 1.25rem, 1.45833rem); } }

.heading-5, .info-heading, .posttitle, .cart-section .card-item .card-heading .card-title, .checkout-section .card-item .card-heading .card-title, .checkout-complete-section .card-item .card-heading .card-title, .news-item-title, .cart-suggest-product .product-item-title, .recruitment-list table th, .culture-trigger-item-title, .product-detail-attributes .tabs-nav li a, .supply-detail .tab-content table th {
  line-height: 1.33333;
  font-size: clamp(12px, 1.25rem, 1.45833rem);
  font-family: 'Nunito Sans'; }
  @media (max-width: 768px) {
    .heading-5, .info-heading, .posttitle, .cart-section .card-item .card-heading .card-title, .checkout-section .card-item .card-heading .card-title, .checkout-complete-section .card-item .card-heading .card-title, .news-item-title, .cart-suggest-product .product-item-title, .recruitment-list table th, .culture-trigger-item-title, .product-detail-attributes .tabs-nav li a, .supply-detail .tab-content table th {
      font-size: clamp(13px, 1.25rem, 1.45833rem); } }
  @media (max-width: 1024px) {
    .heading-5, .info-heading, .posttitle, .cart-section .card-item .card-heading .card-title, .checkout-section .card-item .card-heading .card-title, .checkout-complete-section .card-item .card-heading .card-title, .news-item-title, .cart-suggest-product .product-item-title, .recruitment-list table th, .culture-trigger-item-title, .product-detail-attributes .tabs-nav li a, .supply-detail .tab-content table th {
      font-size: clamp(12px, 1.14583rem, 1.35417rem); } }
  @media (max-width: 1024px) and (max-width: 768px) {
    .heading-5, .info-heading, .posttitle, .cart-section .card-item .card-heading .card-title, .checkout-section .card-item .card-heading .card-title, .checkout-complete-section .card-item .card-heading .card-title, .news-item-title, .cart-suggest-product .product-item-title, .recruitment-list table th, .culture-trigger-item-title, .product-detail-attributes .tabs-nav li a, .supply-detail .tab-content table th {
      font-size: clamp(13px, 1.14583rem, 1.35417rem); } }

.body-1, .document-item-title, .news-item-brief, .product-item-price, .tab-nav li a, .sub-nav ul li a, .about-nav ul li a, .history-item-brief, .cooperation-detail-heading .img figure figcaption, .cooperation-item-brief, .home-4 .list-item .item .title, .dealer-group-list .dealer-group .name, .dealer-item-address, .manufacture-standard-slider .item, .recruitment-detail-brief table, .recruitment-side-item-title, .product-detail-content strong, .supply-detail .tab-content, .article-content {
  line-height: 1.4;
  font-size: clamp(12px, 1.04167rem, 1.25rem); }
  @media (max-width: 768px) {
    .body-1, .document-item-title, .news-item-brief, .product-item-price, .tab-nav li a, .sub-nav ul li a, .about-nav ul li a, .history-item-brief, .cooperation-detail-heading .img figure figcaption, .cooperation-item-brief, .home-4 .list-item .item .title, .dealer-group-list .dealer-group .name, .dealer-item-address, .manufacture-standard-slider .item, .recruitment-detail-brief table, .recruitment-side-item-title, .product-detail-content strong, .supply-detail .tab-content, .article-content {
      font-size: clamp(13px, 1.04167rem, 1.25rem); } }

.body-2, .journalItems .cmt-item .item .caption .name, .journalItems .cmt-item .item .jsummary .name, .journalItems .cmt-item .cmt-area .caption .name, .journalItems .cmt-item .cmt-area .jsummary .name, .register-nav ul li a, .dealer-detail-address, .recruitment-side-item-body, .recruitment-list table {
  line-height: 1.33333;
  font-size: clamp(12px, 0.9375rem, 1.14583rem); }
  @media (max-width: 768px) {
    .body-2, .journalItems .cmt-item .item .caption .name, .journalItems .cmt-item .item .jsummary .name, .journalItems .cmt-item .cmt-area .caption .name, .journalItems .cmt-item .cmt-area .jsummary .name, .register-nav ul li a, .dealer-detail-address, .recruitment-side-item-body, .recruitment-list table {
      font-size: clamp(13px, 0.9375rem, 1.14583rem); } }

.body-3, .login-box input[type="text"], .login-box input[type="password"], .login-box select, .wrap-register input[type="text"], .wrap-register input[type="password"], .wrap-register select, .account-form input[type="text"], .account-form input[type="password"], .account-form select, .address-form input[type="text"], .address-form input[type="password"], .address-form select, .wrap-recover input[type="text"], .wrap-recover input[type="password"], .wrap-recover select, .wrap-resetpass input[type="text"], .wrap-resetpass input[type="password"], .wrap-resetpass select, .wrap-register .form-group label, .account-form .form-group label, .address-form .form-group label, .wrap-recover .form-group label, .wrap-resetpass .form-group label, .account-info, .coin-info, .cart-history, .order-info, .order-information, .order-product, .order-total, .address-info, .account-info .link, .coin-info .link, .cart-history .link, .order-info .link, .order-information .link, .order-product .link, .order-total .link, .address-info .link, .info-heading .link, .posttitle .link, .address-list .address .name, .product-review .medium-rating, .product-review .charts .chart, .comment-form .module-title, .journalItems .cmt-item .item .caption, .journalItems .cmt-item .item .jsummary, .journalItems .cmt-item .cmt-area .caption, .journalItems .cmt-item .cmt-area .jsummary, .global-footer, .footer-address, .cart-section .card-item, .checkout-section .card-item, .checkout-complete-section .card-item, .product-item-brief, .register-box .form-radio ul li label, .checkout-complete-section .article-content, .checkout-info-form .form-group label, .promotion-popup .promotion-item-choose label .radio::before, .promotion-popup .promotion-item-choose label .desc, .form-group .settinglabel, .input-group .settinglabel {
  line-height: 1.5;
  font-size: clamp(12px, 0.83333rem, 1.04167rem); }
  @media (max-width: 768px) {
    .body-3, .login-box input[type="text"], .login-box input[type="password"], .login-box select, .wrap-register input[type="text"], .wrap-register input[type="password"], .wrap-register select, .account-form input[type="text"], .account-form input[type="password"], .account-form select, .address-form input[type="text"], .address-form input[type="password"], .address-form select, .wrap-recover input[type="text"], .wrap-recover input[type="password"], .wrap-recover select, .wrap-resetpass input[type="text"], .wrap-resetpass input[type="password"], .wrap-resetpass select, .wrap-register .form-group label, .account-form .form-group label, .address-form .form-group label, .wrap-recover .form-group label, .wrap-resetpass .form-group label, .account-info, .coin-info, .cart-history, .order-info, .order-information, .order-product, .order-total, .address-info, .account-info .link, .coin-info .link, .cart-history .link, .order-info .link, .order-information .link, .order-product .link, .order-total .link, .address-info .link, .info-heading .link, .posttitle .link, .address-list .address .name, .product-review .medium-rating, .product-review .charts .chart, .comment-form .module-title, .journalItems .cmt-item .item .caption, .journalItems .cmt-item .item .jsummary, .journalItems .cmt-item .cmt-area .caption, .journalItems .cmt-item .cmt-area .jsummary, .global-footer, .footer-address, .cart-section .card-item, .checkout-section .card-item, .checkout-complete-section .card-item, .product-item-brief, .register-box .form-radio ul li label, .checkout-complete-section .article-content, .checkout-info-form .form-group label, .promotion-popup .promotion-item-choose label .radio::before, .promotion-popup .promotion-item-choose label .desc, .form-group .settinglabel, .input-group .settinglabel {
      font-size: clamp(13px, 0.83333rem, 1.04167rem); } }

.body-4, .login-box input[type=submit], .wrap-register input[type=submit], .account-form input[type=submit], .address-form input[type=submit], .wrap-recover input[type=submit], .wrap-resetpass input[type=submit], .subscribefrm input, .has-sub .sub-menu ul li a, .breadcrumb-wrapper .breadcrumb li a, .breadcrumb-wrapper .breadcrumb li + li::before, .checkout-info-form .form-group .form-control input[type="text"], .checkout-info-form .form-group .form-control select, .checkout-info-form .form-group .form-control textarea, .home-4 .list-item .item .link a span, .btn-icon span {
  line-height: 1.42857;
  font-size: clamp(12px, 0.72917rem, 0.9375rem); }
  @media (max-width: 768px) {
    .body-4, .login-box input[type=submit], .wrap-register input[type=submit], .account-form input[type=submit], .address-form input[type=submit], .wrap-recover input[type=submit], .wrap-resetpass input[type=submit], .subscribefrm input, .has-sub .sub-menu ul li a, .breadcrumb-wrapper .breadcrumb li a, .breadcrumb-wrapper .breadcrumb li + li::before, .checkout-info-form .form-group .form-control input[type="text"], .checkout-info-form .form-group .form-control select, .checkout-info-form .form-group .form-control textarea, .home-4 .list-item .item .link a span, .btn-icon span {
      font-size: clamp(13px, 0.72917rem, 0.9375rem); } }

.body-5, .address-list .address, .journalItems .cmt-item .item .caption .mod, .journalItems .cmt-item .item .jsummary .mod, .journalItems .cmt-item .cmt-area .caption .mod, .journalItems .cmt-item .cmt-area .jsummary .mod, .journalItems .cmt-item .item .caption time, .journalItems .cmt-item .item .caption abbr, .journalItems .cmt-item .item .jsummary time, .journalItems .cmt-item .item .jsummary abbr, .journalItems .cmt-item .cmt-area .caption time, .journalItems .cmt-item .cmt-area .caption abbr, .journalItems .cmt-item .cmt-area .jsummary time, .journalItems .cmt-item .cmt-area .jsummary abbr, .cart-product-item-price .discount, .document-item-meta, .news-item-date, .checkout-info-form .form-group label.error {
  line-height: 1.33333;
  font-size: clamp(12px, 0.625rem, 0.83333rem); }
  @media (max-width: 768px) {
    .body-5, .address-list .address, .journalItems .cmt-item .item .caption .mod, .journalItems .cmt-item .item .jsummary .mod, .journalItems .cmt-item .cmt-area .caption .mod, .journalItems .cmt-item .cmt-area .jsummary .mod, .journalItems .cmt-item .item .caption time, .journalItems .cmt-item .item .caption abbr, .journalItems .cmt-item .item .jsummary time, .journalItems .cmt-item .item .jsummary abbr, .journalItems .cmt-item .cmt-area .caption time, .journalItems .cmt-item .cmt-area .caption abbr, .journalItems .cmt-item .cmt-area .jsummary time, .journalItems .cmt-item .cmt-area .jsummary abbr, .cart-product-item-price .discount, .document-item-meta, .news-item-date, .checkout-info-form .form-group label.error {
      font-size: clamp(13px, 0.625rem, 0.83333rem); } }

.flex {
  display: flex; }

.inline-flex {
  display: inline-flex; }

.flex-wrap {
  flex-wrap: wrap; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse; }

.flex-column {
  flex-direction: column; }

.flex-column-reverse {
  flex-direction: column-reverse; }

.flex-row {
  flex-direction: row; }

.flex-row-reverse {
  flex-direction: row-reverse; }

.flex-center {
  justify-content: center;
  align-items: center; }

.justify-start {
  justify-content: flex-start; }

.justify-end {
  justify-content: flex-end; }

.justify-center {
  justify-content: center; }

.justify-between {
  justify-content: space-between; }

.items-center {
  align-items: center; }

.items-start {
  align-items: flex-start; }

.items-end {
  align-items: flex-end; }

.items-baseline {
  align-items: baseline; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.block {
  display: block; }

.inline-block {
  display: inline-block; }

.inline {
  display: inline; }

.hidden, .d-none, [hidden] {
  display: none; }

@media (max-width: 1024px) {
  .hidden-mobile {
    display: none; } }

@media (min-width: 1025px) {
  .hidden-desktop {
    display: none; } }

.text-transform-none {
  text-transform: none; }

.text-uppercase {
  text-transform: uppercase; }

.text-capitalize {
  text-transform: capitalize; }

.font-light {
  font-weight: 300; }

.font-normal {
  font-weight: 400; }

.font-medium {
  font-weight: 500; }

.font-semibold {
  font-weight: 600; }

.font-bold, .text-bold {
  font-weight: 700; }

.font-italic {
  font-style: italic; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-justify {
  text-align: justify; }

.position-relative {
  position: relative; }

.position-absolute {
  position: absolute; }

.position-fixed {
  position: fixed; }

.position-sticky {
  position: sticky; }

.top-0 {
  top: 0; }

.bottom-0 {
  bottom: 0; }

.left-0 {
  left: 0; }

.right-0 {
  right: 0; }

.rounded {
  border-radius: 50%; }

.w-100 {
  width: 100%; }

.h-100 {
  height: 100%; }

.leading-tight {
  line-height: 1.25; }

.leading-snug {
  line-height: 1.333333; }

.leading-normal {
  line-height: 1.5; }

.leading-relaxed {
  line-height: 1.625; }

.leading-loose {
  line-height: 2; }

.overflow-hidden {
  overflow: hidden; }

.background-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.text-underline {
  text-decoration: underline; }
