.cooperation-item
	padding: r(40px) r(52px)
	display: flex
	flex-direction: column
	text-align: center
	&-title
		@extend .heading-4
		+text(primary-600)
		font-weight: 700
		margin-bottom: r(20px)
	&-brief
		@extend .body-1
		margin-bottom: r(16px)
		margin-top: auto

.images-slider
	.swiper-container
		+mq-min(lg)
			overflow: visible
	.swiper-slide
		.caption
			opacity: 0
		img
			opacity: 0.5
		&-active
			img,.caption
				opacity: 1
	.img
		position: relative
		background: #000
		&::before
			+pseudo
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)
			height: r(200px)
			bottom: 0
			width: 100%
			z-index: 1
		figure
			+res-ratio(1080,670)
			img
				+trans-all
		.caption
			+trans-all
			position: absolute
			padding: 0 r(16px)
			bottom: r(40px)
			z-index: 2
			text-align: center
			+text(white)
			@extend .heading-4
			font-weight: 700
			width: 100%
			+mq-max(lg)
				bottom: r(20px)