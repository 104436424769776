.about-nav
	ul
		display: flex
		gap: r(2px)
		overflow: auto
		li
			flex: auto
			a
				display: flex
				align-items: center
				height: r(52px)
				padding: 0 r(32px)
				justify-content: center
				text-align: center
				@extend .body-1
				border-radius: 0 0 r(8px) r(8px)
				+bg(gray-50)
				white-space: nowrap
				+parent-state(".active")
					+bg(primary-500)
					+text(white)
				+mq-max(md)
					padding: 0 r(20px)
	+mq-max(md)
		.container
			max-width: none
			padding: 0

.about-1
	position: relative
	.bg-icon
		position: absolute
		top: 0
		left: 0
		z-index: 2
		width: r(259px)
		+remove(lg,max)
		figure
			+res-ratio(1,1)
		+mq-max(2xl)
			left: r(-100px)
	.content-container
		+mq-max(lg)
			padding: r(40px) 0
		+mq-min(lg)
			position: absolute
			+center(Y)
			left: 0
			width: 100%
		.article-content
			+line(6)
	.img-container
		+mq-max(lg)
			.img
				figure
					+res-ratio(1200,650)
		+mq-min(lg)
			width: 1200 / 1920 * 100%
			margin-left: auto
			.img
				figure
					height: r(650px)
					img
						+fit-cover
