.home-3
	.item-col
		.item
			background: #F8F8F8
		&:nth-child(2)
			.item
				background: rgba(203, 203, 203, 0.3)
		&:nth-child(3)
			.item
				+bg(gray-100)
	.item
		padding: r(60px)
		align-items: center
		.icon
			+circle(160px)
			border: 5px solid #FFFFFF
			box-shadow: -4px 4px 20px rgba(25, 64, 92, 0.25)
			+bg(primary-600)
			figure
				img
					max-width: r(90px)
		.title
			text-align: center
			+mq-min(lg)
				max-width: r(280px)
				margin-left: auto
				margin-right: auto
