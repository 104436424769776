
.banner-home
	.swiper-pagination
		bottom: r(60px)
		z-index: 2
		+mq-max(lg)
			bottom: r(20px)
		&-bullet
			height: r(16px)
			width: r(16px)
			+bg(white)
			opacity: 1
			margin: 0 r(10px) !important
			&-active
				+bg(primary-500)

.banner-item
	position: relative
	.banner-icon
		position: absolute
		+center(both)
		+fz(80px)
		+text(white)
	.banner-caption
		position: absolute
		bottom: r(144px)
		width: 100%
		+text(white)
		+mq-max(lg)
			bottom: r(32px)
		.title
			font-size: r(70px)
			font-weight: 900
			+mq-max(lg)
				font-size: r(24px)
				margin-bottom: r(12px)
		.desc
			+mq-max(lg)
				font-size: r(18px)