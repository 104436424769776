.recruitment-list
	counter-reset: section
	+mq-max(lg)
		overflow: auto !important
	table
		width: 100%
		text-align: center
		@extend .body-2
		+mq-max(lg)
			width: 150%
		th,td
			padding: r(16px) r(40px)
			+mq-max(sm)
				padding: r(16px) r(20px)
		th
			@extend .heading-5
			+bg(primary-500)
			+text(white)
			text-transform: uppercase
			font-weight: 700
			white-space: nowrap
		tr
			+even
				+bg(gray-50)
		.num
			&::before
				counter-increment: section
				content: counter(section)

.recruitment-policy-item
	& + .recruitment-policy-item
		margin-top: r(40px)
	+mq-max(md)
		text-align: center
	+even
		.row
			flex-direction: row-reverse
	&-img
		max-width: r(404px)
		margin: 0 auto
		position: relative
		figure
			+res-ratio(1,1)
			border-radius: 50%
			overflow: hidden
		+mq-min(sm)
			&::before
				+pseudo
				top: r(84px)
				left: r(-72px)
				width: 29.5rem
				height: 11.625rem
				border-radius: 50%
				+bg(primary-50)
				transform: rotate(-30deg)

.recruitment-slogan
	+mq-max(md)
		.layout
			> *
				text-align: center !important

.culture-trigger-item-list
	+mq-min(lg)
		display: flex
		flex-direction: column
		.culture-trigger-item
			flex: 1
	+mq-max(lg)
		padding: r(40px) 0
		+content-margins
			margin-top: r(20px)

.culture-trigger-item
	display: flex
	gap: r(20px)
	align-items: center
	+bg(white)
	padding: r(36px) 0
	position: relative
	+trans-all
	box-shadow: 0px 6px 15px 0px rgba(0,0,0,0.1)
	z-index: 1
	cursor: pointer
	+mq-max(lg)
		padding: r(20px)
	+mq-min(lg)
		&::before
			+pseudo
			top: 0
			right: 0
			width: 1000px
			+bg(white)
			height: 100%
			+trans-all
			box-shadow: 0px 6px 15px 0px rgba(0,0,0,0.1)
			z-index: -1
	&::after
		+icon("\f0da")
		position: absolute
		right: 0
		height: 100%
		display: flex
		align-items: center
		width: r(32px)
		+bg(primary-400)
		+text(primary-300)
		font-weight: 900
		+fz(32px)
		opacity: 0
		+trans-all
	&-icon
		+flex-width(r(92px))
		figure
			display: flex
			height: r(92px)
			+flex-center
			border-radius: 50%
			box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25)
			+bg(white)
			+trans-all
			img
				+trans-all
				width: r(60px)
	&-title
		@extend .heading-5
		font-weight: 700
		flex: 1
		padding-right: r(40px)
	&.active
		+mq-min(lg)
			+bg(primary-500)
			+text(white)
			&::before
				+bg(primary-500)
		&::after
			opacity: 1
		.culture-trigger-item-icon
			figure
				+bg(primary-500)
				img
					filter: brightness(0) invert(1)


.culture-target-item,.culture-mobile-item
	padding: r(60px) 0 r(36px)
	+text(white)
	+mq-max(lg)
		padding: 0
	&-img
		figure
			+res-ratio(725,352)
			border-radius: r(20px)
			overflow: hidden
	&-brief
		ul
			list-style: disc
			padding-left: 1rem