.global-header
	+bg(primary-500)
	+mq-max(lg)
		position: relative

// .homepage
// 	.global-header
// 		position: fixed
// 		top: 0
// 		left: 0
// 		width: 100%
// 		z-index: 99

.header-top
	+text(white)
	+mq-max(lg)
		padding: r(12px) 0

.header-bottom-wrap
	+trans-all
	+bg(white)
	border-bottom: 1px solid #e7e7e7
	+trans-all
	padding: r(12px) 0

// .homepage
// 	+mq-min(lg)
// 		.global-header
// 			background: transparent
// 			&:before
// 				+pseudo
// 				height: 160px
// 				width: 1053 / 1920 * 100%
// 				background-image: url(../img/header-bg.svg)
// 				background-position: left top
// 				background-repeat: no-repeat
// 				background-size: contain
// 				z-index: -1
// 				pointer-events: none
// 				+trans-all
// 			.header-bottom-wrap
// 				background: transparent
// 				+text(white)
// 				border-bottom: 0
// 			.top-menu
// 				+text(gray-700)
// 			.menu
// 				ul
// 					li
// 						&.active
// 							a
// 								+text(white)
// 								+mq-min(lg)
// 									border-bottom: r(2px) solid color(white)
// 			&.is-scrolling
// 				+bg(primary-500)
// 				&:before
// 					opacity: 0
// 				.top-menu
// 					+text(white)
// 				.header-bottom-wrap
// 					+bg(white)
// 					border-bottom: 1px solid #e7e7e7
// 					+trans-all
// 					+text(gray-500)
// 				.menu
// 					ul
// 						li
// 							&.active
// 								a
// 									+text(primary-500)
// 									+mq-min(lg)
// 										border-bottom: r(2px) solid color(primary-500)

.logo
	width: r(140px)
	a
		display: block
		+res-ratio(498,256)
		img
			object-fit: contain
		+mq-max(xl)
			padding-top: 30%
		+mq-max(lg)
			+res-ratio(498,256)

.top-menu
	+trans-all
	> ul
		+mq-min(lg)
			+layout-row(24px)
		+mq-max(lg)
			+layout-col(16px)
				padding-top: r(16px)
				border-top: 1px solid color(gray-50)
		> li
			&.active
				+mq-min(lg)
					> a
						text-decoration: underline
				+mq-max(lg)
					> a
						+text(primary-500)
			+on-hover
				+mq-min(lg)
					> a
						text-decoration: underline
				+mq-max(lg)
					> a
						+text(primary-500)
			> a
				text-transform: uppercase
				+fz(14px)
				+mq-min(lg)
					height: r(48px)
					display: flex
					align-items: center

.menu
	> ul
		+mq-min(lg)
			+layout-row(60px)
			justify-content: flex-end
		+mq-max(lg)
			+layout-col(16px)
				padding-top: r(16px)
				border-top: 1px solid color(gray-50)
		> li
			&.active
				> a
					+text(primary-500)
					+mq-min(lg)
						border-bottom: r(2px) solid color(primary-500)
			+on-hover
				> a
					+text(primary-500)
					+mq-min(lg)
						border-bottom: r(2px) solid color(primary-500)
			> a
				text-transform: uppercase
				font-size: r(16px)
				+mq-min(lg)
					height: r(64px)
					display: flex
					align-items: center

.has-sub
	position: relative
	display: flex
	align-items: center
	justify-content: space-between
	flex-wrap: wrap
	.toggle-sub-menu
		+mq-min(lg)
			display: none
		+mq-max(lg)
			+box(32px)
			display: flex
			+flex-center
			margin-left: auto
			+bg(primary-500)
			+text(white)
			margin-left: auto
	.sub-menu
		display: none
		z-index: 2
		+mq-max(lg)
			+flex-width(100%)
		+mq-min(lg)
			position: absolute
			top: 100%
			left: 0
			padding-top: r(2px)
		ul
			padding: r(16px)
			+bg(white)
			+text(gray-600)
			+layout-col(16px)
			+mq-max(lg)
				padding: 0 0 0 r(16px)
				margin-top: r(16px)
				border-left: 1px dashed color(gray-50)

			li
				a
					@extend .body-4
					white-space: nowrap
				&.active
					> a
						text-decoration: underline
				+on-hover
					> a
						text-decoration: underline
	+on-hover
		.sub-menu
			display: block

.header-util-list
	+layout-row(32px)
	justify-content: flex-end
	align-items: center
	+mq-max(sm)
		justify-content: space-between

.language
	a
		&.active
			text-decoration: underline
			text-underline-offset: r(4px)
	+layout-row(8px)
		padding-left: r(8px)
		border-left: 1px solid color(primary-300)

.hotline.btn
	height: clamp(32px,r(32px),r(32px))
	+fz(14px)
	text-transform: none
	em
		+fz(16px)
	+mq-max(lg)
		border: 1px solid color(primary-500)
		+text(primary-500)

.account
	display: block !important
	.firstnav
		> span
			font-weight: 700
			white-space: nowrap
	> a
		+layout-row(8px)
		+fz(14px)
		align-items: center
		&::before
			font-family: 'Font Awesome 6 Pro'
			content: "\f007"
			font-weight: 300
			+fz(16px)
			display: inline-block
			margin-right: r(8px)
		span
			overflow: hidden
			text-overflow: ellipsis

	.dropdown-content
		right: 0
		padding-top: r(16px)
		z-index: 2
		+text(gray-700)
		ul
			+layout-col(8px)
			li
				a
					white-space: nowrap

.menu-toggle
	+box(48px)
	display: flex
	+flex-center
	+bg(primary-500)
	+fz(28px)
	+text(white)
	margin-left: auto

.menu-mobile
	position: fixed
	padding: r(48px) r(24px)
	top: 0
	left: -100%
	width: 100%
	height: 100%
	+bg(white)
	overflow: auto
	z-index: 1000
	+trans-all
	&.show
		left: 0
	.close-menu
		position: absolute
		+box(44px)
		display: flex
		+flex-center
		+bg(gray-200)
		top: 0
		right: 0
		font-size: r(24px)
	.menu-mobile-header
		margin-bottom: r(20px)
		display: flex
		align-items: center
		justify-content: space-between
	.menu-mobile-body
		+layout-col(16px)
			padding-top: r(16px)
			border-top: 1px solid color(gray-50)

.backdrop
	+fill
	position: fixed
	z-index: 800
	background: rgba(#000, 0.5)
	display: none
	cursor: pointer

.search-popup
	position: fixed
	z-index: 900
	top: 0
	left: 0
	width: 100vw
	height: 100vh
	pointer-events: none
	display: none
	.search-container
		width: 100%
		height: 100%
		+flex-center
		display: flex
		pointer-events: none
		+mq-max(md)
			align-items: flex-start
			padding-top: r(180px)
	.searchbox
		pointer-events: all
		position: relative
		input
			width: 70vw
			height: r(48px)
			padding: 0 r(72px) 0 r(20px)
			+fz(20px)
			+mq-max(md)
				width: 95vw
		button
			position: absolute
			top: 0
			right: 0
			height: 100%
			width: r(48px)
			background: transparent
			border: 0
			+fz(20px)
	.suggestsearch
		position: absolute
		top: 100%
		padding: r(20px)
		background: #fff
		border-radius: r(4px)
		border: 1px solid #eeeeee
		box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15)
		margin-top: r(12px)
		z-index: 10
		display: none
		width: 100%
		max-height: 30vh
		overflow: auto
		line-height: 1.375
		right: 0
		+scrollbars(4px, color(primary-500), color(gray-50)) 
		.keyword-list
			line-height: 1.375
			h2
				margin-bottom: r(16px)
			& + .keyword-list
				margin-top: r(24px)
			ul
				li
					& + li
						margin-top: 10px
					a
						font-size: r(16px)
						color: #666
						font-weight: 400
						.img
							display: flex
							align-items: center
							justify-content: center
							flex: 0 0 r(60px)
							img
								max-height: r(60px)
						.text
							flex: auto