.cooperation-detail-heading
	.content-container
		pointer-events: none
		padding: r(32px) 0
		+bg(primary-50)
		+mq-min(lg)
			position: absolute
			width: 100%
			+center(Y)
			z-index: 1
			padding: r(88px) 0
	.content
		pointer-events: all
		margin-left: r(32px)
		position: relative
		+mq-min(lg)
			margin-left: r(60px)
		.quote
			font-family: $font-2
			position: absolute
			right: 100%
			+text-gradient(primary)
			+fz(48px)
			margin-right: r(16px)
			top: r(-16px)
	.img
		position: relative
		display: block
		z-index: 2
		+mq-min(lg)
			width: 1056 / 1920 * 100%
		.icon
			position: absolute
			+center(both)
			+circle(80px)
			display: flex
			+flex-center
			+bg(primary-500)
			+text(white)
			+fz(32px)
			border: 1px solid color(primary-200)
		figure
			figcaption
				position: absolute
				bottom: 0
				left: 0
				width: 100%
				padding: r(20px)
				text-align: center
				background: rgba(#000 , 0.5)
				+text(white)
				@extend .body-1
