.about-5
	.item
		+layout-col(16px)
		.icon
			+circle(80px)
			display: flex
			+flex-center
			+bg(primary-600)
			border: r(3px) solid #FFFFFF
			box-shadow: -4px 4px 20px rgba(25, 64, 92, 0.25)
			figure
				+res-ratio(1,1)
				width: r(50px)
				img
					object-fit: contain
	.about-5-content
		padding: r(50px)
		background: linear-gradient(224.07deg, rgba(0, 108, 185, 0.5) 12.94%, rgba(0, 54, 93, 0.5) 94.92%)
		border-radius: r(20px)
