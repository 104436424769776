// Varibles
$primary: linear-gradient(90deg,#f05a94,#bc0049)
$primary: #006CB9
$sub: #223771

.alert
	position: relative
	padding: .75rem r(20px)
	margin-bottom: 1rem
	border: 1px solid transparent
	border-radius: .25rem
	display: block
	line-height: 1.25
	&.alert-danger
		color: #721c24
		background-color: #f8d7da
		border-color: #f5c6cb
	&.alert-success
		color: #155724
		background-color: #d4edda
		border-color: #c3e6cb
	button
		display: none

.login-box,.wrap-register,.wrap-recover,.wrap-resetpass,.wrap-confirmreg
	max-width: 400px
	margin: 60px auto
	padding: 1.875rem
	background: #fff
	border-radius: 10px
	box-shadow: 0 2px 10px 0 rgba(0,0,0,.03)
	@media screen and ( max-width: 1024.98px )
		font-size: 14px

.wrap-confirmreg
	background: transparent
	max-width: 600px
	box-shadow: none

.login-logo,.pagetitle,.postname,.wrap-recover h1
	font-size: 1.5rem
	text-align: center
	line-height: 1.375
	font-weight: 700
	margin-bottom: r(20px)

.wrap-resetpass h1
	font-size: r(20px)
	margin-bottom: r(20px)
	line-height: 1.375

.loginstandard
	.cart-login
		.social-login
			padding: 0

.postname
	text-align: left

.login-box-msg
	font-size: 1.125rem
	margin-bottom: 1rem

.login-box,.wrap-register,.account-form,.address-form,.wrap-recover,.wrap-resetpass
	input[type="text"],input[type="password"],select
		height: r(44px)
		border-radius: 499px
		@extend .body-3
	input[type=submit]
		height: r(40px)
		width: r(200px)
		@extend .body-4
		border-radius: 999px
		color: #fff
		border: 0
		background: $primary
		font-weight: 700
		display: block
		margin-left: auto
	input:disabled
		color: #666
		background-color: transparent
		font-style: italic
	span.fa-exclamation-triangle
		font-size: 12px
		color: red
		line-height: 2.5

.login-box
	input[type=submit]
		width: 100%
		border-radius: 20px

.wrap-register,.account-form,.address-form,.wrap-recover,.wrap-resetpass
	.settingrow
		> *
			flex: 0 0 100%
			max-width: 100%
	.form-group
		margin-bottom: 20px
		label
			@extend .body-3
			text-transform: uppercase
		> *
			flex: auto 
		&.check-group
			> *
				flex: inherit
			label
				flex: 1
				max-width: none

.account-form
	.form-group
		display: flex
		gap: r(20px)
		align-items: center
		label
			+flex-width(r(152px))
	
.passwordrecovery,.wrap-register
	input[type=submit]
		margin: 0 auto

.fa-exclamation-triangle
	font-size: 12px
	color: red
	line-height: 2

.profile-container
	padding: 3rem 15px

.user-sidebar,.user-sidelink
	padding: r(32px)
	background: #fff
	border-radius: r(20px)
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08)

.userinfo
	figure
		display: flex
		align-items: center
		img
			border-radius: 50%
		figcaption
			padding-left: r(20px)
			color: $primary
			+layout-col(8px)
	.user-name
		font-weight: 700
		+fz(20px)

.user-sidelink
	margin-top: r(32px)
	ul
		li
			& + li
				margin-top: r(16px)
				border-top: 1px solid #eee
				padding-top: r(16px)
			&.active
				a
					color: $primary

.account-info,.coin-info,.cart-history,.order-info,.order-information,.order-product,.order-total,.address-info
	background: #fff
	border-radius: r(20px)
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08)
	@extend .body-3
	.link
		+text(gray-300)
		@extend .body-3

.info-heading,.posttitle
	display: flex
	align-items: center
	justify-content: space-between
	padding: r(16px) r(32px)
	@extend .heading-5
	font-weight: 700
	+text(primary-500)
	font-family: $font-2
	border-bottom: 1px solid #d9d9d9
	.posttitle,.postname
		margin-bottom: 0
		padding: 0
		border-bottom: 0
	.link
		+text(gray-300)
		@extend .body-3

.account-info
	.info-detail
		padding: r(20px) r(32px)
		.group
			.label
				color: #787878

.account-order,.address-list,.account-form,.order-info
	padding: r(20px) r(32px)

.address-list
	.address-col
		& + .address-col
			padding-top: r(20px)
			margin-top: r(20px)
			border-top: 1px solid color(primary-100)
	.address
		@extend .body-5
		+layout-col(8px)
		position: relative
		.type
			align-items: center
			background-color: $primary
			border-radius: 4px
			color: #fff
			display: inline-flex
			font-size: .75rem
			height: 2em
			justify-content: center
			line-height: 1.5
			padding-left: .75em
			padding-right: .75em
			white-space: nowrap
			margin-bottom: .9375rem
			position: absolute
			right: 0
			top: 0
		.name
			@extend .body-3
			+text(primary-500)
			font-weight: 700
		.addr-detail
			ul
				+layout-col(8px)
		.btn-editaddr
			color: $primary
			display: inline-flex
			align-items: center
			gap: r(8px)
			&::before
				+icon("\f044")
		.btn-deleteaddr
			color: #f60000
			margin-left: r(20px)
			display: inline-flex
			align-items: center
			gap: r(8px)
			&::before
				+icon("\e2b4")

.cart-history,.order-product
	overflow: auto
	table
		width: 100%
		font-size: 14px
		thead
			th
				font-weight: 700
				padding: .75rem .75rem
				+bg(primary-500)
				+text(white)
				border: 1px solid color(primary-100)
		tbody
			td
				border: 1px solid color(primary-100)
				padding: .75rem .75rem
				vertical-align: top
				a
					display: block
					color: $primary

					+ .name
						margin-top: 5px
					&:hover
						text-decoration: underline
.order-product
	table
		td
			text-align: center
			.product
				display: flex
				align-items: center
				.img
					width: 80px
				.caption
					flex: 1 1 0%
					padding-left: 20px
					font-size: 14px
	@media screen and ( max-width: 576px )
		table
			width: 150%
			td,th
				&:first-child
					width: 50%
				.product
					.caption
						text-align: left

.order-total
	width: 400px
	max-width: 100%
	margin-left: auto
	margin-top: 1rem
	font-size: 14px
	table
		width: 100%
		tr
			td
				text-align: right
				padding: .5rem .75rem
				&.total
					font-weight: 700
					font-size: 18px
					color: $primary
			&:first-child
				td
					padding-top: 0

.regcomplete,.wrap-confirmreg
	span
		display: block
		// text-align: center
		margin-bottom: 20px
		color: #155724
		background-color: #d4edda
		border-color: #c3e6cb
		padding: .75rem r(20px)


.order-information
	.title
		font-weight: 700
		font-size: 18px
		margin-bottom: 15px
		padding-bottom: 10px
		border-bottom: 1px dashed #ccc
	ul
		font-size: 14px
		li
			&.name
				font-size: 16px
