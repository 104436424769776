.technology-item
	&-title
		+text(primary-500)
		font-weight: 700
	&-img
		figure
			+radius(r(48px))
			overflow: hidden
			a
				display: block
				+res-ratio(572,496)
			+mq-max(lg)
				+radius(r(16px))
				a
					+res-ratio(16,10)
	&-horizontal
		+mq-min(lg)
			+odd
				.technology-item-img
					padding-left: r(68px)
			+even
				.technology-item-img
					padding-right: r(68px)
				.row
					flex-direction: row-reverse
		& + .technology-item-horizontal
			margin-top: r(60px)
			+mq-max(lg)
				margin-top: r(40px)
	&-vertical
		display: flex
		flex-direction: column
		.technology-item-img
			figure
				a
					+res-ratio(416,360)
		.technology-item-caption
			flex: 1 1 0%
		.technology-item-brief
			+line(5)
			margin-bottom: r(16px)
		.technology-item-title
			+line(2)
		.technology-item-btn
			margin-top: auto !important