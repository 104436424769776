.supply-detail
	.container
		background-image: url(../img/supply-bg.png)
		background-repeat: no-repeat
		background-size: contain
		background-position: top center
	.attribute-tab
		+layout-row(36px)
		overflow: auto
		li
			a
				display: flex
				align-items: center
				height: r(44px)
				+text(gray-300)
				border-bottom: 1px solid transparent
				white-space: nowrap
			&.active
				a
					+text(primary-500)
					border-bottom: 1px solid color(primary-500)
	.tab-content
		@extend .body-1
		table
			width: 100%
			text-align: center
			th,td
				padding: r(16px) r(40px)
				+mq-max(sm)
					padding: r(16px) r(20px)
			th
				@extend .heading-5
				+bg(primary-600)
				+text(white)
				text-transform: uppercase
				font-weight: 700
			tr
				+bg(white)
				+even
					+bg(gray-50)