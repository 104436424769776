.breadcrumb-wrapper
	+remove(lg, 'max')
	+bg(white)
	+text(gray-400)
	border-bottom: 1px solid color(gray-50)
	.breadcrumb
		padding: r(12px) 0
		display: flex
		align-items: center
		li
			a
				@extend .body-4
				display: flex
				align-items: center
			+ li
				padding-left: r(4px)
				display: flex
				align-items: center
				&::before
					content: "|"
					margin-right: r(4px)
					@extend .body-4
